import React from 'react'; // eslint-disable-line no-unused-vars
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import SuccessProfileRankedQuestionGroup from './SuccessProfileRankedQuestionGroup';
import {
  setProductVersionPage,
  storeProductVersionAnswer,
} from '^/actions/actions';
import {
  saveRankingSuccessAnswersAndSubmit,
  openProductVersion,
} from '^/actions/actionSequences';
import Well from '^/components/Well';
import { anyPending } from '^/responseStates';
import { generateQuestionOrder } from '^/questionGroups';
import AssessmentContainer from './AssessmentContainer';
import AssessmentHeader from './AssessmentHeader';
import AssessmentBody from './AssessmentBody';
import AssessmentFooter from './AssessmentFooter';

export class RankedProductVersion extends PureComponent {
  isViewingLastQuestion() {
    return (
      this.props.progress.get('currentPage') >=
      this.props.questionGroupsWithAnswers.count() - 1
    );
  }

  isFirstViewingCollection() {
    return (
      this.props.questionCollectionIdx === 0 ||
      !this.props.questionCollectionIdx
    );
  }

  onBack() {
    this.props.openProductVersion(
      this.props.productVersion,
      this.props.activity,
      null,
      this.props.questionCollectionIdx - 1
    );
  }

  onNext(unsyncedAnswers) {
    if (!unsyncedAnswers) {
      this.props.openProductVersion(
        this.props.productVersion,
        this.props.activity,
        null,
        this.props.questionCollectionIdx + 1
      );
      return;
    }
    const currentQuestionGroup = this.props.questionGroupsWithAnswers.get(
      this.props.progress.get('currentPage', 0)
    );
    const questionId = currentQuestionGroup.get('id');
    this.props.saveRankingSuccessAnswersAndSubmit(
      this.props.activity,
      this.props.productVersion,
      this.props.uiLanguage,
      questionId,
      unsyncedAnswers ||
        generateQuestionOrder(currentQuestionGroup.get('questions')),
      this.props.questionCollectionIdx
    );
  }

  confirmAndClose() {
    if (
      window.confirm(
        i18next.t(
          'If you need to come back to finish the questionnaire, you will be able to return to where you left off.'
        )
      )
    ) {
      return; //do nothing, client wants the button to still be here even though it's broken
    }
  }

  render() {
    const {
      isPending,
      productVersion,
      questionCollectionIdx,
      raterFor,
      activity,
    } = this.props;
    const profileName = activity.get('profile').get('profile_name');
    const currentQuestionIndex = this.props.progress.get('currentPage', 0);
    const currentQuestionGroup = this.props.questionGroupsWithAnswers.get(
      currentQuestionIndex
    );
    if (!currentQuestionGroup) {
      return null;
    }
    const questionId = currentQuestionGroup.get('id');
    const unsyncedAnswers = this.props.progress.getIn(
      ['unsavedResponses', questionId],
      null
    );

    const allQuestionAnswered = currentQuestionGroup
      .get('questions')
      .every(question => question.get(1));

    const disableSave =
      !allQuestionAnswered &&
      (!unsyncedAnswers || !unsyncedAnswers.every(id => id));

    const currentPage = this.props.progress.get('currentPage', 0);
    const pageCount = this.props.questionGroupsWithAnswers.count();

    return (
      <AssessmentContainer large>
        <AssessmentHeader
          productVersion={productVersion}
          questionCollectionIdx={questionCollectionIdx}
          raterFor={raterFor}
          steps={pageCount}
          currentStep={currentPage}
          noLogo
          removePercentage
        />

        <AssessmentBody header={''} questionIndex={currentQuestionIndex}>
          <Well>
            {questionCollectionIdx === 0 && (
              <div>
                <Trans i18nKey="Potential rank instructions">
                  Thinking of {{ profileName }}, move each psychological
                  capability into one of the following categories:
                  <strong> Critical</strong> (assign 5 only),
                  <strong> Important</strong> (assign 5 only),
                  <strong> Less Essential</strong> (assign 5 only) and rank
                  order within each category (1 = Most, 15 = Least)
                </Trans>
              </div>
            )}
            {questionCollectionIdx === 1 && (
              <div>
                <Trans i18nKey="Competencies rank instructions">
                  Thinking of {{ profileName }}, move each competency into one
                  of the following categories:
                  <strong> Critical</strong> (assign 10 only),
                  <strong> Important</strong> (assign 10 only),
                  <strong> Less Essential</strong> (assign 10 only)
                  <strong> Not applicable </strong> (assign 7 only) and rank
                  order within each category (1 = Most, 37 = Least)
                </Trans>
              </div>
            )}
          </Well>

          <SuccessProfileRankedQuestionGroup
            questionCollectionIdx={questionCollectionIdx}
            questionGroup={currentQuestionGroup}
            unsyncedAnswers={unsyncedAnswers}
            reorderAnswers={this.props.storeProductVersionAnswer.bind(
              null,
              questionId
            )}
            isPending={isPending}
          />
        </AssessmentBody>

        <AssessmentFooter
          isSaving={isPending}
          showContinue={false}
          onContinueLater={() => this.confirmAndClose()}
          onContinue={this.onNext.bind(this, unsyncedAnswers)}
          isEnd={this.isViewingLastQuestion()}
          isSaveButtonDisabled={disableSave}
          onBack={this.onBack.bind(this)}
          isStart={this.isFirstViewingCollection()}
          isProfiler
        />
      </AssessmentContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    progress: state.productVersionProgress,
    isPending: anyPending([
      state.responses.get('getCollection'),
      state.responses.get('saveSuccessRankingAnswers'),
      state.responses.get('updateActivityProductVersionSession'),
    ]),
    uiLanguage: state.ui.get('uiLanguage'),
  };
}

export default connect(mapStateToProps, {
  setProductVersionPage,
  storeProductVersionAnswer,
  saveRankingSuccessAnswersAndSubmit,
  openProductVersion,
  push,
})(RankedProductVersion);

import React from 'react'; // eslint-disable-line no-unused-vars
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import i18next from 'i18next';
import { List } from 'immutable';

import PureComponent from '^/components/PureComponent';
import PerspectivesMotivatorsQuestionGroup from './PerspectivesMotivatorsQuestionGroup';
import {
  setProductVersionPage,
  storeProductVersionAnswer,
} from '^/actions/actions';
import {
  saveISPAnswersAndShowNext,
  saveISPAnswersAndSubmit,
  goToMultiLikertQuestionPrevious,
  openProductVersion,
} from '^/actions/actionSequences';
import Well from '^/components/Well';
import { anyPending } from '^/responseStates';
import AssessmentContainer from './AssessmentContainer';
import AssessmentHeader from './AssessmentHeader';
import AssessmentBody from './AssessmentBody';
import AssessmentFooter from './AssessmentFooter';
import { QUESTION_COLLECTION_TYPES } from '^/components/productVersions/choices';

export class OrderedProductVersion extends PureComponent {
  componentDidMount() {
    // Reset our OrderedProductVersion progress
    const currentQuestionIndex = this.props.questionGroupsWithAnswers.findIndex(
      questionGroup => {
        return questionGroup.get('questions').every(([, answer]) => !answer);
      }
    );
    if (currentQuestionIndex === undefined || currentQuestionIndex === -1) {
      //We've already answered this productVersion, so jump to the last page
      this.props.setProductVersionPage(
        Math.floor(this.props.questionGroupsWithAnswers.count() - 1)
      );
    } else {
      this.props.setProductVersionPage(currentQuestionIndex);
    }
  }

  isViewingLastQuestion() {
    return (
      this.props.progress.get('currentPage') >=
      this.props.questionGroupsWithAnswers.count() - 1
    );
  }

  isFirstViewingPageOrCollection() {
    return (
      this.props.progress.get('currentPage', 0) === 0 &&
      this.props.questionCollectionIdx === 0
    );
  }

  onNext(isLastQuestion) {
    const currentQuestionGroup = this.props.questionGroupsWithAnswers.get(
      this.props.progress.get('currentPage', 0)
    );
    const questionId = currentQuestionGroup.get('id');
    const unsyncedAnswers =
      this.props.progress.getIn(['unsavedResponses', questionId], null) ||
      List();
    const reloadAnswers = unsyncedAnswers.count() > 0;

    if (isLastQuestion) {
      this.props.saveISPAnswersAndSubmit(
        this.props.activity,
        this.props.productVersion,
        this.props.raterFor,
        this.props.uiLanguage,
        questionId,
        unsyncedAnswers,
        this.props.questionCollectionIdx
      );
    } else {
      this.props.saveISPAnswersAndShowNext(
        this.props.activity,
        this.props.productVersion,
        this.props.raterFor,
        this.props.uiLanguage,
        questionId,
        unsyncedAnswers,
        reloadAnswers
      );
    }
  }

  onBack() {
    const previousPage = this.props.progress.get('currentPage', 0) - 1;

    this.props.progress.get('currentPage', 0) > 0
      ? this.props.goToMultiLikertQuestionPrevious(
          this.props.activity,
          this.props.productVersion,
          [],
          previousPage
        )
      : this.props.openProductVersion(
          this.props.productVersion,
          this.props.activity,
          null,
          this.props.questionCollectionIdx - 1
        );
  }

  confirmAndClose() {
    if (
      window.confirm(
        i18next.t(
          'If you need to come back to finish the questionnaire, you will be able to return to where you left off.'
        )
      )
    ) {
      return; //do nothing, client wants the button to still be here even though it's broken
    }
  }

  render() {
    const {
      isPending,
      productVersion,
      questionCollectionIdx,
      raterFor,
    } = this.props;
    const pageCount = this.props.questionGroupsWithAnswers.count();
    const currentQuestionIndex =
      this.props.progress.get('currentPage', 0) > pageCount
        ? 0
        : this.props.progress.get('currentPage', 0);

    const currentQuestionGroup = this.props.questionGroupsWithAnswers.get(
      currentQuestionIndex
    );

    const questionId = currentQuestionGroup.get('id');
    const unsyncedAnswers = this.props.progress.getIn(
      ['unsavedResponses', questionId],
      null
    );
    const allQuestionAnswered = currentQuestionGroup
      .get('questions')
      .every(question => question.get(1));

    const disableSave =
      !allQuestionAnswered &&
      (!unsyncedAnswers || !unsyncedAnswers.every(id => id));

    const currentPage = this.props.progress.get('currentPage', 0);

    const isLastQuestion = this.isViewingLastQuestion();

    const questionCollection = productVersion.getIn([
      'questioncollection_set',
      questionCollectionIdx || 0,
    ]);
    const isSuccessMotivators =
      questionCollection.get('question_type') ===
      QUESTION_COLLECTION_TYPES.CHOICES.SUCCESS_PROFILE_MOTIVATORS_RANK;

    return (
      <AssessmentContainer large>
        <AssessmentHeader
          productVersion={productVersion}
          questionCollectionIdx={questionCollectionIdx}
          raterFor={raterFor}
          steps={pageCount}
          currentStep={currentPage}
          noLogo
        />

        <AssessmentBody
          header={i18next.t('Question {{current}} of {{total}}', {
            current: currentPage + 1,
            total: this.props.questionGroupsWithAnswers.count(),
          })}
          questionIndex={currentQuestionIndex}
        >
          {isSuccessMotivators ? (
            <Well>
              <div>
                <Trans i18nKey="Please rank order success motivators">
                  For the <strong>motivators</strong>, rank order each of the
                  nine statements describing a role incumbent's personal values,
                  needs and preferences, most likely to be associated with
                  success in the role (1 = most, 9 = least)
                </Trans>
              </div>
            </Well>
          ) : (
            <Well>
              <div>
                <Trans i18nKey="Please rank order motivators">
                  Thinking of your own personal values, needs and preferences
                  for your ideal job role and workplace environment, rank order
                  each of the nine statements from{' '}
                  <strong>'MOST important to me at work (1)'</strong> and{' '}
                  <strong>'LEAST important to me at work (9)'</strong>
                </Trans>
              </div>
              <div />
            </Well>
          )}
          <PerspectivesMotivatorsQuestionGroup
            questionGroup={currentQuestionGroup}
            unsyncedAnswers={unsyncedAnswers}
            reorderAnswers={this.props.storeProductVersionAnswer.bind(
              null,
              questionId
            )}
            isPending={isPending}
            targetText
            isSuccessMotivators={isSuccessMotivators}
          />
        </AssessmentBody>

        <AssessmentFooter
          isSaving={isPending}
          showContinue={false}
          onContinueLater={() => this.confirmAndClose()}
          onContinue={this.onNext.bind(this, isLastQuestion)}
          onBack={this.onBack.bind(this)}
          isEnd={this.isViewingLastQuestion()}
          isSaveButtonDisabled={disableSave}
          isStart={this.isFirstViewingPageOrCollection()}
          isProfiler={isSuccessMotivators}
        />
      </AssessmentContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    progress: state.productVersionProgress,
    isPending: anyPending([
      state.responses.get('getCollection'),
      state.responses.get('saveISPAnswers'),
      state.responses.get('updateActivityProductVersionSession'),
    ]),
    uiLanguage: state.ui.get('uiLanguage'),
  };
}

export default connect(mapStateToProps, {
  setProductVersionPage,
  storeProductVersionAnswer,
  goToMultiLikertQuestionPrevious,
  openProductVersion,
  saveISPAnswersAndShowNext,
  saveISPAnswersAndSubmit,
  push,
})(OrderedProductVersion);

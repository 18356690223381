import classNames from 'classnames';
import i18next from 'i18next';
import React, { ReactNode, FunctionComponent } from 'react';

interface OwnProps {
  children?: ReactNode;
  isNotComplete?: boolean;
  isEnd?: boolean;
  isSaving?: boolean;
  isStart?: boolean;
  showContinue?: boolean;
  onContinueLater?: () => void;
  onContinue?: () => void;
  onBack?: () => void;
  isSaveButtonDisabled?: boolean;
  isProfiler?: boolean;
  isSuccessMotivators?: boolean;
}

type Props = OwnProps;

export const AssessmentFooter: FunctionComponent<Props> = ({
  children,
  isNotComplete,
  isSaving,
  showContinue,
  onContinueLater,
  onContinue,
  onBack,
  isEnd,
  isSaveButtonDisabled,
  isProfiler,
  isStart,
}) => {
  return (
    <div
      className={classNames('assessment-footer', { 'on-continue': onContinue })}
    >
      <div className="pull-right">
        {isNotComplete && (
          <span className="mr-sm">
            {i18next.t<string>('Complete all questions to continue')}
          </span>
        )}
        {isSaving && (
          <span className="mr-md">{i18next.t<string>('Saving...')}</span>
        )}
        {showContinue && (
          <button
            className="btn btn-default continue-later"
            onClick={onContinueLater}
            disabled={isSaving}
          >
            {i18next.t<string>('Save and continue later')}
          </button>
        )}
        {children}
        {isProfiler && !isStart && (
          <button
            className="btn btn-primary"
            onClick={onBack}
            disabled={isSaving}
          >
            {i18next.t<string>('Back')}
          </button>
        )}
        {onContinue && (
          <button
            className="btn btn-primary"
            onClick={onContinue}
            disabled={isNotComplete || isSaving || isSaveButtonDisabled}
          >
            {isEnd ? i18next.t<string>('Submit') : i18next.t<string>('Next')}
          </button>
        )}
      </div>
    </div>
  );
};

export default AssessmentFooter;

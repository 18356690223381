import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Link } from 'react-router';
import i18next from 'i18next';

import {
  getUserReports,
  resetResponse,
  getUserPulses,
} from '../../actions/actions';
import { loadUser } from '^/actions/items';
import { getActivities } from '^/actions/collections';
import PureComponent from '../PureComponent';
import ControlBar from '../ControlBar';
import Loading from '../Loading';
import UserInfo from './UserInfo';
import UserReports from './UserReports';
import { withRouter } from '^/withRouter';
import { selectUserProfile } from '^/selectors';
import { selectResponse, selectResponseIsPending } from '^/selectors/responses';
import { selectSelectedUser } from '^/selectors/items';
import { selectActivitiesItems, selectUserStatusPillType } from './selectors';
import {
  selectSelectedUserFullName,
  selectSelectedUserId,
  selectSelectedUserOrgName,
  selectTranslatedSelectedUserStatus,
} from '^/selectors/items/users';
import StatusPill from '../StatusPill';
import UserPulsesTable from '../pulses/UserPulsesTable';

export class SingleUserPage extends PureComponent {
  UNSAFE_componentWillMount() {
    const { paramId } = this.props;

    this.props.loadUser(paramId);
    this.props.getActivities('', 1, {
      user: paramId,
      page_size: 1000,
      user_filter: true,
      shared: false,
      exclude_profiles: true,
    });
    this.props.getUserPulses(paramId);
    this.props.getUserReports(paramId, { include_hidden: true });
  }

  render() {
    const {
      activities,
      user,
      activeUser,
      name,
      orgName,
      userStatus,
      isLoading,
      pillType,
    } = this.props;

    const breadcrumb = (
      <span className="breadcrumbs">
        <Link to="/page/individuals/view">{i18next.t('Individuals')}</Link>
        <span>{name || null}</span>
      </span>
    );

    return (
      <div>
        <ControlBar
          breadcrumb={breadcrumb}
          title={orgName ? `${name} - ${orgName}` : `${name}`}
          hideSearch
          hideAdd
        >
          {userStatus && <StatusPill status={userStatus} type={pillType} />}
        </ControlBar>
        <div>
          {isLoading && <Loading className="list-loading" />}
          {user && !isLoading && (
            <div>
              <UserInfo user={user} />

              <hr className="mt-md" />

              <h4>{i18next.t('User Reports')}</h4>
              <UserReports
                activeUser={activeUser}
                activities={activities}
                user={user}
                style="row"
              />

              {this.props.userPulses && this.props.userPulses.count > 0 && (
                <>
                  <h4>{i18next.t('User Pulse Checks')}</h4>
                  <UserPulsesTable userPulses={this.props.userPulses} />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    activeUser: selectUserProfile(state),
    updateItemResponse: selectResponse(state, 'updateItem'),
    user: selectSelectedUser(state),
    name: selectSelectedUserFullName(state),
    userId: selectSelectedUserId(state),
    orgName: selectSelectedUserOrgName(state),
    userStatus: selectTranslatedSelectedUserStatus(state),
    isLoading: selectResponseIsPending(state, 'loadItem'),
    activities: selectActivitiesItems(state),
    pillType: selectUserStatusPillType(state),
    paramId: props.router.params.id,
    userPulses: state.userPulses,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    loadUser,
    getActivities,
    getUserReports,
    getUserPulses,
    resetResponse,
  })(SingleUserPage)
);

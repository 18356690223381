import makeRsaAction from '../middleware/makeRsaAction';
import makeAsyncActionSet from './makeAsyncActionSet';

export const LOAD_ITEM = makeAsyncActionSet('LOAD_ITEM');

function request(method, itemType, id, url) {
  const action = makeRsaAction(LOAD_ITEM, url, method, null, { itemType, id });
  return action;
}

export const loadItem = request.bind(null, 'GET');
export const loadOptions = request.bind(null, 'OPTIONS');

export const CLEAR_ITEM = 'CLEAR_ITEM';

export function clearItem(itemType) {
  return {
    type: CLEAR_ITEM,
    payload: itemType,
  };
}

export function loadActivity(id) {
  return loadItem('activities', id, `/activities/${id}/`);
}

export function loadActivitySummary(id) {
  return loadItem('activitiesSummary', id, `/activities/${id}/summary/`);
}

export function loadActivityRespondents(id) {
  return loadItem(
    'activitiesRespondents',
    id,
    `/activities/${id}/respondents/`
  );
}

export function loadActivityPublic(id) {
  return loadItem('activities', id, `/activities/${id}/public/`);
}

export function loadOrganisation(id) {
  return loadItem('organisations', id, `/organisations/${id}/`);
}

export function loadUser(id) {
  return loadItem('users', id, `/accounts/users/${id}/`);
}

export function loadProductVersionOptions(id) {
  return loadOptions('productVersionOptions', id, `/product-versions/${id}/`);
}

export function loadGroup(id) {
  return loadItem('groups', id, `/organisations/groups/${id}/`);
}

export function loadTransactionBatch(id) {
  return loadItem(
    'batchCreditHistory',
    id,
    `/organisations/batch/${id}/credit-history/`
  );
}

export function loadJob(id) {
  return loadItem('jobs', id, `/jobs/${id}/`);
}

export function loadPulse(id) {
  return loadItem('pulses', id, `/pulses/${id}/`);
}

export function loadSuccessProfile(id) {
  return loadItem('successProfiles', id, `/activities/success-profile/${id}/`);
}

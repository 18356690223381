import React, { useEffect, useCallback } from 'react'; // eslint-disable-line no-unused-vars
import { Formik } from 'formik';
import { connect } from 'react-redux';
import i18next from 'i18next';

import { getAllProductOrganisations } from '^/actions/collections';
import AccountTypeSetup from '^/components/activities/CreateActivityForm/AccountTypeSetup';
import {
  selectDefaultActivityType,
  selectSelectedOrganisation,
} from '^/components/activities/selectors';
import { ACTIVITY_TYPES } from '^/components/productVersions/choices';

export const CreateActivityOrgTypeForm = props => {
  const {
    hasSelectedGroup,
    groupId,
    selectedOrganisation,
    goToNextStage,
    defaultActivityType,
  } = props;
  const getProductOrgs = props.getAllProductOrganisations;

  const _onAccountTypePress = useCallback(
    query_params => {
      getProductOrgs(selectedOrganisation.get('id'), query_params).then(() => {
        const isProfile = Boolean(
          query_params.activity_type === ACTIVITY_TYPES.CHOICES.PROFILER
        );

        goToNextStage(
          query_params.activity_type,
          selectedOrganisation,
          isProfile
        );
      });
    },
    [getProductOrgs, selectedOrganisation, goToNextStage]
  );

  useEffect(() => {
    if (!selectedOrganisation.isEmpty() && defaultActivityType) {
      _onAccountTypePress({ activity_type: defaultActivityType });
    }
  }, [defaultActivityType, selectedOrganisation]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Formik
      initialValues={{
        organisationId: props.selectedOrganisation.get('id'),
        type: props.defaultActivityType,
      }}
      onSubmit={values => console.log(values)} // eslint-disable-line no-console
    >
      <>
        <AccountTypeSetup
          selectedOrganisation={selectedOrganisation}
          onSelect={_onAccountTypePress}
          hasSelectedGroup={hasSelectedGroup}
          groupId={groupId}
        />

        <div className="form-button-group">
          <hr />
          <div className="form-item wrapping">
            <button
              className="btn btn-default"
              type="button"
              onClick={props.onClose}
            >
              {i18next.t('Cancel')}
            </button>
          </div>
        </div>
      </>
    </Formik>
  );
};

export function mapStateToProps(state, props) {
  return {
    selectedOrganisation: selectSelectedOrganisation(state, props.groupId),
    defaultActivityType: selectDefaultActivityType(
      state,
      props.defaultProductOrganisations || []
    ),
  };
}

export default connect(mapStateToProps, {
  getAllProductOrganisations,
})(CreateActivityOrgTypeForm);

import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { List } from 'immutable';
import _ from 'underscore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/pro-light-svg-icons/faFileDownload';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';

import { HELP_EMAIL } from '^/settings';
import { hasSucceeded } from '^/responseStates';
import { can, downloadExistingReports } from '^/capabilities';
import {
  getMyGeneratingReports,
  resetGeneratingReports,
  setPoll,
  clearPoll,
  resetGetReportsFromDate,
  getOrganisationAllReportsSimple,
  getUserInfo,
  getToken,
  setModalTitle,
  setModalClosable,
} from '^/actions/actions';
import { openSendReportsModal } from '^/actions/modals';
import { setFormStageAndTryViewExport } from '^/actions/actionSequences';
import {
  PDF_STATUS,
  createdByUser,
  getName,
  isInProgress,
} from '^/models/report';
import PureComponent from '^/components/PureComponent';
import ProgressBar from '^/components/ProgressBar';
import Alert, { AlertType } from '^/components/Alert';

export const STAGES = {
  SET_UP: 'SET_UP',
  GENERATING: 'GENERATING',
  REPORTS_PURCHASED: 'REPORTS_PURCHASED',
  REPORTS_DOWNLOADING: 'REPORTS_DOWNLOADING',
  REPORTS_DOWNLOADED: 'REPORTS_DOWNLOADED',
  SEND_REPORTS: 'SEND_REPORTS',
  REPORTS_SENT: 'REPORTS_SENT',
  EDIT_EMAIL: 'EDIT_EMAIL',
};

const OVERLAY_POLLING_RATE = 2000;

export class ReportActions extends PureComponent {
  componentDidMount() {
    const { user, organisationId, formStage } = this.props;
    if (can(user, downloadExistingReports())) {
      this.props.setModalClosable(true);
    }
    this.setTitle();
    if (organisationId) {
      this.props.getOrganisationAllReportsSimple(organisationId);
    }

    if (formStage === STAGES.GENERATING) {
      this.props.setModalClosable(false);
      const date = this.props.getReportsFromDate || new Date().toISOString();
      this.props.clearPoll('AdminReportsPage');
      this.props.setPoll(
        'GenerationProgress',
        this.props.getMyGeneratingReports.bind(null, date),
        OVERLAY_POLLING_RATE
      );
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isModalClosable,
      reportsInitiallyGenerating,
      organisationId,
      formStage,
      user,
      createReportsResponse,
      generateTeamReportResponse,
    } = this.props;
    const progress = this.getProgress();
    const isReady = progress >= 100;

    if (
      formStage === STAGES.GENERATING &&
      (hasSucceeded(createReportsResponse) ||
        hasSucceeded(generateTeamReportResponse)) &&
      (isReady || reportsInitiallyGenerating.isEmpty())
    ) {
      this.onFinishGeneration();
    }

    if (!organisationId && isReady && !isModalClosable) {
      this.props.setModalClosable(true);
    }

    if (this.props.formStage !== prevProps.formStage) {
      this.setTitle();
      if (
        this.props.formStage !== STAGES.REPORTS_PURCHASED ||
        can(user, downloadExistingReports())
      ) {
        this.props.setModalClosable(true);
      }
    }
  }

  getProgress() {
    const {
      formStage,
      myQueuedReports,
      reportsInitiallyGenerating,
      user,
    } = this.props;
    if (formStage === STAGES.GENERATING) {
      const myReportsInitiallyGenerating = reportsInitiallyGenerating.filter(
        createdByUser(user)
      );
      const myQueuedReportsCount = myQueuedReports.filter(isInProgress).count();
      const initialReportsCount = myReportsInitiallyGenerating.count();
      const reportGenerationPercentLeft = initialReportsCount
        ? Math.round((myQueuedReportsCount / initialReportsCount) * 100)
        : 100;
      return 100 - reportGenerationPercentLeft;
    }
    return 100;
  }

  setTitle() {
    switch (this.props.formStage) {
      case STAGES.GENERATING:
        this.props.setModalClosable(false);
        return this.props.setModalTitle(i18next.t('Generating reports…'));
      case STAGES.REPORTS_DOWNLOADING:
        return this.props.setModalTitle(i18next.t('Reports downloading…'));
      case STAGES.REPORTS_DOWNLOADED:
        return this.props.setModalTitle(i18next.t('Reports downloaded'));
      case STAGES.REPORTS_SENT:
        return this.props.setModalTitle(i18next.t('Reports sent'));
      default:
        return this.props.setModalTitle(i18next.t('Reports purchased'));
    }
  }

  getActionNote() {
    const { user, formStage, isJobMatch } = this.props;
    switch (formStage) {
      case STAGES.REPORTS_DOWNLOADING:
      case STAGES.REPORTS_DOWNLOADED:
        return i18next.t('Your reports have successfully been downloaded');
      case STAGES.REPORTS_SENT:
        return i18next.t('Your reports have successfully been sent');
      default:
        if (isJobMatch) {
          return i18next.t(
            'Your reports have successfully been purchased job match'
          );
        }
        if (!can(user, downloadExistingReports())) {
          return i18next.t(
            'Your reports have successfully been purchased no exit'
          );
        }
        return i18next.t('Your reports have successfully been purchased');
    }
  }

  onDownload() {
    const { formName, shortlivedToken } = this.props;
    this.props.setFormStageAndTryViewExport(
      shortlivedToken,
      'DOWNLOAD_REPORTS',
      { reports: this.getSelectedReportIds().toJS() },
      formName,
      STAGES.REPORTS_DOWNLOADED
    );
  }

  onSend() {
    this.props.openSendReportsModal(
      this.props.isTeamReport,
      this.props.isProfiler,
      this.getSelectedReportIds(),
      this.props.reportsSent,
      true,
      this.getSelectedUserIds()
    );
  }

  onFinishGeneration() {
    const { organisationId } = this.props;
    this.props.getUserInfo();
    this.props.getToken();
    this.props.clearPoll('GenerationProgress');

    if (organisationId) {
      this.props.proceedToNextStage();
    } else {
      this.props.onClose();
    }
  }

  getSelectedReportIds() {
    const { selectedReports, reportsInitiallyGenerating } = this.props;
    const selectedReportIds = selectedReports.map(each => each.reportId);
    const recentlyGeneratedReportIds = reportsInitiallyGenerating.map(report =>
      report.get('id')
    );
    return recentlyGeneratedReportIds.concat(selectedReportIds || []).toSet();
  }

  getSelectedUserIds() {
    const { reportsInitiallyGenerating } = this.props;
    const recentlyGeneratedUserIds = reportsInitiallyGenerating.map(report =>
      report.getIn(['user', 'id'])
    );
    return recentlyGeneratedUserIds.toSet();
  }

  renderErrorMessage(myFailedReports) {
    return (
      <Alert type={AlertType.Error}>
        {i18next.t('There was a problem generating some reports')}:
        {myFailedReports.map(report => (
          <div className="reports-error-name" key={report.get('id')}>
            {getName(report)}
          </div>
        ))}
        <Trans i18nKey="TRANS contact enable support">
          Please contact <a href={`mailto:${HELP_EMAIL}`}>{HELP_EMAIL}</a> with
          a screenshot of this error
        </Trans>
      </Alert>
    );
  }

  render() {
    const {
      isModalClosable,
      myQueuedReports,
      reportsInitiallyGenerating,
      user,
      organisationId,
    } = this.props;
    const progress = this.getProgress();
    const isReady = progress >= 100;
    const myReportsInitiallyGenerating = reportsInitiallyGenerating.filter(
      createdByUser(user)
    );
    const myFailedReports = myQueuedReports
      .filter(report =>
        myReportsInitiallyGenerating.some(
          initialReportState =>
            initialReportState.get('id') === report.get('id')
        )
      )
      .filter(report => report.get('pdf_status') === PDF_STATUS.FAILED);
    const disableActions =
      organisationId &&
      myFailedReports.size >= this.getSelectedReportIds().size;
    return (
      <div className="reports-info-wrapper">
        <div className="reports-info-row">
          <ProgressBar percent={progress} />
          {progress}%
        </div>
        {!myFailedReports.isEmpty() && this.renderErrorMessage(myFailedReports)}
        {organisationId && isReady && (
          <div className="margin-top">
            {this.getActionNote()}

            <div className="modal-footer clearfix">
              <div className="pull-right">
                <div className="display-flex">
                  {(isModalClosable || disableActions) && (
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={() => this.props.onClose()}
                    >
                      {i18next.t('Exit')}
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.onDownload()}
                    disabled={disableActions}
                  >
                    <FontAwesomeIcon icon={faFileDownload} />
                    {i18next.t('Download')}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.onSend()}
                    disabled={disableActions}
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                    {i18next.t('Send')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const modalsConfig = state.ui.get('modalsConfig');
  const lastModal = _.last(modalsConfig);
  return {
    user: state.userProfile,
    shortlivedToken: state.shortlivedToken,
    isModalClosable: lastModal && lastModal.closable,
    createReportsResponse: state.responses.get('createReports'),
    generateTeamReportResponse: state.responses.get('generateTeamReport'),
    myQueuedReports: state.activityReports.get(null) || List(),
    reportsInitiallyGenerating: state.ui.get(
      'reportsInitiallyGeneratingSimple'
    ),
    getReportsFromDate: state.getReportsFromDate,
  };
}

export default connect(mapStateToProps, {
  setPoll,
  clearPoll,
  resetGeneratingReports,
  getMyGeneratingReports,
  resetGetReportsFromDate,
  getOrganisationAllReportsSimple,
  getUserInfo,
  setFormStageAndTryViewExport,
  setModalTitle,
  getToken,
  setModalClosable,
  openSendReportsModal,
})(ReportActions);

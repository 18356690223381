import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import { isPending } from '^/responseStates';
import { updateProductOrganisation } from '^/actions/collections';
import PureComponent from '^/components/PureComponent';
import Table from '^/components/Table';
import Checkbox from '^/components/Checkbox';

export class EditAccountVersionType extends PureComponent {
  getVersionsList(checked, versionType) {
    const versionTypesArray = this.props.productOrganisation
      .get('version_types')
      .toArray();
    let newArray = versionTypesArray;
    if (checked) {
      newArray.push(versionType);
    } else {
      newArray = versionTypesArray.filter(
        item => item.get('id') !== versionType.get('id')
      );
    }
    return newArray;
  }

  render() {
    const { product, productOrganisation, productResponses } = this.props;
    const productId = product.get('id');
    const versionTypes = product.get('version_types');
    return (
      <div className="margin-bottom">
        <h6 className="mb-0">{product.get('name')}</h6>
        <form className="form-inline scrollable">
          <Table
            className="highlight-rows selectable"
            columns={[
              {
                header: '',
                value: versionType => {
                  const versionTypeId = versionType.get('id');
                  return (
                    <Checkbox
                      id={productId + versionTypeId}
                      checked={productOrganisation
                        .get('version_types')
                        .some(e => e.get('id') === versionTypeId)}
                      onChange={event =>
                        this.props.updateProductOrganisation(
                          productOrganisation.get('id'),
                          {
                            version_types: this.getVersionsList(
                              event.target.checked,
                              versionType
                            ),
                          }
                        )
                      }
                      disabled={isPending(productResponses)}
                    />
                  );
                },
              },
              {
                header: i18next.t('Version Type'),
                value: versionType =>
                  versionType.get('name') || i18next.t('Default'),
              },
            ]}
            rowClassName={versionType =>
              productOrganisation
                .get('version_types')
                .some(e => e === versionType.get('id'))
                ? 'selected'
                : ''
            }
            rows={versionTypes}
          />
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    productResponses: state.multiResponses.get('updateProductOrganisation'),
  };
}

export default connect(mapStateToProps, {
  updateProductOrganisation,
})(EditAccountVersionType);

import React, { useState, DragEvent, useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import i18next from 'i18next';

import {
  DefaultRanges,
  SuccessProfileItem,
  SuccessProfileSection,
} from '^/reducers/api/types';
import FactorTile from './FactorTile';
import { setDrag, setDrop, setOver } from '^/drag-and-drop-utils';
import { closeModal, openProfileRangeModal } from '^/actions/modals';
import { deepCopy } from '^/utils';

interface Props {
  title: string;
  potentialResults: SuccessProfileSection;
  setResults: (results: SuccessProfileSection) => void;
  canVerify: boolean;
  openProfileRangeModal: typeof openProfileRangeModal;
  closeModal: typeof closeModal;
  defaultRanges: DefaultRanges;
}

export const PotentialProfileSummarySection: React.FC<Props> = props => {
  const {
    title,
    potentialResults,
    canVerify,
    setResults,
    defaultRanges,
  } = props;
  const [dragItem, setDragItem] = useState<string>('');
  const [dragOver, setDragOver] = useState<string>('');
  const [results, setSectionResults] = useState<SuccessProfileSection>(
    potentialResults
  );

  useEffect(() => {
    setResults(deepCopy(results));
  }, [results, setResults]);

  const onClickRange = (
    _e: React.ChangeEvent<{}>,
    id: string,
    low: number,
    high: number
  ) => {
    props.openProfileRangeModal(
      id,
      { low: low, high: high },
      results,
      setSectionResults
    );
  };

  const onDragStart = (event: DragEvent<HTMLDivElement>) => {
    setDrag(event, setDragItem);
  };

  const onDrop = () => {
    setDrop(
      setDragOver,
      setDragItem,
      results,
      setSectionResults,
      dragItem,
      dragOver,
      defaultRanges.potential
    );
  };

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    setOver(event, title, setDragOver, setDragItem, dragItem);
  };
  const criticalResults = results.slice(0, 5);
  const importantResults = results.slice(5, 10);
  const lessEssentialResults = results.slice(10);
  const renderFactorTile = (i: number, item: SuccessProfileItem) => (
    <FactorTile
      className={classNames(
        dragItem !== '' &&
          i === parseInt(dragItem.split('_')[1], 10) &&
          'dragging'
      )}
      onDragStart={(e: DragEvent<HTMLDivElement>) => onDragStart(e)}
      onDragOver={(e: DragEvent<HTMLDivElement>) => onDragOver(e)}
      onDrop={() => onDrop()}
      changeRange={(e: React.ChangeEvent<{}>) =>
        onClickRange(
          e,
          title + '_' + i.toString(),
          item.range_low || 1,
          item.range_high || 10
        )
      }
      key={title + i}
      id={title + '_' + i.toString()}
      sectionName={title}
      rank={item.rank}
      rangeMin={item.range_low}
      rangeMax={item.range_high}
      factor={item.factor_label}
      canVerify={canVerify}
    />
  );

  return (
    <div>
      <div className="potential-group">
        {canVerify && (
          <div className="note-text">
            {i18next.t<string>(
              'These are the aggregate rank order results and default ideal profile ranges. To verify, review and confirm the ranking of the scores and click the range to edit, if appropriate.'
            )}
          </div>
        )}
        <div className="group-one">
          <div className="header-text">
            <div>{i18next.t<string>('Critical')}</div>
            <div>{i18next.t<string>('Range')}</div>
          </div>
          {criticalResults.map((item, i) => {
            return renderFactorTile(i, item);
          })}
        </div>
        <div className="group-two">
          <div className="header-text">
            <div>{i18next.t<string>('Important')}</div>
            <div>{i18next.t<string>('Range')}</div>
          </div>

          {importantResults.map((item, i) => {
            return renderFactorTile(i + 5, item);
          })}
        </div>
        <div className="group-three">
          <div className="header-text">
            <div>{i18next.t<string>('Less Essential')}</div>
            <div>{i18next.t<string>('Range')}</div>
          </div>
          {lessEssentialResults.map((item, i) => {
            return renderFactorTile(i + 10, item);
          })}
        </div>
      </div>
    </div>
  );
};

export default connect(null, { openProfileRangeModal, closeModal })(
  PotentialProfileSummarySection
);

import { faArrowsAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import i18next from 'i18next';
import React, { DragEvent } from 'react';

interface Props {
  id: string;
  sectionName: string;
  rank: number;
  rangeMin: number | null | undefined;
  rangeMax: number | null | undefined;
  factor: string;
  canVerify: boolean;
  onDragStart: (e: DragEvent<HTMLDivElement>) => void;
  onDragOver: (e: DragEvent<HTMLDivElement>) => void;
  onDrop: (e: DragEvent<HTMLDivElement>) => void;
  changeRange?: (e: React.ChangeEvent<{}>, id: string) => void;
  className?: string;
}

export const FactorTile: React.FC<Props> = ({
  rangeMin,
  rangeMax,
  factor,
  canVerify,
  id,
  className,
  sectionName,
  rank,
  onDragStart,
  onDragOver,
  onDrop,
  changeRange,
}) => {
  const renderDragIcon = () => (
    <div className="drag-icon">
      <FontAwesomeIcon icon={faArrowsAlt} />
    </div>
  );

  return (
    <div
      className={classNames('factor-tile', className, canVerify && 'draggable')}
      id={id}
      draggable={canVerify}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      <div>
        {rank} {factor}
      </div>
      {sectionName !== 'Motivators' && rangeMin && (
        <div className="range-holder">
          <span
            onClick={e => canVerify && changeRange && changeRange(e, id)}
            className={classNames(canVerify && 'edit-ranges')}
          >
            {rangeMin} - {rangeMax}
          </span>
          {canVerify && renderDragIcon()}
        </div>
      )}
      {sectionName !== 'Motivators' && !rangeMin && (
        <div className="range-holder">
          <span>{i18next.t<string>('N/A')}</span>
          {canVerify && renderDragIcon()}
        </div>
      )}
      {canVerify && sectionName === 'Motivators' && renderDragIcon()}
    </div>
  );
};

export default FactorTile;

import React from 'react'; // eslint-disable-line no-unused-vars
import { List, Set } from 'immutable';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { Link } from 'react-router';

import { ACTIVITY_STATUS } from '^/activityStatus';
import PureComponent from '^/components/PureComponent';
import LoadingAndErrors from '^/components/LoadingAndErrors';
import { getNumberAvailable } from '^/models/activity';
import InviteToStageTwoButton from '^/components/activities/tabs/users/InviteToStageTwoButton';
import UsersTable from './UsersTable';
import GoToReportsLink from './GoToReportsLink';
import { SUCCESS_PROFILE_VERSION_TYPES } from '^/components/productVersions/choices';

export default class ProfileCreatorsTab extends PureComponent {
  constructor(props) {
    super(props);
    this.setProfileCreatorEmail = this.setProfileCreatorEmail.bind(this);
    this.state = {
      profileCreatorEmail: '',
    };
  }

  setProfileCreatorEmail(email) {
    this.setState({ profileCreatorEmail: email });
  }

  removeUser(userId, userName) {
    this.props.confirmRemoveUser(this.props.activityId, userId, userName);
  }

  editUser() {
    const { activityUsers, organisation, isDISC } = this.props;
    const currentIds = activityUsers.map(each => each.getIn(['user', 'id']));

    const updateUsers = users => {
      const userIds = Set(users.map(user => user['id'])).subtract(currentIds);

      this.props.addProfileCreatorToActivityAndRefresh(
        this.props.activityId,
        currentIds,
        userIds.toArray()
      );
    };

    this.props.openAddProfileRatersModal(
      Set(currentIds),
      100,
      updateUsers,
      this.setProfileCreatorEmail,
      isDISC,
      organisation.get('id')
    );
  }

  openUpdateUsersModal() {
    const { organisation, maxNumUsers, activityUsers } = this.props;
    const currentIds = Set(
      activityUsers.map(each => each.getIn(['user', 'id']))
    );
    const updateUsers = users => {
      const userIds = Set(users.map(user => user['id'])).subtract(currentIds);

      this.props.addUsersToActivityAndRefresh(
        this.props.activityId,
        userIds.toArray()
      );
    };

    this.props.openAddProfileRatersModal(
      currentIds,
      maxNumUsers,
      updateUsers,
      this.setProfileCreatorEmail,
      false,
      organisation.get('id')
    );
  }

  render() {
    const {
      activity,
      activityId,
      profileId,
      activityUsers,
      status,
      readOnly,
      organisation,
      addUsersResponse,
      addGroupsResponse,
      removeUserResponse,
      activitySummary,
      isGenerated,
      isDISC,
      successVersionType,
    } = this.props;
    const withPulse = activitySummary.get('with_pulse');
    const maxNumMoreUsers = getNumberAvailable(activitySummary);
    const isEnded = status === ACTIVITY_STATUS.ENDED;
    const noneComplete =
      activityUsers.filter(
        activityUser => activityUser.get('completed') !== null
      ).size === 0;

    const hasProfileReports = Boolean(
      activitySummary?.getIn(['profile', 'profile_reports'])?.size
    );

    const successProfileId = activitySummary?.getIn([
      'profile',
      'success_profile',
      'id',
    ]);

    return (
      <div title={i18next.t('Profile raters')}>
        <div className="clearfix">
          <div>
            {!readOnly && !isEnded && !isDISC && (
              <div className="pull-left">
                <button
                  className="btn btn-default mt-none"
                  onClick={() =>
                    this.openUpdateUsersModal(activityUsers, maxNumMoreUsers)
                  }
                  disabled={maxNumMoreUsers === 0}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  {i18next.t('Add raters')}
                </button>
              </div>
            )}
            {successVersionType && (
              <div className="pull-right">
                <button
                  className="btn btn-standard submit mt-none"
                  onClick={() =>
                    this.props.openGenerateProfileModal(activityId, profileId)
                  }
                  disabled={noneComplete || hasProfileReports}
                >
                  {i18next.t('Generate profile')}
                </button>

                <Link
                  to={`/page/success-profiles/${successProfileId}/${activityId}`}
                >
                  <button
                    className="btn btn-standard submit mt-none"
                    onClick={() => ({})}
                    disabled={!isGenerated}
                  >
                    {successVersionType ===
                    SUCCESS_PROFILE_VERSION_TYPES.CHOICES.UNVERIFIED
                      ? i18next.t('View profile')
                      : i18next.t('Verify profile')}
                  </button>
                </Link>
              </div>
            )}
            {!readOnly && !isEnded && activitySummary.get('is_staged') && (
              <InviteToStageTwoButton {...this.props} />
            )}
            {isDISC && (
              <GoToReportsLink
                activityId={activityId}
                organisationId={organisation.get('id')}
                isProfiler
              />
            )}
          </div>
        </div>

        {this.props.children}

        <UsersTable
          activity={activity}
          activityUsers={activityUsers}
          activityId={activityId}
          editUser={() => this.editUser()}
          removeUser={(userId, userName) => this.removeUser(userId, userName)}
          withPulse={withPulse}
          readOnly={readOnly}
          isProfiler
          isDISC={isDISC}
        />

        <LoadingAndErrors
          responses={List([
            addUsersResponse,
            removeUserResponse,
            addGroupsResponse,
          ])}
        />
      </div>
    );
  }
}

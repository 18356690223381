import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { MouseEvent, PureComponent } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import _ from 'underscore';
import { connect } from 'react-redux';

import { closeTopModal } from '^/actions/modals';
import EmailEditModal from '^/components/activities/notifications/EmailEditModal';
import NotificationEmailEditModal from '^/components/activities/notifications/NotificationEmailEditModal';
import ProductVersionsReportGenerationModal from '^/components/activities/ProductVersionsReportGenerationModal';
import CreateTeamReportModal from '^/components/analytics/CreateTeamReportModal/index';
import CreateJobMatchReportsModal from '^/components/reports/admin/CreateJobMatchReportsModal';
import CreateSuccessProfileReportsModal from '^/components/reports/admin/CreateSuccessProfileReportsModal';
import AdvancedAnalyticsModal from '^/components/advanced-analytics/AdvancedAnalyticsModal';
import DataAnalyticsOverlayModal from '^/components/analytics/DataAnalyticsOverlayModal';
import DataAnalyticsChartsPage from '^/components/analytics/DataAnalyticsChartsPage';
import TokenExpiredModal from '^/components/analytics/TokenExpiredModal';
import SplitRowModal from '^/components/analytics/SplitRowModal';
import AnswerProductVersion from '^/components/dashboard/enduser/answer/AnswerProductVersion';
import CompleteModal from '^/components/dashboard/enduser/answer/CompleteModal';
import PsycapPotentialWelcomeModal from '^/components/dashboard/enduser/answer/PsycapPotentialWelcomeModal';
import PerspectivesWelcomeModal from '^/components/dashboard/enduser/answer/PerspectivesWelcomeModal';
import RespondentAssessmentChecklistModal from '^/components/dashboard/enduser/answer/RespondentAssessmentChecklistModal'; // eslint-disable-line max-len
import WelcomeModal from '^/components/dashboard/enduser/answer/WelcomeModal';
import GenericModal from '^/components/GenericModal';
import AddGroupsModal from '^/components/groups/AddGroupsModal';
import CreateGroupModal from '^/components/groups/CreateGroupModal';
import CloseModalButton from '^/components/modals/CloseModalButton';
import PaymentInProgressModal from '^/components/modals/PaymentInProgressModal';
import {
  ModalConfig,
  MODALS,
  ModalsConfig,
  MODAL_FOOTERS,
} from '^/components/modals/types';
import ConfirmUpdateBulkDiscountBandModal from '^/components/organisations/accounts/ConfirmUpdateBulkDiscountBandModal';
import ConfirmUpgradeAccountTypeModal from '^/components/organisations/accounts/ConfirmUpgradeAccountTypeModal';
import EditAccountAdminProductsModal from '^/components/organisations/accounts/EditAccountAdminProductsModal';
import EditAccountLogoModal from '^/components/organisations/accounts/EditAccountLogoModal';
import EditTaxDetailsModal from '^/components/organisations/accounts/EditTaxDetailsModal';
import PurchaseCreditsModal from '^/components/organisations/accounts/PurchaseCreditsModal';
import AddCreditsToOrganisationModal from '^/components/organisations/AddCreditsToOrganisationModal';
import ProductVersionNameAndLogo from '^/components/productVersions/ProductVersionNameAndLogo';
import ProfileModal from '^/components/profile/ProfileModal';
import ProfileModalTitle from '^/components/profile/ProfileModalTitle';
import ProfileThankYouModal from '^/components/profile/ProfileThankYouModal';
import ReportGeneration from '^/components/reports/admin/ReportGeneration';
import SendReportsModal from '^/components/reports/admin/SendReportsModal';
import AddBulkDiscountBandModal from '^/components/settings/AddBulkDiscountBandModal';
import DeleteBulkDiscountBandModal from '^/components/settings/DeleteBulkDiscountBandModal';
import EditBulkDiscountBandModal from '^/components/settings/EditBulkDiscountBandModal';
import EditCreditCostModal from '^/components/settings/EditCreditCostModal';
import ChooseShopItemVerificationTypeModal from '^/components/shop/ChooseShopItemVerificationTypeModal';
import ShopItemPurchaseConfirmationModal from '^/components/shop/ShopItemPurchaseConfirmationModal';
import SudoModal from '^/components/SudoModal';
import AddUsersModal from '^/components/users/AddUsersModal';
import CreateUserModal from '^/components/users/CreateUserModal';
import ImportUsersModal from '^/components/users/ImportUsersModal';
import InviteToStageTwoModal from '^/components/users/InviteToStageTwoModal';
import { StoreState } from '^/store';
import CloseButtonRight from './footers/CloseButtonRight';
import StopPulsingModal from '^/components/pulses/modals/StopPulsingModal';
import StartPulsingModal from '^/components/pulses/modals/StartPulsingModal';
import ResendPulseInvitesModal from '^/components/pulses/modals/ResendPulseInvitesModal';
import ConfirmRemoveModal from '^/components/pulses/modals/ConfirmRemoveModal';
import SetTimeModal from '../time/SetTimeModal';
import SetFrequencyTimeModal from '../time/SetFrequencyTimeModal';
import PurchasedPulseBreakdownModal from '^/components/shop/Purchases/PurchasedPulseBreakdownModal';
import RequestStatusModal from './RequestStatusModal';
import AdvancedAnalyticsConfirmModal from '../advanced-analytics/filter-profiles/AdvancedAnalyticsConfirmModal';
import AdvancedAnalyticsSetValueModal from './AdvancedAnalyticsSetValueModal';
import AdvancedAnalyticsSaveACopyModal from '../advanced-analytics/filter-profiles/AdvancedAnalyticsSaveACopyModal';
import AddRemoveUserModal from '../groups/AddRemoveUserModal';
import AddProfileRatersModal from '../activities/AddProfileRatersModal';
import GenerateProfileModal from '../activities/GenerateProfileModal';
import ProfileRangeSliderModal from '^/components/success-profile/ProfileRangeSliderModal';
import VerifyProfileModal from '../activities/VerifyProfileModal';

const TRANSITION_TIMEOUT = {
  enter: 300,
  exit: 300,
};

const modalsById = {
  [MODALS.PROFILE_MODAL]: ProfileModal,
  [MODALS.PROFILE_MODAL_TITLE]: ProfileModalTitle,
  [MODALS.PROFILE_THANK_YOU_MODAL]: ProfileThankYouModal,
  [MODALS.CLOSE_MODAL_BUTTON]: CloseModalButton,
  [MODALS.ANSWER_PRODUCT_VERSION]: AnswerProductVersion,
  [MODALS.WELCOME_MODAL]: WelcomeModal,
  [MODALS.PSYCAP_POTENTIAL_WELCOME_MODAL]: PsycapPotentialWelcomeModal,
  [MODALS.PERSPECTIVES_WELCOME_MODAL]: PerspectivesWelcomeModal,
  [MODALS.SUDO_MODAL]: SudoModal,
  [MODALS.PRODUCT_VERSION_NAME_AND_LOGO]: ProductVersionNameAndLogo,
  [MODALS.COMPLETE_MODAL]: CompleteModal,
  [MODALS.CREATE_USER_MODAL]: CreateUserModal,
  [MODALS.CREATE_GROUP_MODAL]: CreateGroupModal,
  [MODALS.IMPORT_USERS_MODAL]: ImportUsersModal,
  [MODALS.EMAIL_EDIT_MODAL]: EmailEditModal,
  [MODALS.NOTIFICATION_EMAIL_EDIT_MODAL]: NotificationEmailEditModal,
  [MODALS.TOKEN_EXPIRED_MODAL]: TokenExpiredModal,
  [MODALS.GENERIC_MODAL]: GenericModal,
  [MODALS.REPORT_GENERATION]: ReportGeneration,
  [MODALS.EDIT_TAX_DETAILS]: EditTaxDetailsModal,
  [MODALS.SEND_REPORTS_MODAL]: SendReportsModal,
  [MODALS.EDIT_ACCOUNT_ADMIN_PRODUCTS_MODAL]: EditAccountAdminProductsModal,
  [MODALS.PURCHASE_CREDITS_MODAL]: PurchaseCreditsModal,
  [MODALS.CHOOSE_SHOP_ITEM_VERIFICATION_TYPE_MODAL]: ChooseShopItemVerificationTypeModal,
  [MODALS.PRODUCT_VERSIONS_REPORT_GENERATION_MODAL]: ProductVersionsReportGenerationModal,
  [MODALS.CONFIRM_UPDATE_BULK_DISCOUNT_BAND_MODAL]: ConfirmUpdateBulkDiscountBandModal,
  [MODALS.CONFIRM_UPGRADE_ACCOUNT_TYPE_MODAL]: ConfirmUpgradeAccountTypeModal,
  [MODALS.EDIT_CREDIT_COST_MODAL]: EditCreditCostModal,
  [MODALS.EDIT_BULK_DISCOUNT_BAND_MODAL]: EditBulkDiscountBandModal,
  [MODALS.DELETE_BULK_DISCOUNT_BAND_MODAL]: DeleteBulkDiscountBandModal,
  [MODALS.ADD_BULK_DISCOUNT_BAND_MODAL]: AddBulkDiscountBandModal,
  [MODALS.ADD_CREDITS_TO_ORGANISATION_MODAL]: AddCreditsToOrganisationModal,
  [MODALS.EDIT_ACCOUNT_LOGO_MODAL]: EditAccountLogoModal,
  [MODALS.ADD_USERS_MODAL]: AddUsersModal,
  [MODALS.ADD_GROUPS_MODAL]: AddGroupsModal,
  [MODALS.INVITE_TO_STAGE_TWO_MODAL]: InviteToStageTwoModal,
  [MODALS.RESPONDENT_ASSESSMENT_CHECKLIST_MODAL]: RespondentAssessmentChecklistModal,
  [MODALS.PAYMENT_IN_PROGRESS_MODAL]: PaymentInProgressModal,
  [MODALS.SHOP_ITEM_PURCHASE_CONFIRMATION_MODAL]: ShopItemPurchaseConfirmationModal,
  [MODALS.ADVANCED_ANALYTICS_MODAL]: AdvancedAnalyticsModal,
  [MODALS.CREATE_TEAM_REPORT_MODAL]: CreateTeamReportModal,
  [MODALS.CREATE_JOB_MATCH_REPORTS_MODAL]: CreateJobMatchReportsModal,
  [MODALS.CREATE_SUCCESS_PROFILE_REPORTS_MODAL]: CreateSuccessProfileReportsModal,
  [MODALS.SPLIT_ROW_MODAL]: SplitRowModal,
  [MODALS.DATA_ANALYTICS_OVERLAY_MODAL]: DataAnalyticsOverlayModal,
  [MODALS.DATA_ANALYTICS_HEATMAP_OVERLAY_MODAL]: DataAnalyticsChartsPage,
  [MODALS.START_PULSING_MODAL]: StartPulsingModal,
  [MODALS.STOP_PULSING_MODAL]: StopPulsingModal,
  [MODALS.RESEND_PULSE_INVITES]: ResendPulseInvitesModal,
  [MODALS.CONFIRM_REMOVE_MODAL]: ConfirmRemoveModal,
  [MODALS.SET_TIME_MODAL]: SetTimeModal,
  [MODALS.SET_FREQUENCY_TIME_MODAL]: SetFrequencyTimeModal,
  [MODALS.PURCHASED_PULSE_BREAKDOWN_MODAL]: PurchasedPulseBreakdownModal,
  [MODALS.REQUEST_STATUS_MODAL]: RequestStatusModal,
  [MODALS.ADVANCED_ANALYTICS_CONFIRM_MODAL]: AdvancedAnalyticsConfirmModal,
  [MODALS.ADVANCED_ANALYTICS_SET_VALUE_MODAL]: AdvancedAnalyticsSetValueModal,
  [MODALS.ADVANCED_ANALYTICS_SAVE_A_COPY_MODAL]: AdvancedAnalyticsSaveACopyModal,
  [MODALS.ADD_REMOVE_USER_MODAL]: AddRemoveUserModal,
  [MODALS.ADD_PROFILE_RATERS_MODAL]: AddProfileRatersModal,
  [MODALS.GENERATE_PROFILE_MODAL]: GenerateProfileModal,
  [MODALS.PROFILE_RANGE_MODAL]: ProfileRangeSliderModal,
  [MODALS.VERIFY_PROFILE_MODAL]: VerifyProfileModal,
};

const modalFootersById = {
  [MODAL_FOOTERS.CLOSE_BUTTON_RIGHT]: CloseButtonRight,
};

interface DispatchProps {
  closeTopModal: typeof closeTopModal;
}

interface StateProps {
  modalsConfig: ModalsConfig;
}

type Props = StateProps & DispatchProps;

const isModalFooterId = (id: ModalConfig['footer']): id is MODAL_FOOTERS => {
  if (typeof id === 'undefined') {
    return false;
  }

  if (typeof id === 'object') {
    return false;
  }

  return id in MODAL_FOOTERS;
};

export class ModalRenderer extends PureComponent<Props> {
  public componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  public componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  public render() {
    const { modalsConfig } = this.props;

    return (
      <TransitionGroup component="span">
        {modalsConfig.map((modalConfig, idx) => {
          // @TODO: Remove any once renderers are converted to TS
          const bodyElement =
            typeof modalConfig.id !== 'undefined'
              ? React.createElement<any>(
                  modalsById[modalConfig.id],
                  modalConfig.props
                )
              : modalConfig.body;

          const {
            className,
            title,
            subtitle,
            footer,
            closable = true,
            fullScreenOverlay,
          } = modalConfig;

          const footerElement = isModalFooterId(footer)
            ? React.createElement(modalFootersById[footer])
            : footer;

          if (fullScreenOverlay) {
            return (
              <CSSTransition
                key={idx}
                classNames="modal-transition"
                timeout={TRANSITION_TIMEOUT}
              >
                <div
                  key={idx}
                  className={classNames('full-screen-overlay', className)}
                >
                  {bodyElement}
                </div>
              </CSSTransition>
            );
          }

          const hasTitleAndSubtitle = Boolean(title && subtitle);

          return (
            <CSSTransition
              key={idx}
              classNames="modal-transition"
              timeout={TRANSITION_TIMEOUT}
            >
              <div key={idx} className={classNames('modal-wrapper', className)}>
                <div className="modal-overlay">
                  <div className="modal" onClick={this.stopPropagation}>
                    <div
                      className={classNames('modal-header', 'clearfix', {
                        'modal-subtitle-title': hasTitleAndSubtitle,
                      })}
                    >
                      {title && (
                        <div className="modal-title clearfix">
                          <h3>{title}</h3>
                          {closable && (
                            <a className="fa-link" onClick={this.closeTopModal}>
                              <FontAwesomeIcon icon={faTimes} />
                            </a>
                          )}
                        </div>
                      )}
                      {subtitle && (
                        <div className="modal-subtitle clearfix">
                          <h5>{subtitle}</h5>
                        </div>
                      )}
                    </div>
                    <div className="modal-body clearfix">{bodyElement}</div>
                    {footerElement && (
                      <div className="modal-footer clearfix">
                        {footerElement}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    );
  }

  private stopPropagation(event: MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
  }

  private closeTopModal = () => {
    const { modalsConfig } = this.props;
    const lastModal = _.last(modalsConfig);

    if (lastModal && lastModal.closable !== false) {
      const { onClose } = lastModal;

      if (typeof onClose === 'function') {
        onClose();
      }

      this.props.closeTopModal();
    }
  };

  private handleKeyDown = (event: Pick<KeyboardEvent, 'keyCode'>) => {
    // Escape key code
    if (event.keyCode === 27) {
      this.closeTopModal();
    }
  };
}

function mapStateToProps(state: StoreState) {
  return {
    modalsConfig: state.ui.get('modalsConfig'),
  };
}

export default connect(mapStateToProps, {
  closeTopModal,
})(ModalRenderer);

import * as actions from '../actions/actions';
import { API_PREFIX } from '../settings';
import { toQueryString } from '../utils';

export const RAW_SCORES = 'RAW_SCORES';
export const PROFILE_SCORES = 'PROFILE_SCORES';
export const ORG_AVERAGES = 'ORG_AVERAGES';
export const RESULTS = 'RESULTS';
export const NUMERICAL = 'NUMERICAL';
export const CREDIT_HISTORY = 'CREDIT_HISTORY';
export const ORG_CREDIT_HISTORY = 'ORG_CREDIT_HISTORY';
export const THREE_SIXTY_RATER = 'THREE_SIXTY_RATER';
export const DOWNLOAD_REPORTS = 'DOWNLOAD_REPORTS';

export const ALL_ID = 'ALL';

const allAsNull = id => (id === ALL_ID ? null : id);

export default store => next => action => {
  switch (action.type) {
    case actions.VIEW_EXPORT:
      let queryString = toQueryString(
        Object.assign({}, action.payload.metadata, {
          JWT: action.payload.token,
        })
      );

      switch (action.payload.exportType) {
        case RAW_SCORES:
          const { userCSVExportFilter } = store.getState();

          if (userCSVExportFilter.get('selectedProduct')) {
            queryString = toQueryString({
              product: userCSVExportFilter.getIn(['selectedProduct', 'id']),
              activity: userCSVExportFilter.getIn(
                ['selectedActivity', 'id'],
                null
              ),
              organisation: allAsNull(
                userCSVExportFilter.getIn(['selectedOrganisation', 'id'], null)
              ),
              team: userCSVExportFilter.getIn(['selectedTeam', 'id'], null),
              user: userCSVExportFilter.getIn(['selectedUser', 'id'], null),
              JWT: action.payload.token,
            });
            const url = `${API_PREFIX}/exports/raw-scores/?${queryString}`;
            window.open(url);
          }
          break;

        case PROFILE_SCORES:
          window.open(API_PREFIX + `/exports/results/?${queryString}`);
          break;
        case ORG_AVERAGES:
          window.open(
            API_PREFIX + `/exports/organisation-averages/?${queryString}`
          );
          break;
        case RESULTS:
          window.open(API_PREFIX + `/exports/results/?${queryString}`);
          break;
        case NUMERICAL:
          window.open(
            API_PREFIX + `/exports/results/?${queryString}&type=NUMERICAL`
          );
          break;
        case CREDIT_HISTORY:
          window.open(API_PREFIX + `/exports/credit-history/?${queryString}`);
          break;
        case ORG_CREDIT_HISTORY:
          window.open(
            API_PREFIX + `/exports/org-credit-history/?${queryString}`
          );
          break;
        case THREE_SIXTY_RATER:
          window.open(
            API_PREFIX + `/exports/three-sixty-rater/?${queryString}`
          );
          break;
        case DOWNLOAD_REPORTS:
          window.location.assign(
            API_PREFIX + `/reports/download/?${queryString}`
          );
          break;
      }
  }

  return next(action);
};

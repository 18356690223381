import i18next from 'i18next';
import { List } from 'immutable';

import { makeChoices } from '^/utils-ts';

export const USER_ROLES = makeChoices([
  ['RESPONDENTS', 'Respondent'],
  ['RATERS', 'Raters'],
  ['EXTERNAL_RATERS', 'External Raters'],
  ['LINE_MANAGER', 'Line Manager'],
  ['ORG_ADMIN', 'Admin'],
  ['REPORT_RECIPIENTS', 'Report recipients'],
  ['PROFILE_RATER', 'Profile raters'],
]);

export const sentToAdmin = notification =>
  notification.get('sent_to') === USER_ROLES.CHOICES.ORG_ADMIN;

export const EMAIL_TYPES = makeChoices([
  ['ACTIVITY_START_ORG_ADMIN', i18next.t('Activity goes live')],
  ['ACTIVITY_END_ORG_ADMIN', i18next.t('Activity closes in 48hrs')],
  ['SURVEY_STARTED', i18next.t('Respondent has started')],
  ['SURVEY_COMPLETED', i18next.t('Respondent has completed')],

  ['ACTIVITY_START_END_USER', i18next.t('Activity invitation')],
  ['ACTIVITY_REPORTS_READY', i18next.t('Reports ready')],
  ['ACTIVITY_REPORT_LINK', i18next.t('Report link')],

  [
    'ACTIVITY_NOMINATIONS_CHASER',
    i18next.t('Respondent reminder to nominate Raters'),
  ],
  [
    'ACTIVITY_RATERS_AWAITING_APPROVAL',
    i18next.t('Line Manager invited to approve Raters'),
  ],
  [
    'ACTIVITY_RATERS_REJECTED',
    i18next.t('Respondent notified Raters rejected'),
  ],
  [
    'ACTIVITY_START_RATER',
    i18next.t('Respondent, Line Manager and Rater invite to complete'),
  ],
  [
    'ACTIVITY_START_EXTERNAL_RATER',
    i18next.t('Respondent, Line Manager and Rater invite to complete'),
  ],
  ['ACTIVITY_MIDWAY_REMINDER', i18next.t('1st reminder to complete')],
  [
    'ACTIVITY_MIDWAY_REMINDER_RATER',
    i18next.t('1st Respondent, Line Manager and Rater reminder to complete'),
  ],
  ['ACTIVITY_PRE_CHASER', i18next.t('2nd reminder to complete')],
  [
    'ACTIVITY_PRE_CHASER_RATER',
    i18next.t('2nd Respondent, Line Manager and Rater reminder to complete'),
  ],
  ['ACTIVITY_CHASER', i18next.t('3rd reminder to complete')],
  [
    'ACTIVITY_CHASER_RATER',
    i18next.t('3rd Respondent, Line Manager and Rater reminder to complete'),
  ],
  ['INVITATION_TO_STAGE_2', i18next.t('Invitation to stage 2')],
  ['SURVEY_STARTED_PROFILER', i18next.t('Profile started')],
  ['SURVEY_COMPLETED_PROFILER', i18next.t('Profile completed')],
  ['ACTIVITY_START_PROFILER', i18next.t('Activity invitation')],
  ['ACTIVITY_MIDWAY_REMINDER_PROFILER', i18next.t('1st reminder to complete')],
  ['ACTIVITY_PRE_CHASER_PROFILER', i18next.t('2nd reminder to complete')],
  ['ACTIVITY_CHASER_PROFILER', i18next.t('3rd reminder to complete')],
]);

export const isDisabledForPrepay = notification =>
  List([
    EMAIL_TYPES.CHOICES.ACTIVITY_START_END_USER,
    EMAIL_TYPES.CHOICES.ACTIVITY_MIDWAY_REMINDER,
    EMAIL_TYPES.CHOICES.ACTIVITY_PRE_CHASER,
    EMAIL_TYPES.CHOICES.ACTIVITY_CHASER,
  ]).contains(notification.get('type'));

export const isReportNotification = notification =>
  List([
    EMAIL_TYPES.CHOICES.ACTIVITY_REPORTS_READY,
    EMAIL_TYPES.CHOICES.ACTIVITY_REPORT_LINK,
  ]).contains(notification.get('type'));

export const canOnlySendNotificationOnce = notification =>
  List([
    EMAIL_TYPES.CHOICES.ACTIVITY_START_ORG_ADMIN,
    EMAIL_TYPES.CHOICES.ACTIVITY_END_ORG_ADMIN,
  ]).contains(notification.get('type'));

export const NOTIFICATION_LOG_TYPES = {
  [EMAIL_TYPES.CHOICES.ACTIVITY_START_END_USER]: 'NOTIFY_END_USERS_START',
  [EMAIL_TYPES.CHOICES.ACTIVITY_START_RATER]: 'NOTIFY_RATERS_START',
  [EMAIL_TYPES.CHOICES.ACTIVITY_START_EXTERNAL_RATER]:
    'ACTIVITY_START_EXTERNAL_RATER',
  [EMAIL_TYPES.CHOICES.ACTIVITY_START_ORG_ADMIN]: 'NOTIFY_ORG_ADMIN_START',
  [EMAIL_TYPES.CHOICES.ACTIVITY_MIDWAY_REMINDER]: 'NOTIFY_MIDWAY',
  [EMAIL_TYPES.CHOICES.ACTIVITY_MIDWAY_REMINDER_RATER]: 'NOTIFY_MIDWAY_RATER',
  [EMAIL_TYPES.CHOICES.ACTIVITY_CHASER]: 'SEND_CHASERS',
  [EMAIL_TYPES.CHOICES.ACTIVITY_CHASER_RATER]: 'SEND_CHASERS_RATER',
  [EMAIL_TYPES.CHOICES.ACTIVITY_NOMINATIONS_CHASER]: 'SEND_NOMINATIONS_CHASERS',
  [EMAIL_TYPES.CHOICES.ACTIVITY_PRE_CHASER]: 'SEND_PRE_CHASERS',
  [EMAIL_TYPES.CHOICES.ACTIVITY_PRE_CHASER_RATER]: 'SEND_PRE_CHASERS_RATER',
  [EMAIL_TYPES.CHOICES.ACTIVITY_END_ORG_ADMIN]: 'NOTIFY_ORG_ADMIN_END',
  [EMAIL_TYPES.CHOICES.ACTIVITY_REPORTS_READY]: 'NOTIFY_REPORTS_READY',
  [EMAIL_TYPES.CHOICES.ACTIVITY_REPORT_LINK]: 'NOTIFY_REPORT_LINK',
  [EMAIL_TYPES.CHOICES.ACTIVITY_RATERS_AWAITING_APPROVAL]:
    'NOTIFY_LM_RATERS_SUBMITTED',
  [EMAIL_TYPES.CHOICES.ACTIVITY_RATERS_REJECTED]:
    'NOTIFY_RESPONDENT_RATERS_REJECTED',
  [EMAIL_TYPES.CHOICES.INVITATION_TO_STAGE_2]: 'NOTIFY_INVITED_TO_STAGE_2',
};

export const EMAIL_TRIGGERS = makeChoices([
  ['ACTIVITY_START', i18next.t('activity goes live')],
  ['ACTIVITY_MIDWAY', i18next.t('activity is midway through')],
  ['ACTIVITY_24H_BEFORE_END', i18next.t('Activity reminder')],
  ['ACTIVITY_7D_BEFORE_END', i18next.t('Activity pre reminder')],
  ['ACTIVITY_END', i18next.t('activity closes')],
  ['ACTIVITY_REPORTS_READY', i18next.t('reports are released')],
  ['ACTIVITY_RATERS_SUBMITTED', i18next.t('Raters submitted')],
  ['ACTIVITY_RATERS_REJECTED', i18next.t('Raters rejected')],
  ['SURVEY_STARTED', i18next.t('Respondent started survey')],
  ['SURVEY_COMPLETED', i18next.t('Respondent has completed a survey')],
  ['INVITED_TO_STAGE_2', i18next.t('invited to stage 2')],
]);

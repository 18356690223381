import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import i18next from 'i18next';

import { APPROVAL_STATUS } from '^/approvalStatus';
import { isLineManager } from '^/rater';
import { getActivityUsersCollectionName } from '^/actions/collections';
import PureComponent from '^/components/PureComponent';
import Table from '^/components/Table';
import UserNameAndEmail from '^/components/UserNameAndEmail';
import {
  getPulsingStatus,
  getAllPulsingStatuses,
  PULSING_STATUSES,
} from './UsersTable';

const isReady = rater =>
  isLineManager(rater) ||
  rater.get('approved_by_line_manager') === APPROVAL_STATUS.APPROVED;

export class UsersTableThreeSixty extends PureComponent {
  getRaterStatus(activityUser) {
    if (!this.props.ratersNeedApproval) {
      return (
        <span className="inline-badge badge-grey">
          {i18next.t('APPROVAL NOT REQUIRED')}
        </span>
      );
    }
    const approvalStatuses = activityUser
      .get('raters')
      .filterNot(isLineManager)
      .map(each => each.get('approved_by_line_manager'));

    if (approvalStatuses.isEmpty()) {
      return (
        <span className="inline-badge badge-grey">
          {i18next.t('NONE CHOSEN')}
        </span>
      );
    } else if (
      approvalStatuses.every(status => status === APPROVAL_STATUS.APPROVED)
    ) {
      return (
        <span className="inline-badge badge-success">
          {i18next.t('APPROVED BY LINE MANAGER')}
        </span>
      );
    } else if (approvalStatuses.contains(APPROVAL_STATUS.REJECTED)) {
      return (
        <span className="inline-badge badge-error">
          {i18next.t('REJECTED BY LINE MANAGER')}
        </span>
      );
    } else if (!activityUser.get('raters_submitted')) {
      return (
        <span className="inline-badge badge-grey">
          {i18next.t('NOT SUBMITTED')}
        </span>
      );
    } else if (approvalStatuses.contains(APPROVAL_STATUS.AWAITING_APPROVAL)) {
      return (
        <span className="inline-badge badge-warning">
          {i18next.t('AWAITING LINE MANAGER APPROVAL')}
        </span>
      );
    }
    return (
      <span className="inline-badge badge-grey">{i18next.t('PENDING')}</span>
    );
  }

  getSelfStatus(activityUser) {
    if (activityUser.get('completed')) {
      return i18next.t('COMPLETED');
    } else if (activityUser.get('has_started')) {
      return i18next.t('PARTIALLY COMPLETED');
    }
    return i18next.t('NOT STARTED');
  }

  getReadyRaters(activityUser) {
    const raters = activityUser.get('raters');
    return this.props.ratersNeedApproval ? raters.filter(isReady) : raters;
  }

  getNumberReadyRaters(activityUser) {
    return this.getReadyRaters(activityUser).count();
  }

  getNumberCompleteRaters(activityUser) {
    const completedRaters = this.getReadyRaters(activityUser).filter(rater =>
      rater.get('completed')
    );
    return completedRaters.count();
  }

  getResponseRate(activityUser) {
    if (this.getNumberCompleteRaters(activityUser) === 0) {
      return 0;
    }
    return Math.round(
      (this.getNumberCompleteRaters(activityUser) * 100) /
        this.getNumberReadyRaters(activityUser)
    );
  }

  renderUser(activityUser) {
    const user = activityUser.get('user');
    return (
      <div>
        <UserNameAndEmail user={user} />
      </div>
    );
  }

  action(activityUser) {
    const user = activityUser.get('user');
    const userId = user.get('id');
    const userName = user.get('respondent');
    if (!activityUser.get('has_started')) {
      return (
        <a
          className="underlined"
          onClick={() => this.props.removeUser(userId, userName)}
        >
          {i18next.t('remove')}
        </a>
      );
    }
  }

  render() {
    const {
      activityUsers,
      activityUsersWithSessions,
      readOnly,
      withPulse,
    } = this.props;
    if (
      !activityUsers ||
      activityUsers.isEmpty() ||
      !activityUsersWithSessions ||
      activityUsersWithSessions.isEmpty()
    ) {
      return null;
    }

    const pulseColumn = {
      header: i18next.t('Pulsing'),
      value: activityUser =>
        PULSING_STATUSES.DISPLAY[
          getPulsingStatus(
            activityUsersWithSessions.find(
              each => each.get('user_id') === activityUser.getIn(['user', 'id'])
            )
          )
        ],
      sortable: true,
      sort: getPulsingStatus,
      filter: {
        key: 'pulse',
        values: getAllPulsingStatuses(activityUsersWithSessions).map(
          status => ({
            id: status,
            name: PULSING_STATUSES.DISPLAY[status],
          })
        ),
        getValue: getPulsingStatus,
      },
    };
    const columns = [
      {
        header: i18next.t('Respondent'),
        value: activityUser => activityUser.getIn(['user', 'full_name']),
        sortable: true,
        sort: activityUser => activityUser.getIn(['user', 'full_name']),
      },
      {
        header: i18next.t('Email address'),
        value: activityUser => activityUser.getIn(['user', 'email']),
      },
      {
        header: i18next.t('Rater Approval Status'),
        value: activityUser => this.getRaterStatus(activityUser),
      },
      {
        header: i18next.t('Self Status'),
        value: activityUser => this.getSelfStatus(activityUser),
      },
      {
        header: i18next.t('Sent'),
        value: activityUser => this.getNumberReadyRaters(activityUser),
      },
      {
        header: i18next.t(`Rec'd`),
        value: activityUser => this.getNumberCompleteRaters(activityUser),
      },
      {
        header: i18next.t('Response Rate %'),
        value: activityUser => this.getResponseRate(activityUser),
      },
      {
        header: i18next.t('Action'),
        value: activityUser => !readOnly && this.action(activityUser),
      },
    ];

    if (withPulse) {
      columns.splice(columns.length - 1, 0, pulseColumn);
    }

    return (
      <div>
        <Table columns={columns} rows={activityUsers} />
      </div>
    );
  }
}

UsersTableThreeSixty.propTypes = {
  activityUsers: ImmutablePropTypes.list.isRequired,
  activity: ImmutablePropTypes.map.isRequired,
  ratersNeedApproval: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  const collectionName = getActivityUsersCollectionName(ownProps.activityId);
  return {
    activityUsersWithSessions: state.collections.getIn([
      collectionName,
      'items',
    ]),
  };
}

export default connect(mapStateToProps)(UsersTableThreeSixty);

import { DragEvent } from 'react';
import _ from 'lodash';

import { Ranges, SuccessProfileSection } from './reducers/api/types';
import { deepCopy } from './utils';

export const setOver = (
  event: DragEvent<HTMLDivElement>,
  title: string,
  setDragOver: (id: string) => void,
  setDragItem: (id: string) => void,
  dragItem: string
) => {
  event.preventDefault();

  if (dragItem.includes(title)) {
    setDragOver(event.currentTarget.id);
  } else {
    setDragOver('');
    setDragItem('');
  }
};
export type RangeType = 'high' | 'low';
export const setDrag = (
  event: DragEvent<HTMLDivElement>,
  setDragItem: (id: string) => void
) => {
  event.dataTransfer.setData('id', event.currentTarget.id);
  setDragItem(event.currentTarget.id);
};

export const findRange = (
  type: RangeType,
  range: number | null | undefined,
  position: number,
  defaultRanges: Ranges | null
) => {
  const isInCriticalRange = defaultRanges?.CRITICAL
    ? _.inRange(
        position,
        defaultRanges.CRITICAL.rank_low - 1,
        defaultRanges.CRITICAL.rank_high
      )
    : false;

  const isInImportantRange = defaultRanges?.IMPORTANT
    ? _.inRange(
        position,
        defaultRanges.IMPORTANT?.rank_low - 1,
        defaultRanges.IMPORTANT?.rank_high
      )
    : false;

  if (isInCriticalRange) {
    if (range !== null) {
      return range;
    }
    return type === 'high'
      ? defaultRanges?.CRITICAL?.range_high
      : defaultRanges?.CRITICAL?.range_low;
  }

  if (isInImportantRange) {
    if (range !== null) {
      return range;
    }
    return type === 'low'
      ? defaultRanges?.IMPORTANT?.range_low
      : defaultRanges?.IMPORTANT?.range_high;
  }

  return null;
};

export const setDrop = (
  setDragOver: (id: string) => void,
  setDragItem: (id: string) => void,
  list: SuccessProfileSection,
  setResults: (results: SuccessProfileSection) => void,
  dragItem: string,
  dragOver: string,
  defaultRanges: Ranges | null
) => {
  if (dragOver !== '') {
    const listCopy: SuccessProfileSection = deepCopy(list);
    const dragItemId = parseInt(dragItem.split('_')[1], 10);
    const dragOverId = parseInt(dragOver.split('_')[1], 10);
    const draggingItemContent = listCopy[dragItemId];
    listCopy.splice(dragItemId, 1);
    listCopy.splice(dragOverId, 0, draggingItemContent);
    const listUpdate = listCopy.map((item, idx) => ({
      ...item,
      rank: idx + 1,
      range_low: findRange('low', item?.range_low, idx, defaultRanges),
      range_high: findRange('high', item?.range_high, idx, defaultRanges),
    }));
    setResults(listUpdate);
  }
  setDragItem('');
  setDragOver('');
};

import React from 'react'; // eslint-disable-line no-unused-vars
import { List } from 'immutable';
import i18next from 'i18next';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ACTIVITY_STATUS } from '^/activityStatus';
import { getNumberAvailable } from '^/models/activity';
import Alert from '^/components/Alert';
import PureComponent from '^/components/PureComponent';
import LoadingAndErrors from '^/components/LoadingAndErrors';
import CreateGroupButton from '^/components/activities/tabs/users/CreateGroupButton';
import InviteToStageTwoButton from '^/components/activities/tabs/users/InviteToStageTwoButton';
import UsersTable from './UsersTable';
import UsersTableThreeSixty from './UsersTableThreeSixty';
import GoToReportsLink from './GoToReportsLink';

export default class ActivityUsersTab extends PureComponent {
  confirmAndAddUsers(doAdd) {
    if (this.props.status === ACTIVITY_STATUS.LIVE) {
      this.props.openConfirmationModal(
        i18next.t('add respondents to'),
        i18next.t('activity'),
        [i18next.t('They will be notified by email.')],
        () => {
          this.props.closeTopModal();
          doAdd();
        },
        this.props.closeTopModal,
        'btn-primary'
      );
    } else {
      doAdd();
    }
  }

  addGroups(groupIds) {
    const doAdd = () =>
      this.props.addGroupsToActivityAndRefresh(this.props.activityId, groupIds);
    this.confirmAndAddUsers(doAdd);
  }

  updateUsers(userIds, currentIds) {
    const userIdsToAdd = userIds.subtract(currentIds);
    const doAdd = () =>
      this.props.addUsersToActivityAndRefresh(
        this.props.activityId,
        userIdsToAdd
      );
    this.confirmAndAddUsers(doAdd);
  }

  removeUser(userId, userName) {
    this.props.confirmRemoveUser(this.props.activityId, userId, userName);
  }

  showAddUsersModal(activityUsers, maxNumMoreUsers) {
    const { organisation } = this.props;
    const currentIds = activityUsers.map(each => each.getIn(['user', 'id']));
    this.props.openAddUsersModal(
      organisation.get('id'),
      currentIds,
      userIds => this.updateUsers(userIds, currentIds),
      true,
      maxNumMoreUsers
    );
  }

  showAddGroupsModal(activityUsers, maxNumMoreUsers) {
    const { organisation } = this.props;
    const currentIds = activityUsers.map(each => each.getIn(['user', 'id']));
    this.props.openAddGroupsModal(
      organisation,
      currentIds,
      this.addGroups.bind(this),
      maxNumMoreUsers
    );
  }

  render() {
    const {
      activity,
      activityId,
      activityUsers,
      status,
      showRaterInfo,
      ratersNeedApproval,
      readOnly,
      organisation,
      addUsersResponse,
      addGroupsResponse,
      removeUserResponse,
      activitySummary,
    } = this.props;

    const withPulse = activitySummary.get('with_pulse');
    const maxNumMoreUsers = getNumberAvailable(activitySummary);
    const isEnded = status === ACTIVITY_STATUS.ENDED;
    return (
      <div title={i18next.t('Respondents')}>
        <div className="clearfix">
          {isEnded && (
            <Alert>
              {i18next.t(
                'Users cannot be added to a closed activity. Extend the close date to add users.'
              )}
            </Alert>
          )}

          {!readOnly && !isEnded && (
            <div className="pull-left">
              <button
                className="btn btn-default btn-small mt-none"
                onClick={() =>
                  this.showAddUsersModal(activityUsers, maxNumMoreUsers)
                }
                disabled={maxNumMoreUsers === 0}
              >
                <FontAwesomeIcon icon={faPlus} />
                {i18next.t('Add respondent(s)')}
              </button>
              <button
                className="btn btn-default btn-small mt-none"
                onClick={() =>
                  this.showAddGroupsModal(activityUsers, maxNumMoreUsers)
                }
              >
                <FontAwesomeIcon icon={faPlus} />
                {i18next.t('Add group(s)')}
              </button>
            </div>
          )}

          <div className="pull-right">
            {!readOnly && !isEnded && activitySummary.get('is_staged') && (
              <InviteToStageTwoButton {...this.props} />
            )}

            {!readOnly && <CreateGroupButton {...this.props} />}

            <GoToReportsLink
              activityId={activityId}
              organisationId={organisation.get('id')}
              isProfiler={false}
            />
          </div>
        </div>

        {this.props.children}

        {showRaterInfo && activityUsers ? (
          <UsersTableThreeSixty
            ratersNeedApproval={ratersNeedApproval}
            activityUsers={activityUsers}
            removeUser={(userId, userName) => this.removeUser(userId, userName)}
            activityId={activityId}
            updateActivityUser={this.props.updateActivityUser}
            withPulse={withPulse}
            readOnly={readOnly}
          />
        ) : (
          <UsersTable
            activity={activity}
            activityUsers={activityUsers}
            removeUser={(userId, userName) => this.removeUser(userId, userName)}
            activityId={activityId}
            withPulse={withPulse}
            readOnly={readOnly}
          />
        )}

        <LoadingAndErrors
          responses={List([
            addUsersResponse,
            removeUserResponse,
            addGroupsResponse,
          ])}
        />
      </div>
    );
  }
}

import {
  OrgSessions,
  TeamSession,
  TeamSessions,
  Uuid,
  ProductOrganisationReportTemplate,
  ProfileRespondentSessions,
  ReportTemplate,
} from '^/reducers/api/types';
import { ReportToGenerate } from './types';

export function filterSessions(sessions: TeamSession[], filters: any) {
  if (filters['activity']?.length) {
    sessions = sessions.filter(session =>
      filters['activity'].includes(session.activity_product_version.activity.id)
    );
  }
  if (filters['group']?.length) {
    sessions = sessions.filter(session =>
      filters['group'].some((group: Uuid) =>
        session.user.usergroup_set.map(g => g.id).includes(group)
      )
    );
  }
  return sessions;
}

const reportCost = ({
  credit_cost,
  report_template,
}: ProductOrganisationReportTemplate) =>
  credit_cost !== null ? credit_cost : report_template.credit_cost;

export const makeReportToGenerate = (
  template: ProductOrganisationReportTemplate,
  quantity: number,
  selected: boolean
) => ({
  id: template.report_template.id,
  name: template.report_template.name,
  cost: reportCost(template),
  isTeam: template.report_template.is_team,
  isJobMatch: template.report_template.is_job_match,
  isJobMatchRelated: template.report_template.is_job_match_related,
  quantity,
  selected,
});

export function chooseReportTemplate(
  templates: ReadonlyArray<ProductOrganisationReportTemplate>,
  languageCode: string
) {
  return templates.length > 1
    ? (templates.find(
        ({ report_template }) => report_template.lang_code === languageCode
      ) as ProductOrganisationReportTemplate)
    : templates[0];
}

export function getSessionsMissingReportTemplate(
  reportTemplateId: Uuid,
  selectedSessions: TeamSessions | ProfileRespondentSessions
) {
  return selectedSessions.filter(
    ({ reports }) =>
      !reports.some(report => report.template.id === reportTemplateId)
  );
}

export function getSessionsMissingReportTemplateForSuccessProfile(
  reportTemplateId: Uuid,
  selectedSessions: ProfileRespondentSessions,
  successProfileId: Uuid
) {
  return selectedSessions.filter(
    ({ reports }) =>
      !reports.some(
        report =>
          report.template.id === reportTemplateId &&
          report.profile_report?.profile?.success_profile?.id ===
            successProfileId
      )
  );
}

export function getJobMatchReportTemplates(
  orgSessions: OrgSessions | null,
  productId: Uuid,
  getSelectedSessions: () => ProfileRespondentSessions,
  jobProfileReportTemplate: ReportTemplate,
  isJobProfileReportGenerated: boolean
): ReportToGenerate[] {
  if (orgSessions) {
    const productOrg = orgSessions.productorganisation_set.find(
      po => po.product.id === productId
    );
    if (productOrg) {
      const jobMatchTemplate = productOrg.productorganisationreporttemplate_set
        .filter(({ report_template }) => report_template.is_job_match)
        .map(report => {
          const selectedSessions = getSelectedSessions();
          return makeReportToGenerate(report, selectedSessions.length, true);
        });

      const relatedTemplates = productOrg.productorganisationreporttemplate_set
        .filter(({ report_template }) => report_template.is_job_match_related)
        .map(individualReportTemplate => {
          const selectedSessions = getSelectedSessions();
          const numReports = getSessionsMissingReportTemplate(
            individualReportTemplate.report_template.id,
            selectedSessions
          ).length;
          return makeReportToGenerate(
            individualReportTemplate,
            numReports,
            true
          );
        })
        .filter(report => report.quantity > 0);

      const jobProfileProductOrgTemplate = {
        credit_cost: null,
        report_template: jobProfileReportTemplate,
      };
      const jobProfileTemplate = !isJobProfileReportGenerated
        ? [makeReportToGenerate(jobProfileProductOrgTemplate, 1, true)]
        : [];

      return [...jobMatchTemplate, ...jobProfileTemplate, ...relatedTemplates];
    }
  }
  return [];
}

export function getSuccessProfileReportTemplates(
  getSelectedSessions: () => ProfileRespondentSessions,
  reportTemplates: ReadonlyArray<ReportTemplate>,
  successProfileId: Uuid
): ReportToGenerate[] {
  const selectedSessions = getSelectedSessions();

  return reportTemplates.map(reportTemplate => {
    const numReports = getSessionsMissingReportTemplateForSuccessProfile(
      reportTemplate.id,
      selectedSessions,
      successProfileId
    ).length;

    return {
      id: reportTemplate.id,
      name: reportTemplate.name,
      cost: reportTemplate.credit_cost,
      isTeam: false,
      isJobMatch: false,
      isJobMatchRelated: false,
      quantity: numReports,
      selected: false,
    };
  });
}

export function getReportsToGenerate(
  orgSessions: OrgSessions | null,
  productId: Uuid,
  languageCode: string,
  getSelectedSessions: () => TeamSessions
): ReportToGenerate[] {
  if (orgSessions) {
    for (const productOrganisation of orgSessions.productorganisation_set.filter(
      po => po.product.id === productId
    )) {
      const reportTemplates =
        productOrganisation.productorganisationreporttemplate_set;
      if (
        reportTemplates.some(({ report_template }) => report_template.is_team)
      ) {
        const teamReportTemplates = reportTemplates.filter(
          ({ report_template }) => report_template.is_team
        );
        const individualReportTemplates = reportTemplates.filter(
          ({ report_template }) => !report_template.is_team
        );

        const teamReportTemplate = chooseReportTemplate(
          teamReportTemplates,
          languageCode
        );

        const teamReport = makeReportToGenerate(teamReportTemplate, 1, true);
        const individualReports = individualReportTemplates
          .filter(
            ({ report_template }) => report_template.lang_code === languageCode
          )
          .map(
            (
              individualReportTemplate,
              _index,
              localIndividualReportTemplates
            ) => {
              const selectedSessions = getSelectedSessions();
              const numReports = getSessionsMissingReportTemplate(
                individualReportTemplate.report_template.id,
                selectedSessions
              ).length;
              return makeReportToGenerate(
                individualReportTemplate,
                numReports,
                localIndividualReportTemplates.length === 1
              );
            }
          );

        return individualReports.every(report => report.quantity > 0)
          ? [teamReport, ...individualReports]
          : [teamReport];
      }
    }
  }
  return [];
}

export function enoughReportsSelected(reportsToGenerate: ReportToGenerate[]) {
  return reportsToGenerate.some(report => report.quantity === 0)
    ? true
    : reportsToGenerate.length === 1 ||
        reportsToGenerate.filter(report => report.selected).length > 1;
}

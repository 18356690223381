import _ from 'underscore';
import { Map, fromJS, List } from 'immutable';

import combineImmutableReducers from '^/combineImmutableReducers';
import composeReducers from './composeReducers';
import * as actions from '^/actions/actions';
import { CLOSE_TOP_MODAL } from '^/actions/modals';
import * as collections from '^/actions/collections';
import * as items from '^/actions/items';
import * as datafilters from '^/actions/datafilters';
import * as shop from '^/actions/shop';
import * as analytics from '^/actions/analytics';
import * as responseStates from '^/responseStates';

export const CONNECTION_ERROR =
  'Connection error. Check connection and try again.';

function makeResponseReducer(actionSet) {
  return (state = Map(), action) => {
    switch (action.type) {
      case actionSet.REQUEST:
        return Map(
          action.error
            ? { state: responseStates.FAILED, errors: List([CONNECTION_ERROR]) }
            : { state: responseStates.PENDING }
        ).merge(action.meta);
      case actionSet.SUCCESS:
        return Map({
          state: responseStates.SUCCESSFUL,
          payload: fromJS(action.payload),
        }).merge(action.meta);
      case actionSet.FAILURE:
        return Map({
          state: responseStates.FAILED,
          errors: action.error ? fromJS(action.payload.response) : null,
        }).merge(action.meta);
      default:
        return state;
    }
  };
}

function makeMultiSourceResponseReducer(actionSet) {
  const responseReducer = makeResponseReducer(actionSet);
  return (state = Map(), action) => {
    const responseState = responseReducer(null, action);
    return responseState ? state.set(action.meta.source, responseState) : state;
  };
}

function makeResponsesReducer(actionsByKey, _makeResponseReducer) {
  return combineImmutableReducers(
    _.mapObject(actionsByKey, actionOrActions =>
      actionOrActions instanceof Array
        ? composeReducers(actionOrActions.map(_makeResponseReducer), Map({}))
        : _makeResponseReducer(actionOrActions)
    )
  );
}

const responsesReducer = makeResponsesReducer(
  {
    forgotPassword: actions.SEND_RECOVERY_LINK,
    resetPassword: actions.RESET_PASSWORD,
    login: actions.LOGIN,
    externalLogin: actions.EXTERNAL_LOGIN,
    sudoLogin: actions.SUDO_LOGIN,
    inviteUser: actions.INVITE_USER,
    checkToken: actions.AUTHENTICATE_TOKEN,
    redeem: actions.REDEEM_INVITE,
    updateProfile: actions.UPDATE_PROFILE,
    getUserInfo: actions.GET_USER_INFO,
    getCollection: collections.GET_COLLECTION,
    addToCollection: collections.ADD_TO_COLLECTION,
    updateItem: collections.UPDATE_ITEM,
    loadItem: items.LOAD_ITEM,
    addUsersToActivity: actions.ADD_USERS_TO_ACTIVITY,
    removeUserFromActivity: actions.REMOVE_USER_FROM_ACTIVITY,
    addGroupsToActivity: actions.ADD_GROUPS_TO_ACTIVITY,
    getProductVersion: actions.GET_PRODUCT_VERSION,
    getMyAnswers: actions.GET_MY_ANSWERS,
    getMyImageMatchAnswers: actions.GET_MY_IMAGE_MATCH_ANSWERS,
    getMyMultiLikertAnswers: actions.GET_MY_MULTI_LIKERT_ANSWERS,
    answerQuestion: [
      actions.ANSWER_QUESTION,
      actions.ANSWER_MULTIPLE_QUESTIONS,
      actions.ANSWER_MULTI_LIKERT_QUESTION,
      actions.SAVE_ISP_ANSWERS,
      actions.SAVE_RANKING_ANSWERS,
    ],
    saveISPAnswers: actions.SAVE_ISP_ANSWERS,
    saveRankingAnswers: actions.SAVE_RANKING_ANSWERS,
    saveSJTAnswers: actions.SAVE_SJT_ANSWERS,
    startActivityProductVersionSession:
      actions.START_ACTIVITY_PRODUCT_VERSION_SESSION,
    createActivityProductVersionSessionEvent:
      actions.CREATE_ACTIVITY_PRODUCT_VERSION_SESSION_EVENT,
    importUsers: actions.IMPORT_USERS,
    notificationEmails: [
      actions.CREATE_NOTIFICATION_EMAIL,
      actions.UPDATE_NOTIFICATION_EMAIL,
      actions.DELETE_NOTIFICATION_EMAIL,
      actions.RESEND_NOTIFICATION_EMAIL,
    ],
    nominationRules: [
      actions.ADD_NOMINATION_RULE,
      actions.DELETE_NOMINATION_RULE,
    ],
    averageValues: actions.VIEW_AVERAGES_REPORT,
    rankQuestionOrder: [
      actions.SAVE_PERSPECTIVES_LIKERT,
      actions.GET_COMPETENCIES_GROUPED_QUESTIONS,
    ],
    stats: actions.GET_STATS,
    signUpUser: actions.SIGN_UP_USER,
    accountSignUp: actions.ACCOUNT_SIGN_UP,
    signUpAnonymousUser: actions.SIGN_UP_ANONYMOUS_USER,
    verifyValidationCode: actions.VERIFY_VALIDATION_CODE,
    addRaterUser: actions.ADD_RATER_USER,
    addMultipleRaterUsers: actions.ADD_MULTIPLE_RATER_USERS,
    addRaterToActivity: actions.ADD_RATER_TO_ACTIVITY,
    removeRaterFromActivity: actions.REMOVE_RATER_FROM_ACTIVITY,
    removeLineManagerFromActivity: actions.REMOVE_LINE_MANAGER_FROM_ACTIVITY,
    addReportRecipient: actions.ADD_REPORT_RECIPIENT,
    removeReportRecipient: actions.REMOVE_REPORT_RECIPIENT,
    muteActivityNotification: actions.MUTE_ACTIVITY_NOTIFICATION,
    updateActivityReportVisibility: actions.UPDATE_ACTIVITY_REPORT_VISIBILITY,
    updateRater: actions.UPDATE_RATER,
    updateActivityProductVersionSession:
      actions.UPDATE_ACTIVITY_PRODUCT_VERSION_SESSION,
    createReports: actions.CREATE_REPORTS,
    generateTeamReport: actions.GENERATE_TEAM_REPORT,
    loadOrganisationFilters: datafilters.LOAD_ORGANISATION_FILTERS,
    loadActivityFilters: datafilters.LOAD_ACTIVITY_FILTERS,
    loadUserFilters: datafilters.LOAD_USER_FILTERS,
    loadPsychometricFilters: datafilters.LOAD_PRODUCT_FILTERS,
    activityReports: [actions.GET_ORGANISATION_ALL_REPORTS],
    addAdminToOrganisation: actions.ADD_ADMIN_TO_ORGANISATION,
    addProductToAccount: actions.ADD_PRODUCT_TO_ACCOUNT,
    addUsersToGroup: actions.ADD_USERS_TO_GROUP,
    addRemoveUsersGroup: actions.ADD_REMOVE_USERS_GROUP,
    shareAccessWithUser: actions.SHARE_ACCESS_WITH_USER,
    revokeAccessFromUser: actions.REVOKE_ACCESS_FROM_USER,
    dismissShareNotifications: actions.DISMISS_SHARE_NOTIFICATIONS,
    getStripeInvoice: actions.GET_STRIPE_INVOICE,
    getStripeShopInvoice: actions.GET_STRIPE_SHOP_INVOICE,
    addCredits: actions.ADD_CREDITS_TO_ORGANISATION,
    getStats: actions.GET_STATS,
    createReportGenerationBatch: actions.CREATE_REPORT_GENERATION_BATCH,
    updateReportGenerationBatch: actions.UPDATE_REPORT_GENERATION_BATCH,
    setManualReportGeneration: actions.SET_MANUAL_REPORT_GENERATION,
    setLineManager: actions.SET_LINE_MANAGER,
    sendReports: actions.SEND_REPORTS,
    loadOrgusers: actions.LOAD_ORG_USERS,
    loadOrgProducts: actions.LOAD_ORG_PRODUCTS,
    loadOrgSessions: actions.LOAD_ORG_SESSIONS,
    loadOrgFilters: actions.LOAD_ORG_FILTERS,
    addProductOrganisations: actions.ADD_PRODUCT_ORGANISATIONS,
    shopItems: shop.GET_SHOP_ITEMS,
    sendEnquiryEmail: shop.SEND_ENQUIRY_EMAIL,
    updateBasketItemQuantity: shop.UPDATE_BASKET_ITEM_QUANTITY,
    updateBasketItemReportQuantity: shop.UPDATE_BASKET_ITEM_REPORT_QUANTITY,
    inviteToStageTwo: actions.INVITE_TO_STAGE_TWO,
    getAnalyticsFilters: analytics.GET_ANALYTICS_FILTERS,
    getAnalyticsFiltersHeatmap: analytics.GET_ANALYTICS_FILTERS_HEATMAP,
    getAnalyticsSessions: analytics.GET_ANALYTICS_SESSIONS,
    getAnalyticsSessionsModal: analytics.GET_ANALYTICS_SESSIONS_MODAL,
    submitUserPulseRatings: actions.SUBMIT_USER_PULSE_RATINGS,
    setPulseBehaviours: actions.SET_PULSE_BEHAVIOURS,
    setPulseRaters: actions.SET_PULSE_RATERS,
    loadUserPulseDetail: actions.LOAD_USER_PULSE_DETAIL,
    clearUserPulseDetail: actions.CLEAR_USER_PULSE_DETAIL,
    loadUserPulseRater: actions.LOAD_USER_PULSE_RATER,
    startPulses: actions.START_PULSES,
    createPulse: actions.CREATE_PULSE,
    updatePulse: actions.UPDATE_PULSE,
    getOrgFlexiPulseNames: actions.GET_ORG_FLEXI_PULSE_NAMES,
    submitConsentAgree: actions.SUBMIT_CONSENT_AGREE,
    submitConsentDisagree: actions.SUBMIT_CONSENT_DISAGREE,
    setNotificationEmailLanguagePreference:
      actions.SET_NOTIFICATION_EMAIL_LANGUAGE_PREFERENCE,
    getOrganisationDetailSimple: actions.GET_SIMPLE_ORGANISATION,
    pulseRespondentsSummary: actions.GET_PULSE_RESPONDENTS_SUMMARY,
    createAdvancedAnalyticsFilterProfile:
      analytics.CREATE_ADVANCED_ANALYTICS_FILTER_PROFILE,
    updateAdvancedAnalyticsFilterProfile:
      analytics.UPDATE_ADVANCED_ANALYTICS_FILTER_PROFILE,
    getAdvancedAnalyticsFilterProfiles:
      analytics.GET_ADVANCED_ANALYTICS_FILTER_PROFILES,
    deleteAdvancedAnalyticsFilterProfile:
      analytics.DELETE_ADVANCED_ANALYTICS_FILTER_PROFILE,
    deletePulseRaterExternal: actions.DELETE_PULSE_RATER_EXTERNAL,
    savePerspectivesLikert: actions.SAVE_PERSPECTIVES_LIKERT,
  },
  makeResponseReducer
);

const multiResponsesReducer = makeResponsesReducer(
  {
    answerQuestion: actions.ANSWER_QUESTION,
    getCollection: collections.GET_COLLECTION,
    updateItem: collections.UPDATE_ITEM,
    loadItem: items.LOAD_ITEM,
    addProductToAccount: actions.ADD_PRODUCT_TO_ACCOUNT,
    removeAdminFromOrganisation: actions.REMOVE_ADMIN_FROM_ORGANISATION,
    addReportToProductOrganisation: actions.ADD_REPORT_TO_PRODUCT_ORGANISATION,
    removeReportFromProductOrganisation:
      actions.REMOVE_REPORT_FROM_PRODUCT_ORGANISATION,
  },
  makeMultiSourceResponseReducer
);

const makeResponses = _responsesReducer => (state = Map(), action) => {
  const updatedState = _responsesReducer(state, action);

  if (action.type === actions.RESET_RESPONSE) {
    return updatedState.delete(action.payload);
  } else if (action.type === CLOSE_TOP_MODAL && action.payload.clearResponses) {
    return _responsesReducer(undefined, {});
  }
  return updatedState;
};

export const responses = makeResponses(responsesReducer);
export const multiResponses = makeResponses(multiResponsesReducer);

export default {
  responses: makeResponses(responsesReducer),
  multiResponses: makeResponses(multiResponsesReducer),
};

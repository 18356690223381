import React from 'react'; // eslint-disable-line no-unused-vars
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import i18next from 'i18next';

import PureComponent from '../../PureComponent';
import Loading from '../../Loading';
import { clearTokenExternalLoginAndRedirect } from '../../../actions/actionSequences';
import { hasFailed } from '../../../responseStates';
import { withRouter } from '^/withRouter';
import Alert from '^/components/Alert';

export class Authenticate extends PureComponent {
  UNSAFE_componentWillMount() {
    const { user, activity, raterFor } = this.props.routerParams;
    if (user === 'profile-rater') {
      this.props.clearTokenExternalLoginAndRedirect(
        activity,
        `/page/external/${raterFor}/profile-rater`
      );
    } else {
      this.props.clearTokenExternalLoginAndRedirect(
        user,
        `/page/external/${activity}/${raterFor}`
      );
    }
  }

  renderLoginError() {
    const errors = this.props.response.get('errors', Map()).toObject();

    if (errors.error === 'User no longer EXTERNAL') {
      if (errors.invite_id) {
        this.props.push(`/invite/${errors.invite_id}/redeem/`);
      } else {
        this.props.push('/login/');
      }
    }

    return (
      <div className="container">
        <div className="no-auth content-box">
          <div className="row">
            <div className="col-xs-12">
              <h3>{i18next.t('Unrecognised link')}</h3>
              <Alert>
                {i18next.t('Sorry, no matching rater was found.')}
                <br />
                {i18next.t(
                  'If you clicked a link then it may be corrupt, or the activity may have been modified.'
                )}
              </Alert>
              <hr />
              <dl>
                {this.props.response
                  .get('errors', Map())
                  .map((value, key) => (
                    <div key={key}>
                      <dt>{key === 'non_field_errors' ? 'Detail' : key}:</dt>
                      <dd>{value}</dd>
                    </div>
                  ))
                  .toArray()}
              </dl>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return hasFailed(this.props.response) ? (
      this.renderLoginError()
    ) : (
      <Loading />
    );
  }
}

function mapStateToProps(state, props) {
  return {
    routerParams: props.router.params,
    response: state.responses.get('externalLogin'),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    clearTokenExternalLoginAndRedirect,
    push,
  })(Authenticate)
);

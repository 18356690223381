import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Map } from 'immutable';

import { StoreState } from '^/store';
import { verifyProfile } from '^/actions/actionSequences';
import { closeTopModal } from '^/actions/modals';
import { SuccessProfileSection, Uuid } from '^/reducers/api/types';

interface StateProps {
  response: Map<string, any>;
}

interface DispatchProps {
  verifyProfile: typeof verifyProfile;
  closeTopModal: typeof closeTopModal;
}

interface OwnProps {
  activityId: Uuid;
  profileId: Uuid;
  data: SuccessProfileSection;
}

type Props = StateProps & DispatchProps & OwnProps;
const VerifyProfileModal: React.FC<Props> = props => {
  const cancel = () => {
    props.closeTopModal();
  };
  return (
    <div>
      <p>
        {i18next.t<String>(
          'Please confirm you are ready to verify the profile. The profile will be based on the ratings entered by you on the profile summary.'
        )}
      </p>
      <p>
        <b>
          {i18next.t<String>(
            'Note: You may re-verify this profile if you need to, but be aware that once a report is generated you can no longer edit.'
          )}
        </b>
      </p>

      <div className="modal-footer clearfix">
        <div className="pull-right">
          <button className="btn btn-default" onClick={() => cancel()}>
            {i18next.t<String>('Cancel')}
          </button>
          <button
            className="btn btn-primary"
            disabled={false}
            onClick={() =>
              props.verifyProfile(props.activityId, props.profileId, props.data)
            }
          >
            {i18next.t<String>('Verify')}
          </button>
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (state: StoreState): StateProps => ({
  response: state.responses.get('addToCollection'),
});

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
  mapStateToProps,
  {
    verifyProfile,
    closeTopModal,
  }
)(VerifyProfileModal);

import i18next from 'i18next';
import { List, Map } from 'immutable';
import React, { PureComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

import {
  setProductVersionPage,
  storeProductVersionAnswer,
} from '^/actions/actions';
import {
  answerMultiLikertQuestionAndAdvance,
  goToMultiLikertQuestionPrevious,
  answerMultiLikertQuestionAndClose,
  answerMultiLikertQuestionAndSubmit,
  answerMultiLikertQuestionAndOnlySubmit,
  goToMultiLikertQuestionNext,
} from '^/actions/actionSequences';
import { anyPending } from '^/responseStates';
import { StoreState } from '^/store';
import AssessmentBody from './AssessmentBody';
import AssessmentContainer from './AssessmentContainer';
import AssessmentFooter from './AssessmentFooter';
import AssessmentHeader from './AssessmentHeader';
import MultiLikertQuestion, {
  MultiLikertValuetype,
  getOtherValue,
} from './MultiLikertQuestion';
import MultiLikertQuestionProgress from './MultiLikertQuestionProgress';
import ScrollTopOnPageChange from './ScrollTopOnPageChange';
import { ACTIVITY_TYPE } from '^/models/activity';

interface OwnProps {
  activity: Map<string, any>;
  productVersion: Map<string, any>;
  multiLikertQuestionsWithAnswers: List<List<Map<string, any>>>;
  questionCollectionIdx: number;
}

interface StateProps {
  progress: Map<string, any>;
  isPending: boolean;
  response: Map<string, any>;
  raterFor: Map<string, any>;
  uiLanguage: string;
  myAnswers: Map<string, any>;
}

interface DispatchProps {
  setProductVersionPage: typeof setProductVersionPage;
  storeProductVersionAnswer: typeof storeProductVersionAnswer;
  push: typeof push;
  goToMultiLikertQuestionPrevious: typeof goToMultiLikertQuestionPrevious;
  goToMultiLikertQuestionNext: typeof goToMultiLikertQuestionNext;
  answerMultiLikertQuestionAndAdvance: typeof answerMultiLikertQuestionAndAdvance;
  answerMultiLikertQuestionAndSubmit: typeof answerMultiLikertQuestionAndSubmit;
  answerMultiLikertQuestionAndOnlySubmit: typeof answerMultiLikertQuestionAndOnlySubmit;
  answerMultiLikertQuestionAndClose: typeof answerMultiLikertQuestionAndClose;
}

type Props = OwnProps & StateProps & DispatchProps;

export class MultiLikertProductVersion extends PureComponent<Props> {
  public componentDidMount() {
    const currentQuestionIndex = this.props.multiLikertQuestionsWithAnswers.findIndex(
      questionAndAnswer => {
        return !questionAndAnswer.get(1);
      }
    );
    if (currentQuestionIndex === -1) {
      // We've already answered this productVersion, so jump to the last page
      this.props.setProductVersionPage(
        this.props.multiLikertQuestionsWithAnswers.count() - 1
      );
    } else {
      this.props.setProductVersionPage(currentQuestionIndex);
    }
  }

  public render() {
    const {
      productVersion,
      questionCollectionIdx,
      raterFor,
      activity,
      myAnswers,
    } = this.props;
    const isProfiler = Boolean(activity.get('type') === ACTIVITY_TYPE.PROFILER);
    const currentQuestionAndAnswer = this.getCurrentQuestion();
    const question = currentQuestionAndAnswer?.getIn([0, 0]);
    const questionID = question.get('id');
    const answer = this.props.progress.getIn(
      ['unsavedResponses', questionID],
      null
    );

    const answeredIds = myAnswers
      .map(items => items.toObject())
      .filter(item => item.question === questionID)
      .sort((a, b) => {
        if (a.value_type > b.value_type) return -1;
        if (a.value_type < b.value_type) return 1;
        return 0;
      })
      .map(item => item.value)
      .toArray();

    const canResume = this.props.progress.get('canResume');
    const currentPage = this.props.progress.get('currentPage', 0);
    const pageCount = this.pageCount();
    const isPending = this.props.isPending;
    const unSavedAnswers = this.props.progress
      .getIn(['unsavedResponses', questionID], Map())
      .toList().size;

    const action = this.isViewingLastPage()
      ? unSavedAnswers > 0
        ? this.props.answerMultiLikertQuestionAndSubmit
        : this.props.answerMultiLikertQuestionAndOnlySubmit
      : unSavedAnswers > 0
      ? this.props.answerMultiLikertQuestionAndAdvance
      : this.props.goToMultiLikertQuestionNext;

    const assessmentGuideText = isProfiler
      ? i18next.t<string>('TRANS disc assessment guide text profile')
      : i18next.t<string>('TRANS disc assessment guide text');

    const introText =
      this.getQuestionCollection().get('on_page_guide_text') ||
      assessmentGuideText;
    return (
      <AssessmentContainer>
        <AssessmentHeader
          productVersion={productVersion}
          questionCollectionIdx={questionCollectionIdx}
          raterFor={raterFor}
          steps={pageCount}
          currentStep={currentPage}
        />

        <AssessmentBody
          className="mb-none"
          header={
            <MultiLikertQuestionProgress
              questionNumber={currentPage + 1}
              numberOfQuestions={pageCount}
            />
          }
          headerInstructions={<ReactMarkdown source={introText} />}
          questionIndex={currentPage}
        >
          <ScrollTopOnPageChange page={currentPage} />
          <MultiLikertQuestion
            type={MultiLikertValuetype.Most}
            question={question}
            answer={answer}
            onAnswer={this.onAnswer.bind(this, question)}
            isPending={isPending}
            answeredId={answeredIds && answeredIds[0]}
            answeredIds={answeredIds}
            isProfiler={isProfiler}
          />
          <MultiLikertQuestion
            type={MultiLikertValuetype.Least}
            question={question}
            answer={answer}
            onAnswer={this.onAnswer.bind(this, question)}
            isPending={isPending}
            answeredId={answeredIds && answeredIds[1]}
            answeredIds={answeredIds}
            isProfiler={isProfiler}
          />
        </AssessmentBody>

        <AssessmentFooter
          isNotComplete={!this.isPageComplete(question, answeredIds)}
          isSaving={isPending}
          showContinue={canResume}
          onContinueLater={this.confirmAndClose.bind(this, question)}
          onContinue={this.onNext.bind(this, question, answeredIds, action)}
          onBack={this.onBack.bind(this, question, answeredIds)}
          isEnd={this.isViewingLastPage()}
          isStart={this.isFirstViewingPage()}
          isProfiler={isProfiler}
        />
      </AssessmentContainer>
    );
  }

  private pageCount() {
    return this.props.multiLikertQuestionsWithAnswers.count();
  }

  private isViewingLastPage() {
    return this.props.progress.get('currentPage', 0) === this.pageCount() - 1;
  }

  private isFirstViewingPage() {
    return this.props.progress.get('currentPage', 0) === 0;
  }

  private onAnswer(question: Map<string, any>, type: string, valueId: string) {
    const currentAnswer = this.props.progress.getIn(
      ['unsavedResponses', question.get('id')],
      Map()
    );
    this.props.storeProductVersionAnswer(
      question.get('id'),
      currentAnswer.set(type.toLowerCase(), valueId)
    );
  }

  private getCurrentQuestion() {
    // TODO: use .some()
    return this.props.multiLikertQuestionsWithAnswers.filter(
      questionAnswerPair =>
        questionAnswerPair.getIn([0, 'position']) ===
        this.props.progress.get('currentPage', 0)
    );
  }

  private getAnswers(question: Map<string, any>, answeredIds: Array<string>) {
    const questionId = question.get('id');
    const answers = this.props.progress
      .getIn(['unsavedResponses', questionId], Map())
      .toArray();

    // when navigated back and select only 1 new answer
    if (answers.length === 1) {
      const otherValue = getOtherValue(question, answers, answeredIds);
      answers.push(otherValue.get('id'));
    }
    return answers;
  }

  private onNext(
    question: Map<string, any>,
    answeredIds: Array<string>,
    actionCreator:
      | typeof answerMultiLikertQuestionAndClose
      | typeof answerMultiLikertQuestionAndSubmit
      | typeof answerMultiLikertQuestionAndOnlySubmit
      | typeof answerMultiLikertQuestionAndAdvance
      | typeof goToMultiLikertQuestionNext
  ) {
    const nextPage = this.props.progress.get('currentPage', 0) + 1;
    const answers = List(this.getAnswers(question, answeredIds));

    actionCreator(
      this.props.activity,
      this.props.productVersion,
      answers,
      nextPage,
      this.props.uiLanguage
    );
  }

  private onBack(question: Map<string, any>, answeredIds: Array<string>) {
    const previousPage = this.props.progress.get('currentPage', 0) - 1;
    const answers = List(this.getAnswers(question, answeredIds));

    if (answers.size > 0) {
      this.props.answerMultiLikertQuestionAndAdvance(
        this.props.activity,
        this.props.productVersion,
        answers,
        previousPage
      );
    } else {
      this.props.goToMultiLikertQuestionPrevious(
        this.props.activity,
        this.props.productVersion,
        answers,
        previousPage
      );
    }
  }

  private confirmAndClose(question: any) {
    if (
      window.confirm(
        i18next.t<string>(
          'If you need to come back to finish the questionnaire, you will be able to return to where you left off.'
        )
      )
    ) {
      this.onNext(question, [], this.props.answerMultiLikertQuestionAndClose);
    }
  }

  private isPageComplete(
    question: Map<string, any>,
    answeredIds: Array<string> | null
  ) {
    const questionId = question?.get('id');
    const answers = this.props.progress.getIn(
      ['unsavedResponses', questionId],
      Map()
    );
    return (
      (answers.get('most', false) && answers.get('least', false)) ||
      (answeredIds && answeredIds.length > 0)
    );
  }

  private getQuestionCollection() {
    const { productVersion, questionCollectionIdx } = this.props;
    return productVersion.getIn([
      'questioncollection_set',
      questionCollectionIdx,
    ]);
  }
}

function mapStateToProps(state: StoreState) {
  return {
    progress: state.productVersionProgress,
    isPending: anyPending([
      state.responses.get('getCollection'),
      state.responses.get('answerQuestion'),
      state.responses.get('updateActivityProductVersionSession'),
    ]),
    response: state.responses.get('answerQuestion'),
    myAnswers: state.myAnswers,
    uiLanguage: state.ui.get('uiLanguage'),
  };
}

export default connect(mapStateToProps, {
  setProductVersionPage,
  storeProductVersionAnswer,
  push,
  goToMultiLikertQuestionNext,
  answerMultiLikertQuestionAndAdvance,
  goToMultiLikertQuestionPrevious,
  answerMultiLikertQuestionAndSubmit,
  answerMultiLikertQuestionAndOnlySubmit,
  answerMultiLikertQuestionAndClose,
})(MultiLikertProductVersion);

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SuccessProfileSection, SuccessRanges } from '^/reducers/api/types';
import { deepCopy } from '^/utils';
import RangeSlider from '../RangeSlider';
import { closeTopModal } from '^/actions/modals';

interface Props {
  showSlider: string;
  sliderRanges: SuccessRanges;
  results: SuccessProfileSection;
  setResults: (results: SuccessProfileSection) => void;
  closeTopModal: typeof closeTopModal;
}

export const ProfileRangeSliderModal: React.FC<Props> = props => {
  const [ranges, setSliderRanges] = useState<SuccessRanges>(props.sliderRanges);
  const onHandleChange = (
    _event: React.ChangeEvent<{}>,
    newValues: number[]
  ) => {
    setSliderRanges({ low: newValues[0], high: newValues[1] });
    const listCopy: SuccessProfileSection = deepCopy(props.results);
    listCopy[parseInt(props.showSlider.split('_')[1], 10)].range_low =
      newValues[0];
    listCopy[parseInt(props.showSlider.split('_')[1], 10)].range_high =
      newValues[1];
    props.setResults(listCopy);
  };

  return (
    <div>
      <div className="modal-header pull-right">
        <div
          className="fa-link close-btn"
          onClick={() => props.closeTopModal()}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div className="modal-body">
        <RangeSlider
          className="slider-padding"
          aria-labelledby="discrete-slider-always"
          value={[ranges.low, ranges.high]}
          step={1}
          max={10}
          min={1}
          marks={Array.from({ length: 10 }, (_, i) => i + 1).map(i => {
            return {
              label: i,
              value: i,
            };
          })}
          valueLabelDisplay="on"
          onChange={onHandleChange as any}
        />
      </div>
    </div>
  );
};

export default connect(null, { closeTopModal })(ProfileRangeSliderModal);

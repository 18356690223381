/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';

import { SuccessProfileItem } from '^/reducers/api/types';
import FactorGroup from './FactorGroup';

interface GroupConfig {
  title: string;
  indexLow: number;
  indexHigh: number;
  hasRanges: boolean;
}

interface Props {
  title: string;
  items: Array<SuccessProfileItem>;
  groups: Array<GroupConfig>;
}

const ProfileSection: React.FC<Props> = ({ title, items, groups }) => {
  return (
    <div className="profile-chart-section-container">
      <h2>{title}</h2>
      <div className="profile-chart-section">
        {groups.map((group, index) => (
          <FactorGroup
            key={index}
            title={group.title}
            items={items.slice(group.indexLow, group.indexHigh)}
            hasRanges={group.hasRanges}
          />
        ))}
      </div>
    </div>
  );
};

export default ProfileSection;

import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import {
  EMAIL_TYPES,
  sentToAdmin,
  isReportNotification,
  isDisabledForPrepay,
} from '^/models/notification';
import PureComponent from '^/components/PureComponent';
import Notification from '^/components/activities/notifications/Notification';
import { isPending } from '^/responseStates';
import OnOffToggle from '^/components/OnOffToggle';

export default class ActivityNotificationsTab extends PureComponent {
  update(data) {
    this.props.updateActivity(this.props.activity.get('id'), data);
  }

  completeAndThen(callback) {
    this.update({ notification_emails_completed: true });
    callback();
  }

  setAdminNotificationsMuted(muted) {
    this.update({ admin_notifications_muted: muted });
  }

  setParticipantNotificationsMuted(muted) {
    this.update({ participant_notifications_muted: muted });
  }

  renderRespondentTabLabel(activity) {
    switch (activity.get('type')) {
      case 'THREE_SIXTY':
        return i18next.t('Respondent, Line Manager and Rater notifications');
      case 'PROFILER':
        return i18next.t('Profile raters notifications');
      default:
        return i18next.t('Respondent notifications');
    }
  }

  render() {
    const {
      notification_emails,
      activity,
      hasStartAndEnd,
      muteNotificationsResponse,
      response,
      readOnly,
    } = this.props;
    const {
      admin_notifications_muted,
      participant_notifications_muted,
      is_prepay: isPrepay,
    } = activity.toObject();

    const filteredNotifications = isPrepay
      ? notification_emails.filterNot(isDisabledForPrepay)
      : notification_emails;
    const sortedNotifications = filteredNotifications.sortBy(email =>
      EMAIL_TYPES.ORDER.indexOf(email.get('type'))
    );
    const adminNotifications = sortedNotifications.filter(sentToAdmin);
    const participantNotifications = sortedNotifications
      .filterNot(sentToAdmin)
      .filterNot(isReportNotification);
    const isLoading = isPending(response);

    return (
      <div className="row">
        <div className="col-xs-12 col-md-6 tabcontent-column tabcontent-admin-column first">
          <div className="row notification notification-header">
            <div className="col-xs-12 col-md-9 notification-content-column">
              <div className="title notification-label">
                {i18next.t('Admin notifications')}
              </div>
            </div>

            <div className="col-xs-12 col-md-3 notification-buttons-column">
              <OnOffToggle
                isLoading={
                  isLoading &&
                  response.getIn(['data', 'admin_notifications_muted']) !==
                    undefined
                }
                value={!admin_notifications_muted}
                onChange={() =>
                  this.setAdminNotificationsMuted(!admin_notifications_muted)
                }
                disabled={readOnly}
              />
            </div>
          </div>

          {adminNotifications.map((notification, index) => (
            <Notification
              key={notification.get('type')}
              index={index}
              total={adminNotifications.count() || 0}
              activity={activity}
              notification={notification}
              muteResponse={muteNotificationsResponse}
              hasStartAndEnd={!!hasStartAndEnd}
              readOnly={readOnly || admin_notifications_muted}
              response={response}
            />
          ))}
        </div>

        {!participantNotifications.isEmpty() && (
          <div className="col-xs-12 col-md-6 tabcontent-column tabcontent-respondent-column">
            <div className="row notification notification-header">
              <div className="col-xs-12 col-md-9 notification-content-column">
                <div className="title notification-label">
                  {this.renderRespondentTabLabel(activity)}
                </div>
              </div>

              <div className="col-xs-12 col-md-3 notification-buttons-column">
                <OnOffToggle
                  isLoading={
                    isLoading &&
                    response.getIn([
                      'data',
                      'participant_notifications_muted',
                    ]) !== undefined
                  }
                  value={!participant_notifications_muted}
                  onChange={() =>
                    this.setParticipantNotificationsMuted(
                      !participant_notifications_muted
                    )
                  }
                  disabled={readOnly}
                />
              </div>
            </div>

            {participantNotifications.map((notification, index) => (
              <Notification
                key={notification.get('type')}
                index={index}
                total={participantNotifications.count() || 0}
                activity={activity}
                notification={notification}
                muteResponse={muteNotificationsResponse}
                hasStartAndEnd={!!hasStartAndEnd}
                readOnly={readOnly || participant_notifications_muted}
                response={response}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

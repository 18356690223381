import React, { useState } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';

import { loadSuccessProfile } from '^/actions/items';
import PotentialProfileSummarySection from './PotentialProfileSummarySection';
import CompetenciesProfileSummarySection from './CompetenciesProfileSummarySection';
import MotivatorsProfileSummarySection from './MotivatorsProfileSummarySection';
import NewButton from '../NewButton';
import {
  DefaultRanges,
  SuccessProfileSection,
  Uuid,
} from '^/reducers/api/types';
import { ExpandableBox } from '../ExpandableBox';
import { openAnotherModal, openVerifyProfileModal } from '^/actions/modals';
interface DispatchProps {
  loadSuccessProfile: typeof loadSuccessProfile;
  openAnotherModal: typeof openAnotherModal;
  openVerifyModal: typeof openVerifyProfileModal;
}

interface OwnProps {
  title: string;
  canVerify: boolean;
  potentials: SuccessProfileSection;
  motivators: SuccessProfileSection;
  competencies: SuccessProfileSection;
  profileId: Uuid;
  activityId: Uuid;
  defaultRanges: DefaultRanges;
}
type Props = DispatchProps & OwnProps;

export const ProfileSummarySection: React.FC<Props> = ({
  activityId,
  canVerify,
  potentials,
  motivators,
  competencies,
  openVerifyModal,
  profileId,
  defaultRanges,
}) => {
  const [potentialResults, setPotentials] = useState<SuccessProfileSection>(
    potentials
  );
  const [motivatorsResults, setMotivators] = useState<SuccessProfileSection>(
    motivators
  );
  const [competenciesResults, setCompetencies] = useState<
    SuccessProfileSection
  >(competencies);

  return (
    <div>
      <ExpandableBox
        defaultExpanded={false}
        title={i18next.t<string>('Potential')}
      >
        <PotentialProfileSummarySection
          title={i18next.t<string>('Potential')}
          potentialResults={potentialResults}
          setResults={setPotentials}
          canVerify={canVerify}
          defaultRanges={defaultRanges}
        />
      </ExpandableBox>
      <ExpandableBox
        defaultExpanded={false}
        title={i18next.t<string>('Competencies')}
      >
        <CompetenciesProfileSummarySection
          title={i18next.t<string>('Competencies')}
          competenciesResults={competenciesResults}
          setResults={setCompetencies}
          canVerify={canVerify}
          defaultRanges={defaultRanges}
        />
      </ExpandableBox>
      <ExpandableBox
        defaultExpanded={false}
        title={i18next.t<string>('Motivators')}
      >
        <MotivatorsProfileSummarySection
          title={i18next.t<string>('Motivators')}
          motivatorsResults={motivatorsResults}
          setResults={setMotivators}
          canVerify={canVerify}
        />
      </ExpandableBox>
      {canVerify && (
        <div className="button-wrapper">
          <NewButton
            onClick={() =>
              openVerifyModal(activityId, profileId, [
                ...potentialResults,
                ...motivatorsResults,
                ...competenciesResults,
              ])
            }
          >
            {i18next.t<string>('Verify Profile')}
          </NewButton>
        </div>
      )}
    </div>
  );
};

export default connect(null, {
  loadSuccessProfile,
  openAnotherModal,
  openVerifyModal: openVerifyProfileModal,
})(ProfileSummarySection);

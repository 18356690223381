import { faArrowsAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { List } from 'immutable';
import React, { PureComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import i18next from 'i18next';

import DragAndDropGroup from '^/components/drag-and-drop/DragAndDropGroup';
import DragAndDropHandler from '^/components/drag-and-drop/DragAndDropHandler';
import Draggable from '^/components/drag-and-drop/Draggable';
import DragTarget from '^/components/drag-and-drop/DragTarget';
import {
  Item,
  QuestionGroup,
  State,
  getItemId,
  getItemText,
  getItemTooltip,
  isEmpty,
  isEmptyBoolean,
  prepareGroups,
  shouldSwap,
} from '^/components/dashboard/enduser/answer/utils';
import {
  SUCCESS_PROFILE_POTENTIAL_QUESTION_COUNT,
  SUCCESS_PROFILE_MOTIVATORS_QUESTION_COUNT,
} from '^/constants/assessments';

interface OwnProps {
  questionCollectionIdx: number;
  isPending: boolean;
  questionGroup: QuestionGroup;
  reorderAnswers: (answers: List<string | null>) => void;
}

type Props = OwnProps;
const getGroupCssName = (index: number, questionCount: number) => {
  switch (true) {
    case (index < 5 &&
      questionCount === SUCCESS_PROFILE_POTENTIAL_QUESTION_COUNT) ||
      (index < 10 &&
        questionCount === SUCCESS_PROFILE_MOTIVATORS_QUESTION_COUNT):
      return questionCount + '-one';
    case (index >= 5 &&
      index < 10 &&
      questionCount === SUCCESS_PROFILE_POTENTIAL_QUESTION_COUNT) ||
      (index >= 10 &&
        index < 20 &&
        questionCount === SUCCESS_PROFILE_MOTIVATORS_QUESTION_COUNT):
      return questionCount + '-two';
    case (index >= 10 &&
      questionCount === SUCCESS_PROFILE_POTENTIAL_QUESTION_COUNT) ||
      (index >= 20 &&
        index < 30 &&
        questionCount === SUCCESS_PROFILE_MOTIVATORS_QUESTION_COUNT):
      return questionCount + '-three';
    case index >= 30 &&
      questionCount === SUCCESS_PROFILE_MOTIVATORS_QUESTION_COUNT:
      return questionCount + '-four';
    default:
      return '';
  }
};

const displayLeastMost = (item: Item, index: number, questionCount: number) => {
  if (isEmpty(item)) {
    switch (true) {
      case index === 0 ||
        ((index === 5 || index === 10) &&
          questionCount === SUCCESS_PROFILE_POTENTIAL_QUESTION_COUNT) ||
        ((index === 10 || index === 20 || index === 30) &&
          questionCount === SUCCESS_PROFILE_MOTIVATORS_QUESTION_COUNT):
        return <span> - {i18next.t<string>('Most')}</span>;
      case ((index === 4 || index === 9 || index === 14) &&
        questionCount === SUCCESS_PROFILE_POTENTIAL_QUESTION_COUNT) ||
        ((index === 9 || index === 19 || index === 29 || index === 36) &&
          questionCount === SUCCESS_PROFILE_MOTIVATORS_QUESTION_COUNT):
        return <span> - {i18next.t<string>('least')}</span>;
      default:
        return;
    }
  }
};

export class SuccessProfileRankedQuestionGroup extends PureComponent<
  Props,
  State
> {
  public constructor(props: Props) {
    super(props);

    this.state = prepareGroups(props.questionGroup);
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.questionGroup !== this.props.questionGroup) {
      this.setState(prepareGroups(this.props.questionGroup));
    }
  }

  public render() {
    const questionCount = this.state.to.length;
    return (
      <>
        <div className="drag-header-text">
          <div>
            {questionCount === 15
              ? i18next.t<string>('Psychological capability')
              : i18next.t<string>('Competencies')}
          </div>
          <hr className="blue-line small" />
        </div>

        <DragAndDropHandler
          groups={this.state}
          onDrop={this.onDrop}
          shouldSwap={shouldSwap}
          isEmpty={isEmptyBoolean}
          className="isp-drag-and-drop-container"
        >
          {group => (
            <DragAndDropGroup<Item>
              key={group}
              group={group}
              tag="ul"
              className={classNames(
                'success-drag-and-drop-group',
                questionCount === 37 && 'competencies',
                group
              )}
            >
              {(item, index) => {
                const draggable = (
                  <Draggable
                    key={getItemId(item)}
                    index={index}
                    disableDrag={isEmpty(item)}
                    className={classNames('draggable', {
                      empty: isEmpty(item),
                    })}
                    draggedClassName="dragged"
                    placeholderClassName="placeholder"
                  >
                    <span className="draggable-text">
                      <ReactTooltip
                        id={`drag-${getItemText(item)}`}
                        backgroundColor="white"
                        border
                        borderColor="#C9CCD6"
                        clickable
                        insecure
                        resizeHide
                        textColor="black"
                        wrapper="div"
                      >
                        {getItemTooltip(item)}
                      </ReactTooltip>
                      <div className="drag-text-wrapper">
                        <div data-for={`drag-${getItemText(item)}`} data-tip>
                          {getItemText(item)}
                        </div>
                      </div>
                    </span>
                    {!isEmpty(item) && <FontAwesomeIcon icon={faArrowsAlt} />}
                  </Draggable>
                );
                const draggableTarget = (
                  <Draggable
                    key={getItemId(item)}
                    index={index}
                    disableDrag={isEmpty(item)}
                    className={classNames('draggable', {
                      empty: isEmpty(item),
                    })}
                    draggedClassName="dragged"
                    placeholderClassName="placeholder"
                  >
                    <span className="draggable-text">
                      <div className="drag-text-wrapper">
                        <div>
                          <span className="drag-target-text">
                            {index + 1}
                            {displayLeastMost(item, index, questionCount)}
                          </span>
                          <span className="ml-sm">
                            {!isEmpty(item) && getItemText(item)}
                          </span>
                        </div>
                      </div>
                    </span>
                  </Draggable>
                );
                const groupName = getGroupCssName(index, questionCount);
                return group === 'from' ? (
                  <li className="drag-container">{draggable}</li>
                ) : (
                  <>
                    {index === 0 && (
                      <div className={'drag-container-' + groupName}>
                        <div className="header-text">
                          {i18next.t<string>('Critical')}
                        </div>

                        <img src="/static/img/icons/7-10.svg" />
                        <hr className="blue-line" />
                      </div>
                    )}
                    {((index === 5 && questionCount === 15) ||
                      (index === 10 && questionCount === 37)) && (
                      <div className={'drag-container-' + groupName}>
                        <div className="header-text">
                          {i18next.t<string>('Important')}
                        </div>
                        <img src="/static/img/icons/5-8.svg" />
                        <hr className="blue-line" />
                      </div>
                    )}
                    {((index === 10 && questionCount === 15) ||
                      (index === 20 && questionCount === 37)) && (
                      <div className={'drag-container-' + groupName}>
                        <div className="header-text">
                          {i18next.t<string>('Less essential')}
                        </div>
                        <div className="center">
                          <hr className="grey-short left" />
                          <i>{i18next.t<string>('No range')} </i>
                          <hr className="grey-short right" />
                        </div>
                        <hr className="blue-line" />
                      </div>
                    )}
                    {index === 30 && questionCount === 37 && (
                      <div className={'drag-container-' + groupName}>
                        <div className="header-text">
                          {i18next.t<string>('Not applicable')}
                        </div>
                        <div className="center">
                          <hr className="grey-short left" />
                          <i>{i18next.t<string>('No range')} </i>
                          <hr className="grey-short right" />
                        </div>
                        <hr className="blue-line" />
                      </div>
                    )}
                    <DragTarget
                      key={getItemId(item)}
                      index={index}
                      tag="li"
                      className={'drag-container-' + groupName}
                    >
                      <div>{draggableTarget}</div>
                    </DragTarget>
                  </>
                );
              }}
            </DragAndDropGroup>
          )}
        </DragAndDropHandler>
      </>
    );
  }

  private onDrop = (state: State) => {
    this.setState(state);
    const newOrder = List(
      state.to.map(item => (isEmpty(item) ? null : getItemId(item)))
    );
    this.props.reorderAnswers(newOrder);
  };
}

export default SuccessProfileRankedQuestionGroup;

import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Map, Set } from 'immutable';

import { StoreState } from '^/store';
import { generateProfile } from '^/actions/actionSequences';
import { closeTopModal } from '^/actions/modals';
import { Uuid } from '^/reducers/api/types';

interface StateProps {
  response: Map<string, any>;
}

interface DispatchProps {
  generateProfile: typeof generateProfile;
  closeTopModal: typeof closeTopModal;
}

interface OwnProps {
  activityId: Set<string>;
  profileId: Uuid;
}

type Props = StateProps & DispatchProps & OwnProps;
const GenerateProfileModal: React.FC<Props> = props => {
  const cancel = () => {
    props.closeTopModal();
  };
  return (
    <div>
      <p>
        {i18next.t<String>(
          'Please confirm you are ready to generate the profile. The profile will be based on completed ratings.'
        )}
      </p>
      <p>
        <b>{i18next.t<String>('Profile note')}</b>
      </p>

      <div className="modal-footer clearfix">
        <div className="pull-right">
          <button className="btn btn-default" onClick={() => cancel()}>
            {i18next.t<String>('Cancel')}
          </button>
          <button
            className="btn btn-primary"
            disabled={false}
            onClick={() =>
              props.generateProfile(props.activityId, props.profileId)
            }
          >
            {i18next.t<String>('Generate')}
          </button>
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (state: StoreState): StateProps => ({
  response: state.responses.get('addToCollection'),
});

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
  mapStateToProps,
  {
    generateProfile,
    closeTopModal,
  }
)(GenerateProfileModal);

import i18next from 'i18next';
import { Moment } from 'moment';
import React from 'react';

import DateRangePicker from '^/components/date/DateRangePicker';
import Dropdown from '^/components/dropdown/Dropdown';
import { FormItem } from '^/components/forms/FormItem';
import { AllOrgs, Uuid } from '^/reducers/api/types';
import ProductsDropdown from './AdminReportsPage/ProductsDropdown';
import ActivitiesDropdown from './AdminReportsPage/ActivitiesDropdown';

export interface Props {
  canUserAdministerOrganisations: boolean;
  organisations: AllOrgs;
  onOrganisationClick: (orgId: string | number | null) => void;
  initialOrganisation: Uuid | null;
  isLoadingTable: boolean;
  hasOrgSelected: boolean;
  selectedDate: Readonly<{
    from?: Date;
    to?: Date;
  }>;
  setDate: (fieldName: string, date?: Moment) => void;
}

export default class ProfileReportsFilters extends React.Component<Props> {
  public render() {
    const {
      canUserAdministerOrganisations,
      organisations,
      onOrganisationClick,
      isLoadingTable,
      hasOrgSelected,
      selectedDate,
      initialOrganisation,
      setDate,
    } = this.props;

    return (
      <div className="reports-control-bar">
        {canUserAdministerOrganisations && (
          <div className="form-block">
            <FormItem label={i18next.t<string>('Account')}>
              <Dropdown
                title={i18next.t<string>('Choose an organisation')}
                items={organisations}
                onClick={onOrganisationClick}
                loading={isLoadingTable}
                failed={false}
                defaultValue={initialOrganisation || undefined}
              />
            </FormItem>
          </div>
        )}

        <div className="form-block">
          <FormItem label={i18next.t<string>('Products')}>
            <ProductsDropdown />
          </FormItem>
        </div>

        <div className="form-block">
          <FormItem label={i18next.t<string>('Activities')}>
            <ActivitiesDropdown />
          </FormItem>
        </div>

        <div className="form-block">
          <FormItem label={i18next.t<string>('Date report generated')}>
            <DateRangePicker
              clearable
              fitWidth
              onSave={setDate}
              first={{
                defaultValue: i18next.t<string>('From'),
                fieldName: 'from',
                value: selectedDate.from,
                disabled: isLoadingTable || !hasOrgSelected,
              }}
              second={{
                defaultValue: i18next.t<string>('To'),
                fieldName: 'to',
                value: selectedDate.to,
                disabled: isLoadingTable || !hasOrgSelected,
              }}
            />
          </FormItem>
        </div>
      </div>
    );
  }
}

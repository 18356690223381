import i18next from 'i18next';
import { List } from 'immutable';

import { administerOrganisations, can } from '^/capabilities';
import { makeChoices } from '^/utils-ts';
import {
  PRODUCT_TYPES,
  ACTIVITY_TYPES,
  PRODUCT_ACTIVITY_TYPES,
} from '^/components/productVersions/choices';

export const JOB_LEVELS = makeChoices([
  ['GRADUATE', 'Graduate'],
  ['INDIVIDUAL_CONTRIBUTOR', 'Individual Contributor'],
  ['FIRST_LEVEL_LEADER', 'First Level Leader'],
  ['MID_LEVEL_LEADER', 'Mid Level Leader'],
  ['SENIOR_LEVEL_LEADER', 'Senior Level Leader'],
  ['SENIOR_TECHNICAL_SPECIALIST', 'Senior Technical Specialist'],
]);

export const isNotInterruptible = product =>
  product &&
  [PRODUCT_TYPES.CHOICES.PSYCAP_POTENTIAL].includes(product.get('type'));

export const getTranslatedRespondentAssessmentChecklistInfo = () => ({
  welcomeMessageTitle: i18next.t('TRANS assessment checklist title'),
  welcomeMessageShort: i18next.t('TRANS assessment checklist short'),
  warningText: i18next.t('TRANS assessment checklist warning'),
  warningTextNonAnonymous: i18next.t(
    'TRANS assessment non anonymous checklist warning'
  ),
  cancelLabel: i18next.t('Complete another time'),
  continueLabel: i18next.t('Start'),
  checklistItems: [
    {
      text: i18next.t('TRANS assessment checklist checkbox 0'),
    },
    {
      text: i18next.t('TRANS assessment checklist checkbox 1'),
    },
    {
      text: i18next.t('TRANS assessment checklist checkbox 2'),
    },
    {
      text: i18next.t('TRANS assessment checklist checkbox 3'),
    },
    {
      text: i18next.t('TRANS assessment checklist checkbox 4'),
    },
    {
      text: i18next.t('TRANS assessment checklist checkbox 5'),
    },
  ],
  canCancel: true,
});

export const getAdditionalWelcomeScreens = jobRole => ({
  [PRODUCT_ACTIVITY_TYPES.CHOICES.PERSPECTIVES_SUCCESS_PROFILE]: [
    {
      position: 0,
      welcomeMessageTitle: i18next.t('Welcome'),
      welcomeMessageLong: i18next.t('TRANS PerspectivesProfiler 1 long', {
        jobRole,
      }),
      warningText: '',
      continueLabel: i18next.t('Next'),
      checklistItems: [],
      canCancel: false,
    },
    {
      position: 0,
      welcomeMessageTitle: i18next.t('TRANS discWelcome 1 title'),
      welcomeMessageLong: i18next.t('TRANS PerspectivesProfiler 2 long', {
        jobRole,
      }),
      warningText: '',
      continueLabel: i18next.t('Next'),
      checklistItems: [],
      canCancel: false,
    },
  ],
  [PRODUCT_TYPES.CHOICES.DISC]: [
    {
      position: 0,
      welcomeMessageTitle: i18next.t('TRANS discWelcome 1 title'),
      welcomeMessageLong: i18next.t('TRANS discWelcome 1 long'),
      warningText: i18next.t('TRANS discWelcome 1 warning'),
      continueLabel: i18next.t('Next'),
      checklistItems: [],
      canCancel: false,
    },
    {
      position: 1,
      welcomeMessageTitle: i18next.t('TRANS discWelcome 2 title'),
      welcomeMessageLong: i18next.t('TRANS discWelcome 2 long'),
      warningText: i18next.t('TRANS discWelcome 2 warning'),
      continueLabel: i18next.t('Next'),
      checklistItems: [],
      canCancel: false,
    },
  ],
  [PRODUCT_ACTIVITY_TYPES.CHOICES.PROFILER_DISC]: [
    {
      position: 0,
      welcomeMessageTitle: i18next.t('TRANS discWelcome 1 title'),
      welcomeMessageLong: i18next.t('TRANS jobProfiler 1 long', { jobRole }),
      warningText: i18next.t('TRANS discWelcome 1 warning'),
      continueLabel: i18next.t('Next'),
      checklistItems: [],
      canCancel: false,
    },
    {
      position: 1,
      welcomeMessageTitle: i18next.t('TRANS discWelcome 2 title'),
      welcomeMessageLong: i18next.t('TRANS jobProfiler 2 long', {
        jobRole,
      }),
      warningText: i18next.t('TRANS discWelcome 2 warning'),
      continueLabel: i18next.t('Next'),
      checklistItems: [],
      canCancel: false,
    },
    {
      position: 2,
      welcomeMessageTitle: i18next.t('TRANS discWelcome 2 title'),
      welcomeMessageLong: i18next.t('TRANS jobProfiler 3 long'),
      warningText: i18next.t('TRANS discWelcome 2 warning'),
      continueLabel: i18next.t('Next'),
      checklistItems: [],
      canCancel: false,
    },
  ],
});

export const getAutoReportGeneration = activityProductVersion =>
  activityProductVersion
    .get('reportgenerationbatch_set')
    .filter(batch => !batch.get('session'))
    .sortBy(each => each.get('created'))
    .first();

export const productNeedsComparisonGroup = product =>
  product &&
  product.get('activity_type') === ACTIVITY_TYPES.CHOICES.PSYCHOMETRIC;

export const productNeedsVersionChoice = product =>
  product && product.get('activity_type') === ACTIVITY_TYPES.CHOICES.PROFILER;

const productVersionHasComparisonGroups = productVersion =>
  productVersion && !productVersion.get('comparison_groups').isEmpty();

export const getNeedsComparisonGroup = activityProductVersion =>
  productNeedsComparisonGroup(
    activityProductVersion.getIn(['product_version', 'product'])
  ) &&
  productVersionHasComparisonGroups(
    activityProductVersion.get('product_version')
  );

export function getReportCost(reportTemplate, productOrganisation) {
  const organisationReport = productOrganisation
    .get('productorganisationreporttemplate_set')
    .find(
      each => each.getIn(['report_template', 'id']) === reportTemplate.get('id')
    );

  return organisationReport && organisationReport.get('credit_cost') !== null
    ? organisationReport.get('credit_cost')
    : reportTemplate.get('credit_cost', reportTemplate.get('cost'));
}

export function isProductOrganisationAvailable(user, productOrganisation) {
  return (
    can(user, administerOrganisations()) ||
    (productOrganisation &&
      user
        .getIn(['organisation', 'available_product_organisations'])
        .includes(productOrganisation.get('id')))
  );
}

export function isProductAvailable(user, productId, productVariantId) {
  return isProductOrganisationAvailable(
    user,
    user
      .getIn(['organisation', 'productorganisation_set'], List())
      .find(
        each =>
          each.get('product') === productId &&
          each.get('product_variant') === productVariantId
      )
  );
}

export function holdsAccreditation(organisation, productId) {
  return organisation
    .get('admins')
    .some(admin => admin.get('accredited_products').contains(productId));
}

export const getName = activityProductVersion =>
  activityProductVersion.getIn(['product_variant', 'name']) ||
  activityProductVersion.getIn(['product_version', 'product', 'name']);

export function findProductOrganisation(organisation, product, variant) {
  return organisation
    .get('productorganisation_set')
    .find(
      productOrganisation =>
        productOrganisation.get('product') === product &&
        productOrganisation.get('product_variant') === variant
    );
}

export function findProductOrganisationJS(
  organisation,
  product_id,
  variant_id
) {
  return organisation.productorganisation_set.find(
    productOrganisation =>
      productOrganisation.product === product_id &&
      productOrganisation.product_variant === variant_id
  );
}

import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import i18next from 'i18next';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  getAllProductOrganisations,
  clearCollection,
} from '^/actions/collections';
import { ACTIVITY_STATUS } from '^/activityStatus';
import { ACTIVITY_TYPE } from '^/models/activity';
import {
  removeProductVersionFromActivityModal,
  closeTopModal,
  openModal,
} from '^/actions/modals';
import { deleteActivityProductVersion } from '^/actions/actions';
import { addProductOrganisationsAndCloseTopModal } from '^/actions/actionSequences';
import Tag from '^/components/Tag';
import PureComponent from '^/components/PureComponent';
import AddProductsModal from './AddProductsModal';
import Alert from '^/components/Alert';

const getName = (
  activityProductVersion,
  versionType = null,
  withPulse = false
) => {
  const productName =
    activityProductVersion.getIn(['product_variant', 'name']) ||
    activityProductVersion.getIn(['product_version', 'product', 'name']);
  if (withPulse) {
    return `${productName} + ${i18next.t('Pulsing')}`;
  }
  if (versionType) {
    return productName + ' - ' + i18next.t(versionType);
  }

  return productName;
};

const onlySingleAllowed = activityProductVersion =>
  activityProductVersion.get('product_variant') !== null ||
  activityProductVersion.getIn([
    'product_version',
    'product',
    'activity_type',
  ]) === ACTIVITY_TYPE.THREE_SIXTY;

export class ActivityProductVersions extends PureComponent {
  UNSAFE_componentWillMount() {
    if (!this.props.noLoad) {
      this.props.clearCollection('productOrganisations');
    }
    if (
      this.props.selectedProduct &&
      !this.props.selectedProduct.isEmpty() &&
      this.props.activityProductVersions.isEmpty()
    ) {
      this.addProductOrganisations(this.props.selectedProduct, false);
    }
  }

  addProductOrganisations(productOrganisations, withPulse) {
    const { activityId, onAdd } = this.props;

    const isAllowedToPulse = productOrganisations.size === 1;
    const canPulse = isAllowedToPulse && withPulse;

    if (canPulse) {
      productOrganisations = productOrganisations.map(productOrganisation =>
        productOrganisation.updateIn(
          ['product', 'name'],
          name => `${name} + ${i18next.t('Pulsing')}`
        )
      );
    }

    if (onAdd) {
      onAdd(productOrganisations);
      this.props.closeTopModal();
    } else {
      this.props.addProductOrganisationsAndCloseTopModal(
        activityId,
        productOrganisations.map(each => each.get('id')).toJS()
      );
    }
  }

  removeActivityProductVersion(activityProductVersion) {
    const { onRemove } = this.props;
    const activityProductVersionId = activityProductVersion.get('id');
    this.props.removeProductVersionFromActivityModal(
      getName(activityProductVersion),
      () =>
        (onRemove || this.props.deleteActivityProductVersion)(
          activityProductVersionId
        )
    );
  }

  showAddProductsModal() {
    const {
      organisation,
      activityType,
      activityProductVersions,
      noLoad,
      numRespondents,
    } = this.props;
    this.props.openModal({
      title: i18next.t('Product library'),
      body: (
        <AddProductsModal
          current={activityProductVersions}
          activityType={activityType}
          organisation={organisation}
          onChooseProductOrganisations={this.addProductOrganisations.bind(this)}
          onClose={this.props.closeTopModal}
          noLoad={noLoad}
          numRespondents={numRespondents}
        />
      ),
    });
  }

  render() {
    const {
      activityStatus,
      activityProductVersions,
      readOnly,
      isLiveOrEnded,
      isPulsing,
      isProfile,
      versionType,
    } = this.props;

    const versionName = isProfile
      ? versionType
        ? versionType
        : this.props.selectedVersionType?.type
      : null;

    const canUpdateProducts = !readOnly && !isLiveOrEnded && !isProfile;
    return (
      <div>
        {activityProductVersions && !activityProductVersions.isEmpty() && (
          <div className="flex-list added-list assets-list">
            {activityProductVersions.map(activityProductVersion => {
              return (
                <Tag
                  key={activityProductVersion.get('id')}
                  name={getName(activityProductVersion, versionName, isPulsing)}
                  onRemove={
                    canUpdateProducts &&
                    this.removeActivityProductVersion.bind(
                      this,
                      activityProductVersion
                    )
                  }
                />
              );
            })}
          </div>
        )}

        {canUpdateProducts && (
          <button
            type="button"
            className="btn btn-default btn-small product-abbreviation-add-button"
            onClick={() => this.showAddProductsModal()}
            disabled={activityProductVersions.some(onlySingleAllowed)}
          >
            <FontAwesomeIcon icon={faPlus} />
            {isProfile ? i18next.t('Add profile') : i18next.t('Add product(s)')}
          </button>
        )}

        {activityStatus === ACTIVITY_STATUS.ENDED && (
          <Alert className="mb-none">
            {i18next.t(
              'Products cannot be added to a closed activity. Extend the close date to add products.'
            )}
          </Alert>
        )}
      </div>
    );
  }
}

ActivityProductVersions.propTypes = {
  activityId: PropTypes.string.isRequired,
  activityType: PropTypes.string.isRequired,
  activityStatus: PropTypes.string.isRequired,
  organisation: ImmutablePropTypes.map.isRequired,
  openModal: PropTypes.func.isRequired,
  closeTopModal: PropTypes.func.isRequired,
  activityProductVersions: ImmutablePropTypes.list.isRequired,
  status: PropTypes.string.isRequired,
};

export function mapStateToProps(state) {
  return {
    user: state.userProfile,
    selectedVersionType: state.ui.getIn([
      'simpleComponentState',
      'CreateActivityPage',
      'selectedVersionType',
    ]),
  };
}

export default connect(mapStateToProps, {
  removeProductVersionFromActivityModal,
  getAllProductOrganisations,
  openModal,
  closeTopModal,
  addProductOrganisationsAndCloseTopModal,
  deleteActivityProductVersion,
  clearCollection,
})(ActivityProductVersions);

import React, { useEffect, useState } from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import i18next from 'i18next';

import { closeModalAndTryViewExport } from '^/actions/actionSequences';
import { isPending } from '^/responseStates';
import ContentBox from '^/components/ContentBox';
import ExportSelect from './ExportSelect';
import { getSimpleCollection } from '^/actions/collections';
import { SUCCESS_PROFILE_VERSION_TYPES } from '^/components/productVersions/choices';
import { PROFILE_SCORES } from '^/middleware/reports';

const TYPE_OPTIONS = List([
  Map({ id: 'CLIENT_RESULTS', name: 'Client Results' }),
  Map({ id: 'VERIFIED_RESULTS', name: 'Verified Results' }),
]);

export const ExportProfile = props => {
  const [selectedOrganisation, setSelectedOrganisation] = useState(Map());
  const [selectedActivity, setSelectedActivity] = useState(Map());
  const [selectedType, setSelectedType] = useState(Map());

  const {
    response,
    organisationsActivities,
    shortlivedToken,
    getSimpleCollection,
  } = props;

  useEffect(() => {
    getSimpleCollection(
      'organisationsProfileActivities',
      '/organisations/activities/',
      {
        profile_only: true,
      }
    );
  }, [getSimpleCollection]);

  const onSubmit = () => {
    const metadata = {
      activity: selectedActivity.get('id'),
      is_success_profile: true,
    };
    if (selectedType) {
      metadata.type = selectedType.get('id');
    }
    props.closeModalAndTryViewExport(shortlivedToken, PROFILE_SCORES, metadata);
  };

  const isLoading = isPending(response);
  const blankOptionMessage = isLoading ? 'Loading...' : 'Please select...';

  const activities =
    selectedOrganisation && selectedOrganisation.get('activities');

  const versionType =
    selectedActivity &&
    selectedActivity.getIn(['profile', 'success_profile', 'version_type']);

  const requiresType =
    versionType === SUCCESS_PROFILE_VERSION_TYPES.CHOICES.VERIFIED;

  const isReady =
    !selectedOrganisation.isEmpty() &&
    !selectedActivity.isEmpty() &&
    (requiresType ? !selectedType.isEmpty() : true) &&
    shortlivedToken.get('token') &&
    !isLoading;

  return (
    <ContentBox>
      <h3>{i18next.t('Profile scores')}</h3>
      <form>
        <div className="row mb-none">
          <ExportSelect
            selectName={i18next.t('Account')}
            disabled={isLoading}
            onChange={setSelectedOrganisation}
            blankOptionMessage={blankOptionMessage}
            items={organisationsActivities}
          />
          <ExportSelect
            selectName={i18next.t('Activity')}
            disabled={isLoading}
            onChange={activity => {
              setSelectedActivity(activity);
              setSelectedType(Map());
            }}
            blankOptionMessage={blankOptionMessage}
            items={activities}
          />
        </div>
        {requiresType && (
          <div className="row mb-none">
            <ExportSelect
              selectName={i18next.t('Type')}
              disabled={isLoading}
              onChange={setSelectedType}
              blankOptionMessage={blankOptionMessage}
              items={TYPE_OPTIONS}
            />
          </div>
        )}

        <div className="row mb-none">
          <div className="col-xs-12 col-md-6 pull-right">
            <button
              className="btn btn-primary pull-right"
              onClick={onSubmit}
              disabled={!isReady}
            >
              {i18next.t('Export CSV')}
            </button>
          </div>
        </div>
      </form>
    </ContentBox>
  );
};

export function mapStateToProps(state) {
  return {
    organisationsActivities: state.collections.getIn([
      'organisationsProfileActivities',
      'items',
    ]),
    response: state.multiResponses.getIn([
      'getCollection',
      'organisationsProfileActivities',
    ]),
    shortlivedToken: state.shortlivedToken,
  };
}

export default connect(mapStateToProps, {
  closeModalAndTryViewExport,
  getSimpleCollection,
})(ExportProfile);

/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import i18next from 'i18next';

import { SuccessProfile } from '^/reducers/api/types';
import ProfileSection from './ProfileSection';
import MotivatorsSection from './MotivatorsSection';

interface Props {
  successProfile: SuccessProfile;
}

const ProfileCharts: React.FC<Props> = ({ successProfile }) => {
  return (
    <div className="profile-charts">
      <ProfileSection
        title={i18next.t<string>('Potential')}
        items={successProfile.potential}
        groups={[
          {
            title: i18next.t<string>('Critical'),
            indexLow: 0,
            indexHigh: 5,
            hasRanges: true,
          },
          {
            title: i18next.t<string>('Important'),
            indexLow: 5,
            indexHigh: 10,
            hasRanges: true,
          },
          {
            title: i18next.t<string>('Less essential'),
            indexLow: 10,
            indexHigh: 15,
            hasRanges: false,
          },
        ]}
      />
      <ProfileSection
        title={i18next.t<string>('Competencies')}
        items={successProfile.competencies}
        groups={[
          {
            title: i18next.t<string>('Critical'),
            indexLow: 0,
            indexHigh: 10,
            hasRanges: true,
          },
          {
            title: i18next.t<string>('Important'),
            indexLow: 10,
            indexHigh: 20,
            hasRanges: true,
          },
          {
            title: i18next.t<string>('Less essential'),
            indexLow: 20,
            indexHigh: 30,
            hasRanges: false,
          },
          {
            title: i18next.t<string>('Not applicable'),
            indexLow: 30,
            indexHigh: 37,
            hasRanges: false,
          },
        ]}
      />
      <MotivatorsSection items={successProfile.motivators} />
    </div>
  );
};

export default ProfileCharts;

import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import { formatDateTime } from '../../../utils';
import PureComponent from '../../PureComponent';
import Table from '../../Table';
import { ACTIVITY_TYPES } from '^/components/productVersions/choices';

const ACTIONS = {
  [ACTIVITY_TYPES.CHOICES.PSYCHOMETRIC]: {
    NOTIFY_ORG_ADMIN_START: i18next.t('Admin notified activity is live'),
    NOTIFY_END_USERS_START: i18next.t('Respondents invited to activity'),
    NOTIFY_MIDWAY: i18next.t('1st reminder sent to Respondents'),
    SEND_PRE_CHASERS: i18next.t('2nd reminder sent to Respondents'),
    SEND_CHASERS: i18next.t('3rd reminder sent to Respondents'),
    NOTIFY_ORG_ADMIN_END: i18next.t('Admins notified activity closes in 48hrs'),
    NOTIFY_INVITED_TO_STAGE_2: i18next.t('Respondents invited to stage 2'),
  },
  [ACTIVITY_TYPES.CHOICES.THREE_SIXTY]: {
    NOTIFY_ORG_ADMIN_START: i18next.t('Admin notified activity is live'),
    NOTIFY_END_USERS_START: i18next.t('Respondents invited to activity'),
    NOTIFY_RATERS_START: i18next.t(
      'Respondent, Line Manager and Rater invite to complete'
    ),
    NOTIFY_MIDWAY: i18next.t(
      '1st reminder sent to Respondents, Line manager and Raters'
    ),
    NOTIFY_MIDWAY_RATER: i18next.t(
      '1st reminder sent to Respondents, Line manager and Raters'
    ),
    SEND_PRE_CHASERS: i18next.t(
      '2nd reminder sent to Respondents, Line manager and Raters'
    ),
    SEND_PRE_CHASERS_RATER: i18next.t(
      '2nd reminder sent to Respondents, Line manager and Raters'
    ),
    SEND_CHASERS: i18next.t(
      '3rd reminder sent to Respondents, Line manager and Raters'
    ),
    SEND_CHASERS_RATER: i18next.t(
      '3rd reminder sent to Respondents, Line manager and Raters'
    ),
    NOTIFY_ORG_ADMIN_END: i18next.t('Admins notified activity closes in 48hrs'),
    NOTIFY_NOMINATION_PERIOD_STARTED: i18next.t(
      'Respondents invited to nominate Raters'
    ),
  },
  [ACTIVITY_TYPES.CHOICES.PROFILER]: {
    NOTIFY_ORG_ADMIN_START: i18next.t('Admin notified activity is live'),
    NOTIFY_END_USERS_START: i18next.t('Profile raters invited to activity'),
    NOTIFY_MIDWAY: i18next.t('1st reminder sent to Profile raters'),
    SEND_PRE_CHASERS: i18next.t('2nd reminder sent to Profile raters'),
    SEND_CHASERS: i18next.t('3rd reminder sent to Profile raters'),
    NOTIFY_ORG_ADMIN_END: i18next.t('Admins notified activity closes in 48hrs'),
  },
};

export default class ActivitySentNotificationsTab extends PureComponent {
  renderAction(notification) {
    const { activity } = this.props;
    const { type: activityType } = activity.toObject();
    const {
      action,
      additional_text,
      recipients,
      respondents,
    } = notification.toObject();

    const adminName = activity.getIn(['created_by_user', 'full_name']);
    const respondentsNames = respondents
      .map(each => each.get('full_name'))
      .join(', ');
    const recipientsNames = recipients
      .map(each => each.getIn(['user', 'full_name']))
      .join(', ');
    const recipientsEmails = recipients
      .map(each => each.getIn(['user', 'email']))
      .join(', ');

    switch (action) {
      case 'NOTIFY_REPORTS_LINK_ADMIN':
        return i18next.t('{{respondentsNames}} reports sent to {{adminName}}', {
          respondentsNames,
          adminName,
        });
      case 'NOTIFY_REPORT_LINK':
        return i18next.t('{{respondentsNames}} report sent to {{emails}}', {
          respondentsNames,
          emails: additional_text,
        });
      case 'NOTIFY_RESPONDENT_RATERS_REJECTED':
        return i18next.t('{{recipientsNames}} notified Rater(s) rejected', {
          recipientsNames,
        });
      case 'SEND_NOMINATIONS_CHASERS':
        return i18next.t('{{recipientsNames}} reminded to nominate Raters', {
          recipientsNames,
        });
      case 'NOTIFY_REPORTS_READY':
        return i18next.t('{{recipientsNames}} sent own report', {
          recipientsNames,
        });
      case 'NOTIFY_LM_RATERS_SUBMITTED':
        return i18next.t('{{recipientsEmails}} invited to approve Raters', {
          recipientsEmails,
        });
      default:
        return ACTIONS[activityType][action] || '';
    }
  }

  render() {
    const { activity } = this.props;
    return (
      <div className="notification-emails">
        <Table
          columns={[
            {
              header: i18next.t('Timestamp'),
              value: notification =>
                formatDateTime(notification.get('created')),
            },
            {
              header: i18next.t('Action'),
              value: notification => this.renderAction(notification),
            },
          ]}
          rows={activity.get('notifications')}
        />
      </div>
    );
  }
}

import React, { useState, DragEvent, useEffect } from 'react';
import classNames from 'classnames';
import i18next from 'i18next';

import { SuccessProfileSection } from '^/reducers/api/types';
import FactorTile from './FactorTile';
import { setDrag, setDrop, setOver } from '^/drag-and-drop-utils';
import { deepCopy } from '^/utils';

interface Props {
  title: string;
  motivatorsResults: SuccessProfileSection;
  setResults: (results: SuccessProfileSection) => void;
  canVerify: boolean;
}

export const MotivatorsProfileSummarySection: React.FC<Props> = ({
  title,
  motivatorsResults,
  canVerify,
  setResults,
}) => {
  const [dragItem, setDragItem] = useState<string>('');
  const [dragOver, setDragOver] = useState<string>('');
  const [results, setSectionResults] = useState<SuccessProfileSection>(
    motivatorsResults
  );

  useEffect(() => {
    setResults(deepCopy(results));
  }, [results, setResults]);

  const onDragStart = (event: DragEvent<HTMLDivElement>) => {
    setDrag(event, setDragItem);
  };

  const onDrop = () => {
    setDrop(
      setDragOver,
      setDragItem,
      results,
      setSectionResults,
      dragItem,
      dragOver,
      null
    );
  };

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    setOver(event, title, setDragOver, setDragItem, dragItem);
  };
  return (
    <div>
      <div className="motivators-group">
        {canVerify && (
          <div className="note-text">
            {i18next.t<string>(
              'These are the aggregate rank order results. To verify the profile, review and confirm the ranking of the scores.'
            )}
          </div>
        )}
        <div className="group-one">
          {results.map((item, i) => {
            return (
              <FactorTile
                className={classNames(
                  dragItem !== '' &&
                    i === parseInt(dragItem.split('_')[1], 10) &&
                    'dragging'
                )}
                onDragStart={(e: DragEvent<HTMLDivElement>) => onDragStart(e)}
                onDragOver={(e: DragEvent<HTMLDivElement>) => onDragOver(e)}
                onDrop={() => onDrop()}
                key={title + i}
                id={title + '_' + i.toString()}
                sectionName={title}
                rank={item.rank}
                rangeMin={null}
                rangeMax={null}
                factor={item.factor_label}
                canVerify={canVerify}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MotivatorsProfileSummarySection;

import { Uuid } from '^/reducers/api/types';

export enum ProductType {
  EAP = 'EAP',
  ISP = 'ISP',
  THREE_SIXTY = 'THREE_SIXTY',
  POSITIVE_RESILIENCE_PROFILER = 'POSITIVE_RESILIENCE_PROFILER',
  MAP = 'MAP',
  PSYCAP_POTENTIAL = 'PSYCAP_POTENTIAL',
  DISC = 'DISC',
}

export enum ActivityType {
  PSYCHOMETRIC = 'PSYCHOMETRIC',
  THREE_SIXTY = 'THREE_SIXTY',
  PROFILER = 'PROFILER',
}

export interface ProductVariant {
  id: Uuid;
  name: string;
  comparison_groups: ReadonlyArray<Uuid>;
  report_templates: ReadonlyArray<Uuid>;
}

export interface Product {
  activity_type: ActivityType;
  icon: string;
  id: Uuid;
  name: string;
  productvariant_set: ReadonlyArray<ProductVariant>;
  report_templates: ReadonlyArray<any>;
  requires_accreditation: boolean;
  has_shopitem: boolean;
  type: ProductType;
}

export interface ProductAndVariant {
  product: Product;
  variant: ProductVariant | null;
}

import { makeChoices } from '^/utils-ts';

export const PRODUCT_TYPES = makeChoices([
  ['THREE_SIXTY', '360'],
  ['EAP', 'EAP'],
  ['ISP', 'ISP'],
  ['POSITIVE_RESILIENCE_PROFILER', 'Positive Resilience Profiler'],
  ['MAP', 'MAP'],
  ['PSYCAP_POTENTIAL', 'PsyCap Potential'],
  ['DISC', 'DISC'],
  ['SJT', 'SJT'],
  ['PERSPECTIVES', 'Perspectives'],
]);

export const PRODUCT_ACTIVITY_TYPES = makeChoices([
  ['PROFILER_DISC', 'DISC Discovery Job Profiler'],
  ['PERSPECTIVES_SUCCESS_PROFILE', 'Perspectives Success Profiler'],
]);

export const SUCCESS_PROFILE_VERSION_TYPES = makeChoices([
  ['UNVERIFIED', 'Unverified'],
  ['VERIFIED', 'Verified'],
]);

export const REPORT_TEMPLATES = makeChoices([
  ['TEST', 'Test Report'],
  ['ISP', 'Influencing Styles Profiler'],
  ['MAP', 'Mental Agility Profiler'],
  ['EAP', 'Emotional Agility Profiler'],
  ['THREE_SIXTY', '360'],
  ['POSITIVE_RESILIENCE_PROFILER', 'Positive Resilience Profiler'],
  ['EXECUTIVE_SUMMARY', 'Executive Summary'],
  ['INTERVIEW_INSIGHTS', 'Interview Insights'],
  ['PROFILER', 'Profiler Report'],
  ['INDIVIDUAL_DEVELOPMENT', 'Individual Development'],
  ['DISC_INDIVIDUAL', 'DISC Individual'],
]);

export const ACTIVITY_TYPES = makeChoices([
  ['THREE_SIXTY', 'Three Sixty'],
  ['PSYCHOMETRIC', 'Psychometric'],
  ['PROFILER', 'Profiler'],
]);

export const QUESTION_COLLECTION_TYPES = makeChoices([
  ['EAP', 'EAP'],
  ['ISP', 'ISP'],
  ['PSYCAP', 'Psycap'],
  ['THREE_SIXTY', 'Three Sixty'],
  ['POSITIVE_RESILIENCE_PROFILER', 'Positive Resilience Profiler'],
  ['MAP', 'MAP'],
  ['DISC', 'DISC'],
  ['SJT', 'SJT'],
  ['PERSPECTIVES_COMPETENCIES_LIKERT', 'Perspectives Competencies Likert'],
  ['PERSPECTIVES_MOTIVATORS', 'Perspectives Motivators'],
  ['PERSPECTIVES_DERAILERS', 'Perspectives Derailers'],
  ['SUCCESS_PROFILE_POTENTIAL_RANK', 'Perspectives Success Profile Potential'],
  [
    'SUCCESS_PROFILE_COMPETENCIES_RANK',
    'Perspectives Success Profile Competencies',
  ],
  [
    'SUCCESS_PROFILE_MOTIVATORS_RANK',
    'Perspectives Success Profile Motivators',
  ],
]);

/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import classNames from 'classnames';
import i18next from 'i18next';

import { SuccessProfileItem } from '^/reducers/api/types';

interface Props {
  items: Array<SuccessProfileItem>;
}

const Item: React.FC<{ item: SuccessProfileItem }> = ({ item }) => {
  return (
    <div className="motivators-item">
      <div>{item.factor_label}</div>
      <div
        className={classNames('motivators-score', {
          purple: item.rank <= 3,
          white: item.rank > 3,
        })}
      >
        {item.rank}
      </div>
    </div>
  );
};

const MotivatorsSection: React.FC<Props> = ({ items }) => {
  return (
    <div className="profile-chart-section-container">
      <h2>{i18next.t<string>('Motivators')}</h2>
      <div className="profile-chart">
        <div className="motivators-fit-role-label">
          {i18next.t<string>('Role fit profile')}
        </div>
        {items.map((item, index) => (
          <Item key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

export default MotivatorsSection;

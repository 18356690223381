import i18next from 'i18next';
import { Map } from 'immutable';
import React, { ChangeEvent, PureComponent } from 'react';

import { Props as CheckableButtonProps } from '^/components/buttons/CheckableButton';
import CheckableButtonQuestion from '^/components/buttons/CheckableButtonQuestion';

export enum MultiLikertValuetype {
  Most = 'MOST',
  Least = 'LEAST',
}

const TYPE_FOR_DISPLAY = {
  [MultiLikertValuetype.Most]: 'MOST like me…',
  [MultiLikertValuetype.Least]: 'LEAST like me…',
};

const TYPE_FOR_DISPLAY_JOB_PROFILER = {
  [MultiLikertValuetype.Most]: 'MOST...',
  [MultiLikertValuetype.Least]: 'LEAST...',
};

interface OwnProps {
  answer?: Map<string, string | number>;
  question: Map<string, any>;
  onAnswer: (type: MultiLikertValuetype, value: string) => void;
  isPending?: boolean;
  type: MultiLikertValuetype;
  answeredId?: string | null;
  answeredIds: string[];
  isProfiler?: boolean;
}

type Props = OwnProps;

export const getOtherValue = (
  question: Map<string, any>,
  answers: Array<string | number>,
  answeredIds: Array<string>
) => {
  if (!(answers.length === 1)) {
    throw new Error('This function should only be called with one answer');
  }
  const values = question
    .get('options')
    .flatMap((option: any) => option.get('values'));

  const answerType = values
    .find((value: any) => value.get('id') === answers[0])
    .get('type');

  const otherType =
    answerType === MultiLikertValuetype.Most
      ? MultiLikertValuetype.Least
      : MultiLikertValuetype.Most;

  return values
    .filter(
      (value: any) =>
        answeredIds.includes(value.get('id')) && value.get('type') === otherType
    )
    .get(0);
};

const getOtherAnswerOptionId = (
  otherType: MultiLikertValuetype,
  options: Map<string, any>[],
  answer?: Map<string, string | number>
) => {
  const otherAnswerValueID =
    answer && answer.get(otherType.toLowerCase(), undefined);
  const otherAnswerOption = options.find((answerOption: Map<string, any>) =>
    answerOption
      .get('values')
      .find(
        (answerValue: Map<string, any>) =>
          answerValue.get('id') === otherAnswerValueID
      )
  );
  const otherAnswerOptionId = otherAnswerOption && otherAnswerOption.get('id');
  return otherAnswerOptionId;
};

export default class MultiLikertQuestion extends PureComponent<Props> {
  public isDisabled(option: Map<string, any>, value: Map<string, any>) {
    const { question, answer, isPending, answeredIds } = this.props;

    if (isPending) {
      return true;
    }

    const thisOptionId = option.get('id');
    const options = question.get('options');
    const otherType =
      value.get('type') === MultiLikertValuetype.Most
        ? MultiLikertValuetype.Least
        : MultiLikertValuetype.Most;

    // if answer is empty, we need to check for the other option in answeredIds (saved responses)
    const oppositeOptionValue = option
      .get('values')
      .filter(
        (optionValue: Map<string, any>) =>
          optionValue.get('id') !== value.get('id')
      );
    if (!answer && answeredIds.length === 2) {
      return answeredIds.includes(oppositeOptionValue.get(0).get('id'));
    }

    // if answer has 1 value, we need to add the other value from answeredIds to answer map
    if (answer && answer.size === 1 && answeredIds.length === 2) {
      const otherValue = getOtherValue(question, answer.toArray(), answeredIds);
      const answers = answer.set(
        otherValue.get('type').toLowerCase(),
        otherValue.get('id')
      );
      const otherAnswerOptionId = getOtherAnswerOptionId(
        otherType,
        options,
        answers
      );
      return thisOptionId === otherAnswerOptionId;
    }

    const otherAnswerOptionId = getOtherAnswerOptionId(
      otherType,
      options,
      answer
    );

    return thisOptionId === otherAnswerOptionId;
  }

  public render() {
    const { type, question, answer, answeredId, isProfiler } = this.props;
    let thisAnswer = answer && answer.get(type.toLowerCase(), undefined);
    if (!thisAnswer && answeredId) {
      thisAnswer = answeredId;
    }
    const options = question.get('options');

    const buttons: CheckableButtonProps[] = options.map(
      (option: Map<string, any>) => {
        const value = option
          .get('values')
          .find(
            (answerValue: Map<string, any>) => answerValue.get('type') === type,
            Map()
          );
        const valueId = value.get('id');
        return {
          label: option.get('text'),
          value: valueId,
          disabled: this.isDisabled(option, value),
          checked: Boolean(thisAnswer && thisAnswer === valueId),
        };
      }
    );

    const questionText = isProfiler
      ? TYPE_FOR_DISPLAY_JOB_PROFILER[type]
      : TYPE_FOR_DISPLAY[type];

    return (
      <CheckableButtonQuestion
        question={i18next.t<string>(questionText)}
        buttons={buttons}
        onChange={this.onChange}
        size="small"
      />
    );
  }

  private onChange = (value: ChangeEvent<HTMLInputElement>) => {
    this.props.onAnswer(this.props.type, value.target.value);
  };
}

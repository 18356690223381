import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { push } from 'react-router-redux';
import { List, Map } from 'immutable';
import moment from 'moment';
import i18next from 'i18next';

import { isAwaitingApproval } from '^/approvalStatus';
import { isLineManager } from '^/rater';
import { anyPending } from '^/responseStates';
import { formatDate } from '^/utils';
import { startActivityProductVersionSession } from '^/actions/actions';
import {
  getAllMyActivities,
  showMoreMyActivities,
  getAllMyUserPulses,
} from '^/actions/collections';
import { openModal } from '^/actions/modals';
import {
  continueProductVersion,
  loadDataAndOpenWelcomeModal,
  clearPreviousAndLoadNewActivity,
  setActiveLanguageAndOpenAssessmentModal,
} from '^/actions/actionSequences';
import { can, seeAdminDashboard } from '^/capabilities';
import PureComponent from '^/components/PureComponent';
import ControlBar from '^/components/ControlBar';
import Email from '^/components/Email';
import Loading from '^/components/Loading';
import Table from '^/components/Table';
import PulseViewChartsIcon from '^/components/PulseViewChartsIcon';
import PulseClosedIcon from '^/components/PulseClosedIcon';
import PulseIconCircle from '^/components/PulseIconCircle';
import { Tabset, Tab } from '^/components/tabs';
import TaskStatuses from './TaskStatuses';
import AdminEnduserDashboardSwitcher from '../admin/AdminEnduserDashboardSwitcher';
import { withRouter } from '^/withRouter';
import Alert from '^/components/Alert';

const formatDateOrNull = dateStr => dateStr && formatDate(dateStr);

export const getPulseName = userPulse => {
  const code = userPulse.getIn([
    'pulse',
    'activity_product_version',
    'product_version',
    'product',
    'code',
  ]);
  const productName = userPulse.getIn([
    'pulse',
    'activity_product_version',
    'product_version',
    'product',
    'name',
  ]);
  const pulseName = userPulse.getIn(['pulse', 'name']);
  const isActivityPulse = Boolean(
    userPulse.getIn(['pulse', 'activity_product_version'])
  );
  return isActivityPulse ? (code === '360' ? code : productName) : pulseName;
};

const USER_PULSE_STATUS = {
  ENDING: 'ENDING',
  ENDED: 'ENDED',
  INCOMPLETE: 'INCOMPLETE',
  NOT_STARTED: 'NOT_STARTED',
};

function getStatus(userPulse) {
  switch (userPulse.get('status')) {
    case USER_PULSE_STATUS.ENDED:
      return i18next.t('Closed');
    case USER_PULSE_STATUS.ENDING:
      return i18next.t('Closing');
    default:
      return (userPulse.get('num_raters') ||
        userPulse.getIn(['pulse', 'is_self_only'])) &&
        userPulse.get('userpulseselectedbehaviour_set').size
        ? i18next.t('Pulsing')
        : i18next.t('Invited');
  }
}

export class Dashboard extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.getAllMyActivities();
    this.props.getAllMyUserPulses();
  }

  showMore() {
    this.props.showMoreMyActivities(
      this.props.activities.get('searchString', ''),
      this.props.activities.get('page', 0) + 1
    );
  }

  getDateColorClass(date) {
    const now = moment();
    if (date.diff(now, 'hours') <= 24) {
      return 'text-danger';
    } else if (date.diff(now, 'hours') <= 168) {
      return 'text-warning';
    }
    return undefined;
  }

  renderUser(user) {
    const name = user.get('full_name'),
      email = <Email email={user.get('email')} />;

    return name || email;
  }

  renderDirectReports(task) {
    return task
      .getIn(['activity', 'direct_reports'], List())
      .filter(directReport =>
        directReport
          .get('raters', List())
          .filterNot(isLineManager)
          .some(isAwaitingApproval)
      )
      .map(directReport => this.renderUser(directReport.get('user')));
  }

  renderActivity(task) {
    return <div>{task.getIn(['activity', 'name'])}</div>;
  }

  renderProduct(task) {
    return <span>{task.getIn(['product_version', 'product', 'name'])}</span>;
  }

  renderProductFor(task) {
    const { asLineManager, asRater, raterFor, asRespondent } = task.toObject();

    return (
      <div>
        {task.getIn(['activity', 'type']) !== 'PSYCHOMETRIC' &&
          task.getIn(['activity', 'type']) !== 'PROFILER' &&
          ((asLineManager && this.renderDirectReports(task).join(', ')) ||
            (asRater && this.renderUser(raterFor || this.props.user)) ||
            (asRespondent && this.renderUser(this.props.user)))}
      </div>
    );
  }

  getTasks(activities) {
    function mustLineManagerApprove(activity) {
      const lineManager = activity.get('is_line_manager');
      const lineManagerMustApprove = activity.getIn([
        'activity360',
        'line_manager_must_approve_raters',
      ]);
      const anyRatersRequireApproval = activity
        .get('direct_reports', List())
        .some(directReport =>
          directReport
            .get('raters', List())
            .filterNot(isLineManager)
            .some(isAwaitingApproval)
        );

      return lineManager && lineManagerMustApprove && anyRatersRequireApproval;
    }

    return activities
      .map(activity => {
        const asRespondent = activity.get('is_respondent');
        const asLineManager = mustLineManagerApprove(activity);
        const raterFor = activity.get('rater_for', List());

        return activity
          .get('product_versions', List())
          .map(product_version => {
            const baseTask = Map({ activity, product_version });

            const ratersFor = raterFor.map(nominator =>
              baseTask.merge({
                asRater: true,
                raterFor: nominator.get('user'),
              })
            );

            return ratersFor
              .push(asRespondent ? baseTask.merge({ asRespondent }) : null)
              .push(asLineManager ? baseTask.merge({ asLineManager }) : null)
              .filter(item => item);
          })
          .flatten(true);
      })
      .flatten(true);
  }

  renderCompleteBy(task) {
    return this.renderTaskStatuses(task, true);
  }

  renderActions(task) {
    return this.renderTaskStatuses(task);
  }

  renderTaskStatuses(task, isCompleteBy) {
    const activityId = task.getIn(['activity', 'id']);
    const manageRaters = () =>
      this.props.push(`/page/dashboard/raters/${activityId}`);
    const approveRaters = () =>
      this.props.push(`/page/dashboard/raters/approve/${activityId}`);
    return (
      <TaskStatuses
        task={task}
        manageRaters={manageRaters}
        approveRaters={approveRaters}
        loadDataAndOpenWelcomeModal={this.props.loadDataAndOpenWelcomeModal}
        setActiveLanguageAndOpenAssessmentModal={
          this.props.setActiveLanguageAndOpenAssessmentModal
        }
        continueProductVersion={this.props.continueProductVersion}
        openModal={this.props.openModal}
        startActivityProductVersionSession={
          this.props.startActivityProductVersionSession
        }
        user={this.props.user}
        isCompleteBy={isCompleteBy}
        clearPreviousAndLoadNewActivity={() =>
          this.props.clearPreviousAndLoadNewActivity(activityId)
        }
        activeLanguage={this.props.activeLanguage}
      />
    );
  }

  renderTaskTable() {
    const tasks = this.getTasks(
      this.props.activities.get('items', List())
    ).sortBy(task => task.getIn(['activity', 'end_datetime']));

    if (tasks.isEmpty()) {
      return <Alert>{i18next.t('You have no tasks to complete.')}</Alert>;
    }
    return (
      <Table
        collapse="md"
        columns={[
          {
            header: i18next.t('Activity'),
            value: task => this.renderActivity(task),
          },
          {
            header: i18next.t('Assessment'),
            value: task => this.renderProduct(task),
          },
          {
            header: i18next.t('Feedback for'),
            value: task => this.renderProductFor(task),
          },
          {
            header: i18next.t('Complete by'),
            value: task => this.renderCompleteBy(task),
          },
          {
            header: i18next.t('Actions'),
            value: task => this.renderActions(task),
          },
        ]}
        rows={tasks}
      />
    );
  }

  renderUserPulseAction(userPulse) {
    const hasEnded = userPulse.get('status') === USER_PULSE_STATUS.ENDED;
    const isSetUp =
      (userPulse.get('num_raters') > 0 ||
        userPulse.getIn(['pulse', 'is_self_only'])) &&
      userPulse.get('userpulseselectedbehaviour_set').size > 0;

    return hasEnded ? (
      <span title={i18next.t('Contact your administrator for more details')}>
        <PulseClosedIcon /> {i18next.t('Closed')}
      </span>
    ) : (
      <span>
        {isSetUp ? <PulseViewChartsIcon /> : <PulseIconCircle />}
        <Link to={`/page/user-pulses/${userPulse.get('id')}/`}>
          {isSetUp ? i18next.t('View charts') : i18next.t('Get started')}
        </Link>
      </span>
    );
  }

  renderPulseTable(userPulses) {
    const showRaters = userPulses.some(
      pulse => !pulse.getIn(['pulse', 'is_self_only'])
    );
    const columns = [
      {
        header: i18next.t('Product'),
        value: userPulse => getPulseName(userPulse),
      },
      {
        header: i18next.t('Status'),
        value: getStatus,
      },
      {
        header: i18next.t('Behaviours'),
        value: userPulse =>
          userPulse
            .get('userpulseselectedbehaviour_set')
            .map(each => each.getIn(['pulse_sub_behaviour', 'name']))
            .join(', ') || i18next.t('N/A'),
      },
      {
        header: i18next.t('Raters'),
        value: userPulse =>
          userPulse.getIn(['pulse', 'is_self_only'])
            ? 'N/A'
            : userPulse.get('num_raters'),
      },
      {
        header: i18next.t('Closes'),
        value: userPulse =>
          formatDateOrNull(userPulse.get('end_date')) || i18next.t('N/A'),
      },
      {
        header: i18next.t('Action'),
        value: userPulse => this.renderUserPulseAction(userPulse),
      },
    ];
    if (!showRaters) {
      columns.splice(3, 1);
    }
    return (
      <Table
        collapse="md"
        className="user-pulse-table"
        columns={columns}
        rows={userPulses}
      />
    );
  }

  renderTable() {
    const { userPulses, user } = this.props;
    const userPulseItems = userPulses.get('items');
    const maybeSwitcher = can(user, seeAdminDashboard()) && (
      <AdminEnduserDashboardSwitcher currentPath={this.props.path} />
    );

    return userPulseItems && userPulseItems.size ? (
      <div>
        {maybeSwitcher}
        <Tabset defaultActiveTab="TASKS">
          <Tab tabId="TASKS" title={i18next.t('My tasks')}>
            {this.renderTaskTable()}
          </Tab>
          <Tab tabId="PULSES" title={i18next.t('My pulses')}>
            {this.renderPulseTable(userPulseItems)}
          </Tab>
        </Tabset>
      </div>
    ) : (
      <div>
        <ControlBar title={i18next.t('My tasks')} hideSearch hideAdd>
          {maybeSwitcher}
        </ControlBar>

        {this.renderTaskTable()}
      </div>
    );
  }

  render() {
    const { isLoading } = this.props;

    return (
      <div>
        {isLoading ? <Loading className="list-loading" /> : this.renderTable()}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    user: state.userProfile,
    activities: state.collections.get('myActivities', Map()),
    userPulses: state.collections.get('myUserPulses', Map()),
    path: props.router.location.pathname,
    isLoading: anyPending([
      state.responses.get('getCollection'),
      state.responses.get('getMyAnswers'),
      state.responses.get('getMyImageMatchAnswers'),
      state.responses.get('getMyMultiLikertAnswers'),
      state.responses.get('loadItem'),
    ]),
    activeLanguage: state.ui.get('activeLanguage'),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    openModal,
    getAllMyActivities,
    getAllMyUserPulses,
    showMoreMyActivities,
    push,
    loadDataAndOpenWelcomeModal,
    continueProductVersion,
    setActiveLanguageAndOpenAssessmentModal,
    startActivityProductVersionSession,
    clearPreviousAndLoadNewActivity,
  })(Dashboard)
);

import { makeChoices } from '^/utils-ts';
import { REPORT_GENERATION } from '^/components/activities/tabs/ReportGenerationToggle';
import { getNeedsComparisonGroup } from '^/models/product';
import { ACTIVITY_TYPE } from '^/models/activity';

export const INVITATION_TYPES = makeChoices([
  ['VIA_LINK', `'Copy and paste' link`],
  ['VIA_EMAIL', 'Platform invitation'],
  ['VIA_ANON_LINK', 'Restricted research link'],
]);

export const CREATE_ACTIVITY_STAGES = {
  ACCOUNT_TYPE_SETUP: 0,
  ACTIVITY_DETAILS: 1,
  PROFILE_TYPE_SETUP: 2,
};

export const getSelected = fieldsMap =>
  Object.keys(fieldsMap || {}).filter(key => fieldsMap[key]);

const allProductsHaveReports = values =>
  values.productOrganisations.every(
    productOrganisation =>
      productOrganisation.getIn(['product', 'report_templates']).isEmpty() ||
      getSelected(
        values.activity_product_versions[productOrganisation.get('id')]
          .report_templates
      ).length
  );

const allJobLevelsChosen = values =>
  values.productOrganisations.every(productOrganisation =>
    productOrganisation
      .getIn(['product', 'report_templates'])
      .filter(template => template.get('requires_job_level'))
      .filter(template =>
        getSelected(
          values.activity_product_versions[productOrganisation.get('id')]
            .report_templates
        ).includes(template.get('id'))
      )
      .every(
        template =>
          values.activity_product_versions[productOrganisation.get('id')]
            .job_levels[template.get('id')]
      )
  );

const anyProductMissingCompGroup = values =>
  values.productOrganisations.some(
    productOrganisation =>
      getNeedsComparisonGroup(productOrganisation) &&
      !values.activity_product_versions[productOrganisation.get('id')]
        .comparison_group
  );

function reportOptionsValid(values) {
  if (values.reportGeneration === REPORT_GENERATION.CHOICES.MANUAL) {
    return true;
  }

  return (
    allProductsHaveReports(values) &&
    !anyProductMissingCompGroup(values) &&
    allJobLevelsChosen(values)
  );
}

export function isReadyToSubmit(values) {
  const viaEmailAndNoRespondents =
    values.invitation_type === INVITATION_TYPES.CHOICES.VIA_EMAIL &&
    values.userIds.isEmpty();

  const profilerAndNoProfileName =
    values.type === ACTIVITY_TYPE.PROFILER &&
    values.profile.profile_name === '';

  return (
    values.type &&
    values.name &&
    values.start_datetime &&
    values.end_datetime &&
    !values.productOrganisations.isEmpty() &&
    !viaEmailAndNoRespondents &&
    reportOptionsValid(values) &&
    !profilerAndNoProfileName
  );
}

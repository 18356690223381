import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { List, Set } from 'immutable';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';

import Tag from '^/components/Tag';
import PureComponent from '^/components/PureComponent';
import {
  openUpdateUsersModal,
  openAddGroupsModal,
  openAddProfileRatersModal,
} from '^/actions/modals';
import LoadingAndErrors from '../LoadingAndErrors';

export class CreateActivityAddUsers extends PureComponent {
  constructor(props) {
    super(props);
    this.openUpdateUsersModal = this.openUpdateUsersModal.bind(this);
    this.openUpdateGroupsModal = this.openUpdateGroupsModal.bind(this);
    this.setProfileCreatorEmail = this.setProfileCreatorEmail.bind(this);

    this.state = {
      profileCreatorEmail: '',
      users: Set(),
    };
  }

  updateUsers(userIds) {
    this.props.onUpdate(userIds);
  }

  updateProfileUsers(users) {
    const existingUserIds = Set(this.state.users.map(user => user['id']));
    const newUsers = users.filter(user => !existingUserIds.has(user['id']));
    const updatedUsers = this.state.users.union(Set(newUsers));
    const updatedUserIds = Set(updatedUsers.map(user => user['id']));
    this.props.onUpdate(updatedUserIds);
    this.setState({ users: updatedUsers });
  }

  addGroups(groupIds, userIds) {
    this.updateUsers(userIds);
  }

  setProfileCreatorEmail(email) {
    this.setState({ profileCreatorEmail: email });
  }

  clearSelectedUsersEmail(userId) {
    const updatedUsers = this.state.users.filter(user => user.id !== userId);
    const updatedUserIds = updatedUsers.map(user => user.id);
    this.setState({
      users: updatedUsers,
    });
    this.props.onUpdate(updatedUserIds);
  }

  clearSelectedUsers() {
    this.props.onUpdate(Set());
    this.setState({
      profileCreatorEmail: '',
      users: Set(),
    });
  }

  openUpdateUsersModal() {
    const {
      userIds,
      organisation,
      maxNumUsers,
      isProfile,
      isDISC,
    } = this.props;

    if (isProfile) {
      this.props.openAddProfileRatersModal(
        userIds,
        maxNumUsers,
        this.updateProfileUsers.bind(this),
        this.setProfileCreatorEmail,
        isDISC,
        organisation.get('id')
      );
    } else {
      this.props.openUpdateUsersModal(
        organisation.get('id'),
        userIds,
        maxNumUsers,
        this.updateUsers.bind(this),
        {
          showFilters: false,
        }
      );
    }
  }

  openUpdateGroupsModal() {
    const { organisation, userIds, maxNumUsers } = this.props;
    this.props.openAddGroupsModal(
      organisation,
      userIds,
      this.addGroups.bind(this),
      maxNumUsers
    );
  }

  render() {
    const {
      userIds,
      addUsersResponse,
      addGroupsResponse,
      removeUserResponse,
      isProfile,
      isDISC,
    } = this.props;
    const { profileCreatorEmail } = this.state;
    return (
      <div className="spaced-group">
        {!isDISC && !isProfile && (
          <span>
            {i18next.t('{{numberUsers}} respondents added', {
              numberUsers: userIds.count(),
            })}
          </span>
        )}
        {(isDISC && userIds.size < 1) || !isDISC ? (
          <button
            className="btn btn-default btn-small"
            type="button"
            onClick={() => this.openUpdateUsersModal()}
          >
            <FontAwesomeIcon icon={faPlus} />
            {isProfile
              ? i18next.t('Add profile raters')
              : i18next.t('Add respondent(s)')}
          </button>
        ) : (
          ''
        )}
        {isDISC && profileCreatorEmail && (
          <div className="mt-base">
            <Tag
              key={profileCreatorEmail}
              name={profileCreatorEmail}
              onRemove={() => this.clearSelectedUsers()}
            />
          </div>
        )}

        {!isDISC && isProfile && this.state.users.size > 0 && (
          <div className="mt-base">
            {this.state.users.toArray().map(user => (
              <Tag
                key={user.id}
                name={user.email}
                onRemove={() => this.clearSelectedUsersEmail(user.id)}
              />
            ))}
          </div>
        )}
        {!isProfile && (
          <button
            className="btn btn-default btn-small"
            type="button"
            onClick={() => this.openUpdateGroupsModal()}
          >
            <FontAwesomeIcon icon={faPlus} />
            {i18next.t('Add group(s)')}
          </button>
        )}
        <LoadingAndErrors
          responses={List([
            addUsersResponse,
            addGroupsResponse,
            removeUserResponse,
          ])}
        />
      </div>
    );
  }
}

export default connect(null, {
  openUpdateUsersModal,
  openAddGroupsModal,
  openAddProfileRatersModal,
})(CreateActivityAddUsers);

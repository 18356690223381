import { ReactChild } from 'react';

export interface ModalConfigShared<P = {}> {
  className?: string;
  title?: ReactChild;
  subtitle?: React.ReactNode | string | undefined;
  props?: P;
  footer?: ReactChild | MODAL_FOOTERS;
  closable?: boolean;
  onClose?: () => void;
}

export interface ModalConfigById<P = {}> extends ModalConfigShared<P> {
  id: MODALS;
  body?: never;
  fullScreenOverlay?: never;
}

export interface ModalConfigWithBody<P = {}> extends ModalConfigShared<P> {
  body: ReactChild;
  id?: never;
  fullScreenOverlay?: never;
}

export interface ModalConfigFullScreenOverlay<P = {}>
  extends ModalConfigShared<P> {
  id: MODALS;
  body?: never;
  title?: never;
  footer?: never;
  fullScreenOverlay?: true;
}

export type ModalConfig<P = {}> =
  | ModalConfigById<P>
  | ModalConfigWithBody<P>
  | ModalConfigFullScreenOverlay<P>;

export type ModalsConfig = ReadonlyArray<ModalConfig>;

export enum MODALS {
  PROFILE_MODAL,
  PROFILE_MODAL_TITLE,
  PROFILE_THANK_YOU_MODAL,
  CLOSE_MODAL_BUTTON,
  ANSWER_PRODUCT_VERSION,
  WELCOME_MODAL,
  PSYCAP_POTENTIAL_WELCOME_MODAL,
  PERSPECTIVES_WELCOME_MODAL,
  SUDO_MODAL,
  PRODUCT_VERSION_NAME_AND_LOGO,
  COMPLETE_MODAL,
  CREATE_USER_MODAL,
  CREATE_GROUP_MODAL,
  IMPORT_USERS_MODAL,
  EMAIL_EDIT_MODAL,
  NOTIFICATION_EMAIL_EDIT_MODAL,
  TOKEN_EXPIRED_MODAL,
  GENERIC_MODAL,
  REPORT_GENERATION,
  SEND_REPORTS_MODAL,
  EDIT_ACCOUNT_ADMIN_PRODUCTS_MODAL,
  PURCHASE_CREDITS_MODAL,
  CHOOSE_SHOP_ITEM_VERIFICATION_TYPE_MODAL,
  PRODUCT_VERSIONS_REPORT_GENERATION_MODAL,
  CONFIRM_UPDATE_BULK_DISCOUNT_BAND_MODAL,
  CONFIRM_UPGRADE_ACCOUNT_TYPE_MODAL,
  EDIT_CREDIT_COST_MODAL,
  EDIT_BULK_DISCOUNT_BAND_MODAL,
  DELETE_BULK_DISCOUNT_BAND_MODAL,
  ADD_BULK_DISCOUNT_BAND_MODAL,
  ADD_CREDITS_TO_ORGANISATION_MODAL,
  EDIT_ACCOUNT_LOGO_MODAL,
  ADD_USERS_MODAL,
  ADD_GROUPS_MODAL,
  INVITE_TO_STAGE_TWO_MODAL,
  RESPONDENT_ASSESSMENT_CHECKLIST_MODAL,
  EDIT_TAX_DETAILS,
  PAYMENT_IN_PROGRESS_MODAL,
  SHOP_ITEM_PURCHASE_CONFIRMATION_MODAL,
  ADVANCED_ANALYTICS_MODAL,
  CREATE_TEAM_REPORT_MODAL,
  CREATE_JOB_MATCH_REPORTS_MODAL,
  CREATE_SUCCESS_PROFILE_REPORTS_MODAL,
  SPLIT_ROW_MODAL,
  DATA_ANALYTICS_OVERLAY_MODAL,
  DATA_ANALYTICS_HEATMAP_OVERLAY_MODAL,
  START_PULSING_MODAL,
  STOP_PULSING_MODAL,
  RESEND_PULSE_INVITES,
  CONFIRM_REMOVE_MODAL,
  SET_TIME_MODAL,
  SET_FREQUENCY_TIME_MODAL,
  PURCHASED_PULSE_BREAKDOWN_MODAL,
  REQUEST_STATUS_MODAL,
  ADVANCED_ANALYTICS_CONFIRM_MODAL,
  ADVANCED_ANALYTICS_SET_VALUE_MODAL,
  ADVANCED_ANALYTICS_SAVE_A_COPY_MODAL,
  ADD_REMOVE_USER_MODAL,
  ADD_PROFILE_RATERS_MODAL,
  GENERATE_PROFILE_MODAL,
  PROFILE_RANGE_MODAL,
  VERIFY_PROFILE_MODAL,
}

export enum MODAL_FOOTERS {
  CLOSE_BUTTON_RIGHT,
}

import i18next from 'i18next';
import { Map } from 'immutable';
import React from 'react';

import PureComponent from '^/components/PureComponent';
import StepProgressBar from '^/components/StepProgressBar';
import AssessmentHeaderDetails from './AssessmentHeaderDetails';

interface OwnProps {
  productVersion: Map<string, any>;
  raterFor?: Map<string, any>;
  questionCollection?: Map<string, any>;
  questionCollectionIdx?: number;
  steps?: number;
  currentStep?: number;
  showCompletionTime?: boolean | undefined;
  hideSectionName?: boolean;
  useProductVersionCompletionTime?: boolean;
  blankCompletionTime?: boolean;
  organisationName?: string;
  noLogo?: boolean;
  removePercentage?: boolean;
}

type Props = OwnProps;

export default class AssessmentHeader extends PureComponent<Props> {
  public render() {
    const {
      productVersion,
      questionCollection,
      questionCollectionIdx,
      steps,
      currentStep,
      raterFor,
      hideSectionName,
      showCompletionTime,
      useProductVersionCompletionTime,
      blankCompletionTime,
      organisationName,
      noLogo,
      removePercentage,
    } = this.props;

    return (
      <div className="assessment-header">
        <AssessmentHeaderDetails
          productVersion={productVersion}
          questionCollection={questionCollection}
          questionCollectionIdx={questionCollectionIdx}
          raterFor={raterFor}
          hideSectionName={hideSectionName}
          showCompletionTime={showCompletionTime}
          useProductVersionCompletionTime={useProductVersionCompletionTime}
          blankCompletionTime={blankCompletionTime}
          organisationName={organisationName}
          noLogo={noLogo}
        />

        {typeof steps === 'number' &&
          typeof currentStep === 'number' &&
          !removePercentage && (
            <div>
              <div className="asset-completion">
                {i18next.t<string>('{{percentCompleted}}% complete', {
                  percentCompleted: Math.round((currentStep / steps) * 100),
                })}
              </div>
              <StepProgressBar steps={steps} currentStep={currentStep} />
            </div>
          )}
      </div>
    );
  }
}

/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import i18next from 'i18next';

import { SuccessProfileItem } from '^/reducers/api/types';

interface Props {
  title: string;
  items: Array<SuccessProfileItem>;
  hasRanges: boolean;
}

const FactorGroup: React.FC<Props> = ({ title, items, hasRanges }) => {
  return (
    <div className="profile-chart">
      <h3 className="title">{title}</h3>
      <div className="title-divider" />
      <div className="grid" style={{ height: `${items.length * 32}px` }}>
        <div className="grid-lines">
          <span className="grid-label" style={{ left: '0%' }}>
            0
          </span>
          <span className="grid-label" style={{ left: '50%' }}>
            5
          </span>
          <span className="grid-label" style={{ left: '100%' }}>
            10
          </span>
          <div className="grid-line" style={{ left: '0%' }} />
          <div className="grid-line" style={{ left: '50%' }} />
          <div className="grid-line" style={{ left: '100%' }} />
          {!hasRanges && (
            <span className="no-ranges-label">
              {i18next.t<string>('No ranges available')}
            </span>
          )}
        </div>
      </div>
      <div className="factor-chart">
        {items.map((item, index) => (
          <div key={index} className="factor-item">
            <span className="factor-label">{item.factor_label}</span>
            {item.range_low && item.range_high && (
              <div className="factor-bar-container">
                <div
                  className="factor-bar"
                  data-testid={`factor-bar-${index}`}
                  style={{
                    left: `${item.range_low * 10}%`,
                    width: `${(item.range_high - item.range_low) * 10}%`,
                  }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FactorGroup;

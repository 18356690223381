import i18next from 'i18next';
import { Map } from 'immutable';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { useFormik } from 'formik';

import Alert, { AlertType } from '^/components/Alert';
import FormError from '^/components/FormError';
import LiveButton from '^/components/LiveButton';
import Well from '^/components/Well';
import { LanguageCode, RTL_LANGUAGES } from '^/constants/routes';
import { USER_ROLES } from '^/models/user';
import { interpolateProductVersionDetails } from '^/productVersions';
import { isPending } from '^/responseStates';
import { StoreState } from '^/store';
import AboutQuestionCollectionSection from './AboutQuestionCollectionSection';
import AssessmentBody from './AssessmentBody';
import AssessmentContainer from './AssessmentContainer';
import AssessmentFooter from './AssessmentFooter';
import AssessmentHeader from './AssessmentHeader';
import WelcomePageChecklistForm from './WelcomePageChecklistForm';
import { PRODUCT_TYPES } from '^/components/productVersions/choices';

interface OwnProps {
  name?: string;
  welcomeMessageShort?: string;
  welcomeMessageLong?: string;
  welcomeMessageTitle?: string;
  warningText?: string;
  isBreakPage?: boolean;
  warningTextNonAnonymous?: string;
  onCancel?: () => void;
  onContinue?: () => void;
  productVersion: Map<string, any>;
  user: Map<string, any>;
  respondent?: Map<string, any>;
  isSelfRating?: boolean;
  questionCollectionIdx?: number;
  cancelLabel?: string;
  continueLabel?: string;
  checklistItems?: ReadonlyArray<
    Readonly<{
      text: string;
    }>
  >;
  sections?: ReadonlyArray<
    Readonly<{
      name: string;
      text: string;
    }>
  >;
  getActivityResponse?: Map<string, any>;
  startSessionResponse: Map<string, any>;
  hideSectionName?: boolean;
  useProductVersionCompletionTime?: boolean;
  blankCompletionTime?: boolean;
  organisationName?: string;
  sectionAlertText?: string;
}

interface StateProps {
  activeLanguage: LanguageCode;
  uiLanguage: LanguageCode;
}

type Props = OwnProps & StateProps;

const renderErrorAlert = (text: string) => (
  <Alert type={AlertType.Error}>{text}</Alert>
);

export const WelcomePage: React.FC<Props> = props => {
  function process(line?: string) {
    if (!line) {
      return '';
    }
    return interpolateProductVersionDetails(
      line,
      props.productVersion,
      props.respondent,
      props.isSelfRating
    );
  }

  function getBodyHeader() {
    const { welcomeMessageShort, welcomeMessageTitle } = props;
    const title = welcomeMessageTitle || i18next.t<string>('Welcome');

    if (welcomeMessageShort) {
      return (
        <div>
          <div>{title}</div>
          <ReactMarkdown source={process(welcomeMessageShort)} />
        </div>
      );
    }
    return title;
  }

  const {
    startSessionResponse,
    welcomeMessageLong,
    warningText,
    warningTextNonAnonymous,
    onCancel,
    onContinue,
    cancelLabel,
    continueLabel,
    user,
    checklistItems,
    productVersion,
    sections,
    questionCollectionIdx,
    hideSectionName,
    useProductVersionCompletionTime,
    isSelfRating,
    respondent,
    blankCompletionTime,
    organisationName,
    activeLanguage,
    uiLanguage,
    getActivityResponse,
    sectionAlertText,
    isBreakPage,
  } = props;

  const initialValues = checklistItems?.reduce(
    (acc, { text }) => ({
      ...acc,
      [text]: false,
    }),
    {}
  );
  const checklistFormik = useFormik<Record<string, boolean>>({
    initialValues: initialValues || {},
    onSubmit: () => {}, // eslint-disable-line
  });

  const everyCheckboxSelected =
    checklistItems &&
    checklistItems.length &&
    checklistItems.every(checklistItem => {
      const text = checklistItem.text;
      const field = checklistFormik.values[text];
      return field;
    });
  const hasUnselectedChecklistItems = Boolean(
    checklistItems && checklistItems.length && !everyCheckboxSelected
  );

  const isPendingStartSessionResponse = isPending(startSessionResponse);
  const isNextDisabled =
    isPending(getActivityResponse) || hasUnselectedChecklistItems;
  const languageToWarnIn =
    checklistItems && activeLanguage !== uiLanguage && uiLanguage;
  return (
    <AssessmentContainer>
      <AssessmentHeader
        productVersion={productVersion}
        questionCollectionIdx={questionCollectionIdx}
        hideSectionName={hideSectionName}
        useProductVersionCompletionTime={useProductVersionCompletionTime}
        raterFor={isSelfRating ? undefined : respondent}
        blankCompletionTime={blankCompletionTime}
        organisationName={organisationName}
        showCompletionTime={!isBreakPage}
      />
      <AssessmentBody header={getBodyHeader()}>
        {languageToWarnIn && (
          <div
            dir={RTL_LANGUAGES.indexOf(languageToWarnIn) === -1 ? 'lrt' : 'rtl'}
          >
            <Alert type={AlertType.Info}>
              {i18next.t<string>('Warn language not available', {
                lng: languageToWarnIn,
              })}
            </Alert>
          </div>
        )}
        {Boolean(welcomeMessageLong || checklistItems) && (
          <Well disabled={isNextDisabled}>
            {welcomeMessageLong && (
              <ReactMarkdown source={process(welcomeMessageLong)} />
            )}
            {checklistItems && (
              <WelcomePageChecklistForm
                formik={checklistFormik}
                disabled={isPendingStartSessionResponse}
              />
            )}
          </Well>
        )}
        {checklistItems &&
          productVersion.getIn(['product', 'type']) ===
            PRODUCT_TYPES.CHOICES.PERSPECTIVES && (
            <Trans i18nKey="TRANS disability adjustment" parent="p">
              If you have a disability which requires reasonable adjustments,
              please inform us before commencing the assessment by contacting{' '}
              <a href="mailto:enablesupport@peoplewise.co.uk">
                enablesupport@peoplewise.co.uk
              </a>
              . We may ask you to evidence the basis of the reasonable
              adjustment.
            </Trans>
          )}
        {sections && <AboutQuestionCollectionSection sections={sections} />}
        {sectionAlertText && sections && (
          <Alert type={AlertType.Info}> {sectionAlertText}</Alert>
        )}
        {user.get('role') !== USER_ROLES.EXTERNAL && warningText && (
          <Alert>
            <ReactMarkdown
              source={
                process(warningTextNonAnonymous) + ' ' + process(warningText)
              }
            />
          </Alert>
        )}

        {user.get('role') === USER_ROLES.EXTERNAL && warningText && (
          <Alert>
            <ReactMarkdown source={process(warningText)} />
          </Alert>
        )}
      </AssessmentBody>
      <AssessmentFooter>
        {((user.get('role') !== USER_ROLES.EXTERNAL &&
          onCancel &&
          checklistItems) ||
          isBreakPage) && (
          <button
            className="btn"
            onClick={onCancel}
            disabled={isPendingStartSessionResponse}
          >
            {cancelLabel || i18next.t<string>('Complete another time')}
          </button>
        )}

        <LiveButton
          onClick={onContinue}
          buttonText={continueLabel || i18next.t<string>('Next')}
          response={startSessionResponse}
          pendingMessage={continueLabel || i18next.t<string>('Next')}
          disabled={isNextDisabled}
          hideErrors
        />
      </AssessmentFooter>
      <FormError
        response={startSessionResponse}
        renderError={renderErrorAlert}
      />
    </AssessmentContainer>
  );
};

export function mapStateToProps(state: StoreState): StateProps {
  return {
    activeLanguage: state.ui.get('activeLanguage'),
    uiLanguage: state.ui.get('uiLanguage'),
  };
}

export default connect(mapStateToProps)(WelcomePage);

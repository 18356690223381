import React, { useCallback } from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Set } from 'immutable';

import { ClickableTile } from '^/components/ClickableTile';
import { getAllProductOrganisations } from '^/actions/collections';
import {
  selectDefaultActivityType,
  selectSelectedOrganisation,
} from '^/components/activities/selectors';
import {
  PRODUCT_ACTIVITY_TYPES,
  SUCCESS_PROFILE_VERSION_TYPES,
} from '^/components/productVersions/choices';

export const CreateActivityVersionChoice = props => {
  const { goToNextStage } = props;
  const chooseProduct = useCallback(
    (id, versionIds) => {
      const chosenProduct = props.productOrganisations.filter(
        item => item.getIn(['id']) === id
      );
      goToNextStage(chosenProduct, versionIds);
    },
    [goToNextStage, props.productOrganisations]
  );

  const diskEnabled = props.productOrganisations.filter(
    item =>
      item.getIn(['product', 'name']) ===
      PRODUCT_ACTIVITY_TYPES.DISPLAY.PROFILER_DISC
  ).size;

  const diskId = props.productOrganisations
    .find(
      item =>
        item.getIn(['product', 'name']) ===
        PRODUCT_ACTIVITY_TYPES.DISPLAY.PROFILER_DISC
    )
    ?.get('id');

  const perspectiveSuccessId = props.productOrganisations
    .find(
      item =>
        item.getIn(['product', 'name']) ===
        PRODUCT_ACTIVITY_TYPES.DISPLAY.PERSPECTIVES_SUCCESS_PROFILE
    )
    ?.get('id');

  const versionIds = props.organisationDetailSimple
    .get('productorganisation_set')
    .reduce((acc, item) => {
      if (
        item.get('display_name') ===
        PRODUCT_ACTIVITY_TYPES.DISPLAY.PERSPECTIVES_SUCCESS_PROFILE.toString()
      ) {
        acc.push(item.get('version_types'));
      }
      return acc;
    }, []);

  const unverified = versionIds[0]
    ? versionIds[0]
        .toJS()
        .find(
          item => item.type === SUCCESS_PROFILE_VERSION_TYPES.CHOICES.UNVERIFIED
        )
    : undefined;
  const verified = versionIds[0]
    ? versionIds[0]
        .toJS()
        .find(
          item => item.type === SUCCESS_PROFILE_VERSION_TYPES.CHOICES.VERIFIED
        )
    : undefined;

  return (
    <div className="row">
      <div className="display-flex justify-content-center version-choices">
        <div className="col-xs-8">
          <div className="clickable-tile-container">
            <ClickableTile
              disabled={!diskEnabled}
              title={i18next.t('Disc Job Profiler')}
              icon={
                <img
                  src="/static/img/icons/disc-logo.png"
                  className="clickable-tile-icon clickable-tile-create"
                />
              }
              onClick={() => chooseProduct(diskId, null)}
              strapline={i18next.t('Profile build only')}
            />
            <ClickableTile
              disabled={!unverified}
              title={i18next.t('Perspectives Success Profiler')}
              icon={
                <img
                  width="75px"
                  src="/static/img/icons/perspectives_logo.svg"
                  className="clickable-tile-icon clickable-tile-create"
                />
              }
              onClick={() => chooseProduct(perspectiveSuccessId, unverified)}
              strapline={i18next.t('UNVERIFIED')}
            />
            <ClickableTile
              disabled={!verified}
              title={i18next.t('Perspectives Success Profiler')}
              icon={
                <img
                  width="75px"
                  src="/static/img/icons/perspectives_logo.svg"
                  className="clickable-tile-icon clickable-tile-create"
                />
              }
              onClick={() => chooseProduct(perspectiveSuccessId, verified)}
              strapline={i18next.t('VERIFIED')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export function mapStateToProps(state, props) {
  return {
    products: state.multiResponses.getIn(['getCollection', 'products']),
    organisationDetailSimple: state.organisationDetailSimple,
    selectedOrganisation: selectSelectedOrganisation(state, props.groupId),
    defaultActivityType: selectDefaultActivityType(
      state,
      props.defaultProductOrganisations || []
    ),
    productOrganisations: state.collections.getIn([
      'productOrganisations',
      'items',
    ]),
    selectedProductOrganisations: state.ui
      .get('selectedUiComponents')
      .get('productOrganisations', Set()),
  };
}

export default connect(mapStateToProps, {
  getAllProductOrganisations,
})(CreateActivityVersionChoice);

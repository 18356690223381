import { faArrowsAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { List } from 'immutable';
import React, { PureComponent } from 'react';
import i18next from 'i18next';

import DragAndDropGroup from '^/components/drag-and-drop/DragAndDropGroup';
import DragAndDropHandler from '^/components/drag-and-drop/DragAndDropHandler';
import Draggable from '^/components/drag-and-drop/Draggable';
import DragTarget from '^/components/drag-and-drop/DragTarget';
import {
  Item,
  QuestionGroup,
  State,
  getItemId,
  getItemText,
  isEmpty,
  isEmptyBoolean,
  prepareGroups,
  shouldSwap,
} from '^/components/dashboard/enduser/answer/utils';

interface OwnProps {
  isPending: boolean;
  questionGroup: QuestionGroup;
  reorderAnswers: (answers: List<string | null>) => void;
  targetText?: boolean;
  isSuccessMotivators?: boolean;
}

type Props = OwnProps;

export class MotivatorQuestionGroup extends PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = prepareGroups(props.questionGroup);
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.questionGroup !== this.props.questionGroup) {
      this.setState(prepareGroups(this.props.questionGroup));
    }
  }
  public render() {
    const targetMost = this.props.isSuccessMotivators
      ? i18next.t<string>('- Most')
      : i18next.t<string>('Most important to me at work');
    const targetLeast = this.props.isSuccessMotivators
      ? i18next.t<string>('- Least')
      : i18next.t<string>('Least important to me at work');
    return (
      <DragAndDropHandler
        groups={this.state}
        onDrop={this.onDrop}
        shouldSwap={shouldSwap}
        isEmpty={isEmptyBoolean}
        className="isp-drag-and-drop-container"
      >
        {group => (
          <DragAndDropGroup<Item>
            key={group}
            group={group}
            tag="ul"
            className={classNames(
              'isp-drag-and-drop-group',
              group,
              this.props.isSuccessMotivators && 'success'
            )}
          >
            {(item, index) => {
              const draggable = (
                <Draggable
                  key={getItemId(item)}
                  index={index}
                  disableDrag={isEmpty(item)}
                  className={classNames('draggable', {
                    empty: isEmpty(item),
                  })}
                  draggedClassName="dragged"
                  placeholderClassName="placeholder"
                >
                  <span className="draggable-text">{getItemText(item)}</span>
                  {!isEmpty(item) && <FontAwesomeIcon icon={faArrowsAlt} />}
                </Draggable>
              );
              const draggableTarget = (
                <Draggable
                  key={getItemId(item)}
                  index={index}
                  disableDrag={isEmpty(item)}
                  className={classNames('draggable motivator', {
                    empty: isEmpty(item),
                  })}
                  draggedClassName="dragged"
                  placeholderClassName="placeholder"
                >
                  <span className="draggable-text">
                    <div className="drag-text-wrapper">
                      <div>{!isEmpty(item) && getItemText(item)}</div>
                    </div>
                    {isEmpty(item) && index === 0 && this.props.targetText && (
                      <div className="drag-target-text">{targetMost}</div>
                    )}
                    {isEmpty(item) &&
                      index === this.state.from.length - 1 &&
                      this.props.targetText && (
                        <div className="drag-target-text">{targetLeast}</div>
                      )}
                  </span>
                </Draggable>
              );

              return group === 'from' ? (
                <li className="drag-container">{draggable}</li>
              ) : (
                <DragTarget
                  key={getItemId(item)}
                  index={index}
                  tag="li"
                  className="drag-container"
                >
                  {draggableTarget}
                </DragTarget>
              );
            }}
          </DragAndDropGroup>
        )}
      </DragAndDropHandler>
    );
  }

  private onDrop = (state: State) => {
    this.setState(state);
    const newOrder = List(
      state.to.map(item => (isEmpty(item) ? null : getItemId(item)))
    );
    this.props.reorderAnswers(newOrder);
  };
}

export default MotivatorQuestionGroup;

import i18next from 'i18next';
import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import {
  createReportsAndRefresh,
  createJobReportsAndRefresh,
} from '^/actions/actionSequences';
import { closeTopModal } from '^/actions/modals';
import PureComponent from '^/components/PureComponent';
import { Profiles, Uuid } from '^/reducers/api/types';
import { selectResponse } from '^/selectors/responses';
import { StoreState } from '^/store';
import ReportGenerationForm from '../ReportGenerationForm';
import {
  selectActivityReportTypes,
  selectAllSelectedReportTemplateSet,
  selectExistingValues,
  selectProductsInfo,
  selectReportGenerationFields,
  selectReportGenerationTotalCost,
  selectRespondentNames,
} from './selectors';
import { ActivityReportTypes, ExistingValues, ProductInfo } from './types';
import { selectUserProfile } from '^/selectors';

interface OwnProps {
  isProfiler: boolean | undefined;
  organisationId: Uuid;
  onPurchase: () => void;
  onClose: () => void;
}

interface StateProps {
  activityReportTypes: ActivityReportTypes;
  user: Immutable.Map<string, any>;
  profileSessions: Profiles | null;
  existingValues: ExistingValues;
  response?: Map<string, any>;
  fields: ReadonlyArray<string>;
  productsInfo: { [key: string]: ProductInfo };
  totalCost: number;
  respondentNames: ReadonlyArray<string>;
  selectedReportTemplateSet: Set<{ reportTemplateId: Uuid; productId: Uuid }>;
}

interface DispatchProps {
  closeTopModal: typeof closeTopModal;
  createReportsAndRefresh: typeof createReportsAndRefresh;
  createJobReportsAndRefresh: typeof createJobReportsAndRefresh;
}

export type Props = StateProps & DispatchProps & OwnProps;

export class ReportGenerationModal extends PureComponent<Props> {
  public render() {
    const {
      activityReportTypes,
      response,
      fields,
      productsInfo,
      selectedReportTemplateSet,
      onClose,
      respondentNames,
      totalCost,
      isProfiler,
      profileSessions,
      existingValues,
    } = this.props;
    const initialValues: Record<string, string> = fields.reduce(
      (acc, field) => ({
        ...acc,
        [field]: '',
      }),
      {}
    );

    const sessionId = existingValues.map(item => item.session);

    const jobProfilerRole =
      isProfiler && profileSessions
        ? profileSessions
            .filter(({ id }) => sessionId.includes(id))
            .map(item => item.profile_name)
            .join(', ')
        : null;
    return (
      <div>
        {!isProfiler && (
          <div>
            <h5>{i18next.t<string>('Selected respondents')}</h5>
            <div className="boxed">
              <div className="box">{respondentNames.join(', ')}</div>
            </div>
          </div>
        )}

        <ReportGenerationForm
          profileName={jobProfilerRole}
          activityReportTypes={activityReportTypes}
          initialValues={initialValues}
          productsInfo={productsInfo}
          selectedReportTemplateSet={selectedReportTemplateSet}
          onSubmit={this.onSubmit}
          onClose={onClose}
          response={response}
          totalCost={totalCost}
        />
      </div>
    );
  }

  private onSubmit = (data: { [key: string]: string }) => {
    const { existingValues, fields, organisationId } = this.props;
    const comparisonGroups = fields
      .filter(fieldName => fieldName.startsWith('comparisonGroup_'))
      .filter(fieldName => data[fieldName])
      .map(fieldName => ({
        product: fieldName.substr(16),
        id: data[fieldName],
      }));
    if (this.props.isProfiler) {
      this.props.createJobReportsAndRefresh(
        organisationId,
        {
          reports_to_generate: existingValues.map(user => ({
            report_template: user.reportTemplateId,
            session: user.session,
            report_team: user.reportTeamId,
          })),
          comparison_groups: comparisonGroups,
          job_level: data.jobLevel,
        },
        this.props.user
      );
    } else {
      this.props.createReportsAndRefresh(organisationId, {
        reports_to_generate: existingValues.map(user => ({
          report_template: user.reportTemplateId,
          session: user.session,
          report_team: user.reportTeamId,
        })),
        comparison_groups: comparisonGroups,
        job_level: data.jobLevel,
      });
    }
    this.props.onPurchase();
  };
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    activityReportTypes: selectActivityReportTypes(state),
    user: selectUserProfile(state),
    existingValues: selectExistingValues(state),
    response: selectResponse(state, 'createReports'),
    fields: selectReportGenerationFields(state),
    productsInfo: selectProductsInfo(state),
    totalCost: selectReportGenerationTotalCost(state),
    respondentNames: selectRespondentNames(state),
    profileSessions: state.profileSessions,
    selectedReportTemplateSet: selectAllSelectedReportTemplateSet(state),
  };
}

export default connect(mapStateToProps, {
  closeTopModal,
  createReportsAndRefresh,
  createJobReportsAndRefresh,
})(ReportGenerationModal);

import React from 'react'; // eslint-disable-line no-unused-vars
import _ from 'underscore';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { fromJS } from 'immutable';

import { formatDate } from '^/utils';
import PureComponent from '^/components/PureComponent';
import Loading from '^/components/Loading';
import ListPage from '^/components/ListPage';
import ListPagePagination from '^/components/ListPagePagination';
import { isPending } from '^/responseStates';
import { sortList, setCurrentPageIndex } from '^/actions/actions';
import { closeTopModal } from '^/actions/modals';
import { loadTransactionBatch } from '^/actions/items';
import { ITEMS_PER_PAGE } from '^/actions/collections';
import { getUserDisplayName } from '^/selectors/user';
import { ACTIVITY_TYPE } from '^/models/activity';

function getHeaders(isTeam, isProfile) {
  let name_column = {
    title: i18next.t('Respondent'),
    field: ['report', 'user_name'],
    sortable: true,
  };
  if (isTeam) {
    name_column = {
      title: i18next.t('Team name'),
      field: ['report', 'team_name'],
      sortable: true,
    };
  } else if (isProfile) {
    name_column = {
      title: i18next.t('Profile name'),
      field: ['report', 'profile_name'],
      sortable: true,
    };
  }
  return [
    name_column,
    {
      title: i18next.t('Type'),
      field: ['template_type'],
      sortable: true,
    },
    {
      title: i18next.t('Credits'),
      field: ['amount'],
      sortable: true,
    },
  ];
}

const InfoTable = ({ items }) => (
  <div className="row content-box">
    {items.map(({ value, label, width }, idx) => (
      <div key={idx} className={`col-xs-12 col-md-${width}`}>
        <div className="info-table-label">{label}</div>
        <div className="info-table-value">{value}</div>
      </div>
    ))}
  </div>
);

export class CreditTransactionDetailModal extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.loadTransactionBatch(this.props.batch.get('id'));
    this.props.setCurrentPageIndex(0);
  }

  renderRow(item) {
    const {
      id,
      report: { user_name, team_name, profile_name },
      template_type,
      amount,
    } = item.toJS();

    return (
      <tr key={id}>
        <td>{team_name || profile_name || user_name}</td>
        <td>{template_type}</td>
        <td>{amount}</td>
      </tr>
    );
  }

  sortBy(field) {
    const { sortBy } = this.props;
    this.props.sortList('creditTransactions', {
      field: field,
      reversed: _.isEqual(sortBy.field, field) ? !sortBy.reversed : false,
    });
  }

  withCurrentSort(batchCreditHistory) {
    const {
      sortBy: { field, reversed },
    } = this.props;
    const sorted = field
      ? batchCreditHistory.sortBy(item => item.getIn(field))
      : batchCreditHistory;
    return reversed ? sorted.reverse() : sorted;
  }

  getCurrentPage(batchCreditHistory) {
    const { currentPageIndex } = this.props;
    if (currentPageIndex === null) {
      return batchCreditHistory;
    }

    const startIdx = currentPageIndex * ITEMS_PER_PAGE;
    return batchCreditHistory.slice(startIdx, startIdx + ITEMS_PER_PAGE);
  }

  renderSummaryTable(batch) {
    const { user } = this.props;
    return (
      <InfoTable
        items={[
          {
            label: i18next.t('Date'),
            value: formatDate(batch.get('created')),
            width: 3,
          },
          {
            label: i18next.t('Activity'),
            value: batch.getIn(['activity', 'name'], i18next.t('N/A')),
            width: 5,
          },
          {
            label: i18next.t('Total credits'),
            value: batch.get('amount'),
            width: 2,
          },
          {
            label: i18next.t('Admin user'),
            value: getUserDisplayName(user, batch.get('user')),
            width: 2,
          },
        ]}
      />
    );
  }

  render() {
    const {
      isLoading,
      batchCreditHistory,
      sortBy,
      currentPageIndex,
      batch,
    } = this.props;

    if (!batchCreditHistory || isLoading) {
      return <Loading />;
    }

    const isTeam = batch.get('activity') === null;
    const isProfile =
      batch.getIn(['activity', 'type']) === ACTIVITY_TYPE.PROFILER;
    const items = this.getCurrentPage(this.withCurrentSort(batchCreditHistory));
    return (
      <div>
        <div>
          {this.renderSummaryTable(batch)}

          <ListPagePagination
            pageSize={ITEMS_PER_PAGE}
            collection={fromJS({
              page: currentPageIndex + 1,
              count: batchCreditHistory.size,
              items,
            })}
            onSelectPage={page => this.props.setCurrentPageIndex(page - 1)}
            onShowAll={() => this.props.setCurrentPageIndex(null)}
          />
          <ListPage
            typeNamePlural="transactions"
            items={items}
            headers={getHeaders(isTeam, isProfile)}
            tableClassName="transaction-history"
            filters={[]}
            renderer={this.renderRow.bind(this)}
            ordering={sortBy}
            onOrderingChange={ordering => this.sortBy(ordering)}
          />
        </div>

        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t('Close')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: isPending(state.responses.get('loadItem')),
    batchCreditHistory: state.items.getIn(['batchCreditHistory', 'logs']),
    sortBy: state.sortOrders.get('creditTransactions', {}),
    currentPageIndex: state.ui.get('currentPageIndex'),
    user: state.userProfile,
  };
}

export default connect(mapStateToProps, {
  loadTransactionBatch,
  closeTopModal,
  sortList,
  setCurrentPageIndex,
})(CreditTransactionDetailModal);

import i18next from 'i18next';
import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { toggleReportsTableSelection } from '^/actions/actions';
import { loadOrganisation } from '^/actions/items';
import {
  openCreateJobMatchReportsModal,
  openCreateSuccessProfileReportsModal,
} from '^/actions/modals';
import Table from '^/components/Table';
import {
  Profiles,
  Profile,
  OrgSessions,
  OrgUser,
  Uuid,
  ProductOrganisation,
  ReportTemplate,
} from '^/reducers/api/types';
import {
  ReportsTableSelections,
  JobProfileSelection,
  available,
  isSelected,
  asKeys,
} from '^/reducers/reports/types';
import { selectOrgSessions, selectUserProfile } from '^/selectors';
import { StoreState } from '^/store';
import {
  selectAccountHasUnlimitedCredits,
  selectAllSelectedReportsTable,
  selectAnySelectedReportsTable,
  selectFilteredOrgUsers,
  selectHasAnyReportsTable,
  selectReportsTableSelections,
} from './selectors';
import { selectMappedSelectedProducts } from './AdminReportsPage/selectors';
import { DropdownOption } from '^/components/dropdown/DropdownItem';
import NewButton from '^/components/NewButton';
import { can, downloadExistingReports } from '^/capabilities';
import SelectableReport from './SelectableReport';
import {
  PRODUCT_TYPES,
  PRODUCT_ACTIVITY_TYPES,
} from '^/components/productVersions/choices';
import { SUCCESS_PROFILE_REPORT_TEMPLATE_CODES } from './constants';

type OwnProps = {
  profileSessions: Profiles | null;
  canUserAdministerOwnOrganisation: boolean;
  orgId: Uuid | null;
  discProductOrg: ProductOrganisation | null;
  perspectivesProductOrg: ProductOrganisation | null;
  templateJMAvailable: boolean;
};

interface DispatchProps {
  toggleReportsTableSelection: typeof toggleReportsTableSelection;
  loadOrganisation: typeof loadOrganisation;
  openCreateJobMatchReportsModal: typeof openCreateJobMatchReportsModal;
  openCreateSuccessProfileReportsModal: typeof openCreateSuccessProfileReportsModal;
}

interface StateProps {
  user: Immutable.Map<string, any>;
  orgSessions: OrgSessions | null;
  users: ReadonlyArray<OrgUser>;
  accountHasUnlimitedCredits: boolean;
  reportsTableSelections: ReportsTableSelections;
  allSelected: { [key in keyof ReportsTableSelections]: boolean };
  anySelected: { [key in keyof ReportsTableSelections]: boolean };
  hasAny: { [key in keyof ReportsTableSelections]: boolean };
  productsItems: ReadonlyArray<DropdownOption>;
  organisation: Immutable.Map<string, any>;
}

export type Props = OwnProps & StateProps & DispatchProps;

interface State {
  noAvailableSessions: boolean;
}

export class ProfileReportsTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const state = {
      noAvailableSessions: false,
    };

    if (props.canUserAdministerOwnOrganisation) {
      state.noAvailableSessions = false;
    }

    this.state = state;
  }

  fetchDataForCurrentlySelectedOrganisation() {
    this.props.loadOrganisation(this.props.orgId);
  }

  UNSAFE_componentWillMount(): void {
    this.fetchDataForCurrentlySelectedOrganisation();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.orgId !== this.props.orgId) {
      this.fetchDataForCurrentlySelectedOrganisation();
    }
  }

  public render() {
    const { user } = this.props;
    const profileSessions = this.props.profileSessions;
    return (
      <div className="profile-reports-table">
        <p className="mb-md mt-sm" />
        {profileSessions && this.getReportCountMessage(profileSessions.length)}
        {profileSessions && (
          <Table
            className="interactive-table"
            columns={[
              {
                header: i18next.t<string>('Profile'),
                value: profile =>
                  i18next.t<string>(
                    profile.activity.activity_product_version_items[0]
                      .product_version.product.type
                  ),
              },
              {
                header: i18next.t<string>('Profile name'),
                value: profile => profile.profile_name,
              },
              {
                header: i18next.t<string>('Date created'),
                value: profile =>
                  this.renderDate(
                    profile.activity.activity_product_version_items[0]
                      .activity_product_version_sessions[0]?.completed
                  ),
              },
              {
                header: i18next.t<string>('Profile creator'),
                value: profile => profile.activity.created_by_user.full_name,
              },
              {
                header: i18next.t<string>('Profile verifier'),
                value: profile =>
                  profile.success_profile?.verified_by
                    ? profile.success_profile.verified_by.full_name
                    : 'N/A',
              },
              {
                header: i18next.t<string>('Report'),
                value: profile => {
                  const productOrg = this.getProductOrg(profile);
                  if (!productOrg) {
                    return '';
                  }
                  return this.getReportCell(profile, productOrg, user);
                },
              },
              {
                header: 'Generate',
                value: profile => {
                  const productOrg = this.getProductOrg(profile);
                  if (!productOrg) {
                    return '';
                  }
                  if (profile.success_profile) {
                    return this.renderSuccessProfileActionButton(
                      profile,
                      productOrg
                    );
                  }
                  return this.renderDiscProfileActionButton(
                    profile,
                    productOrg
                  );
                },
              },
            ]}
            rows={List(profileSessions)}
            emptyMessage={i18next.t<string>(
              'There are no Job Profile Reports created yet for this account.'
            )}
          />
        )}
      </div>
    );
  }

  private getProductOrg(profile: Profile) {
    const { discProductOrg, perspectivesProductOrg } = this.props;

    const productType =
      profile.activity.activity_product_version_items[0].product_version.product
        .type;

    let productOrg;
    if (productType === PRODUCT_TYPES.CHOICES.DISC) {
      productOrg = discProductOrg;
    } else if (
      productType ===
      PRODUCT_ACTIVITY_TYPES.CHOICES.PERSPECTIVES_SUCCESS_PROFILE
    ) {
      productOrg = perspectivesProductOrg;
    }
    return productOrg;
  }

  private renderSuccessProfileActionButton(
    profile: Profile,
    productOrg: ProductOrganisation
  ) {
    if (profile.success_profile === null) {
      return '';
    }
    const roleName = profile.profile_name;
    const successProfileId = profile.success_profile.id;

    const reportTemplates =
      productOrg?.productorganisationreporttemplate_set
        .map(template => template.report_template)
        .filter(template =>
          SUCCESS_PROFILE_REPORT_TEMPLATE_CODES.includes(template.code)
        ) || [];

    return (
      <NewButton
        buttonType="form"
        onClick={() =>
          this.openSuccessProfileReportsModal(
            successProfileId,
            roleName,
            reportTemplates,
            productOrg
          )
        }
        className="ml-none"
        disabled={!reportTemplates.length}
        title={
          !reportTemplates.length
            ? i18next.t<string>(
                'You do not have access to Success Profile reports'
              )
            : ''
        }
      >
        {i18next.t<string>('Success Profile')}
      </NewButton>
    );
  }

  private renderDiscProfileActionButton(
    profile: Profile,
    productOrg: ProductOrganisation
  ) {
    const roleName = profile.profile_name;

    const sessionId =
      profile.activity.activity_product_version_items[0]
        .activity_product_version_sessions[0].id;

    const isReportGenerated =
      profile.activity.activity_product_version_items[0]
        .activity_product_version_sessions[0].reports.length > 0;

    return (
      <NewButton
        buttonType="form"
        onClick={() =>
          this.openJobMatchReportsModal(
            sessionId,
            isReportGenerated,
            roleName,
            productOrg
          )
        }
        className="ml-none"
        disabled={!this.props.templateJMAvailable}
        title={
          !this.props.templateJMAvailable
            ? i18next.t<string>(
                'You do not have access to the Job Match report'
              )
            : ''
        }
      >
        {i18next.t<string>('Job Match')}
      </NewButton>
    );
  }

  private renderDate(date: string) {
    return date ? moment(date).format('Do MMM, YYYY') : '';
  }

  private openJobMatchReportsModal = (
    id: string,
    isReportGenerated: boolean,
    roleName: string,
    productOrg: ProductOrganisation
  ) => {
    const { orgId, orgSessions } = this.props;
    const jobProfileReportTemplate =
      productOrg?.productorganisationreporttemplate_set[0].report_template;

    if (orgSessions && jobProfileReportTemplate) {
      this.props.openCreateJobMatchReportsModal(
        id,
        orgId || '',
        jobProfileReportTemplate,
        isReportGenerated,
        roleName
      );
    }
  };

  private openSuccessProfileReportsModal = (
    id: string,
    roleName: string,
    reportTemplates: ReadonlyArray<ReportTemplate>,
    productOrg: ProductOrganisation
  ) => {
    const { orgId } = this.props;
    if (reportTemplates.length) {
      this.props.openCreateSuccessProfileReportsModal(
        id,
        orgId || '',
        reportTemplates,
        roleName,
        productOrg
      );
    }
  };

  private getSelectionState(selection: JobProfileSelection) {
    const [key, innerKey] = asKeys(selection);
    return this.props.reportsTableSelections[key][innerKey];
  }

  private getReportCell(
    profile: Profile,
    productOrg: ProductOrganisation,
    user: Immutable.Map<string, any>
  ) {
    if (profile.success_profile) {
      return null;
    }
    const reports =
      profile.activity.activity_product_version_items[0]
        ?.activity_product_version_sessions[0]?.reports;
    const report = reports &&
      reports.length && { ...reports[0], template: reports[0].template.id };
    return this.renderSelectableReport({
      report: report,
      reportTemplate: {
        ...productOrg.productorganisationreporttemplate_set[0]?.report_template,
        code:
          productOrg.product.code +
          ' - ' +
          productOrg.productorganisationreporttemplate_set[0]?.report_template
            .code,
      },
      productId: productOrg.product.id,
      productVariantId: null,
      userId: user.toObject().id,
      sessionId:
        profile.activity.activity_product_version_items[0]
          .activity_product_version_sessions[0].id,
      mine: user.toObject().mine,
      sharedWithMe: user.toObject().sharedWithMe,
      completed:
        profile.activity.activity_product_version_items[0]
          .activity_product_version_sessions[0].completed,
      roleName: profile.profile_name,
    });
  }

  private renderSelectableReport(selection: JobProfileSelection) {
    const { user } = this.props;
    const userCanDownloadExistingReports = can(user, downloadExistingReports());
    const selectionState = this.getSelectionState(selection);
    const hasVariant = selection.productVariantId !== null;

    return (
      <SelectableReport
        completed={selection.completed}
        key={(selection.report && selection.report.id) || '1'}
        label={
          (selection.reportTemplate && selection.reportTemplate.code) || ''
        }
        status={(selection.report && selection.report.pdf_status) || undefined}
        disabled={
          userCanDownloadExistingReports &&
          this.props.anySelected[
            selection.report ? 'reportTemplates' : 'reports'
          ]
        }
        isUnavailable={
          !available(selectionState) ||
          (!userCanDownloadExistingReports && hasVariant)
        }
        extraClass={'profile-btn'}
        hasVariant={hasVariant}
        downloadBlocked={!userCanDownloadExistingReports}
        isSelected={isSelected(selectionState)}
        onSelect={this.props.toggleReportsTableSelection.bind(null, selection)}
        roleName={selection.roleName}
        reportName={selection.reportTemplate.name}
      />
    );
  }

  private getReportCountMessage(ReportsCount: number) {
    if (ReportsCount === 0) {
      return i18next.t<string>('No reports');
    }
    return i18next.t<string>('Showing {{count}} report', {
      count: ReportsCount,
    });
  }
}

export function mapStateToProps(state: StoreState): StateProps {
  return {
    user: selectUserProfile(state),
    users: selectFilteredOrgUsers(state),
    orgSessions: selectOrgSessions(state),
    accountHasUnlimitedCredits: selectAccountHasUnlimitedCredits(state),
    reportsTableSelections: selectReportsTableSelections(state),
    allSelected: selectAllSelectedReportsTable(state),
    anySelected: selectAnySelectedReportsTable(state),
    hasAny: selectHasAnyReportsTable(state),
    productsItems: selectMappedSelectedProducts(state),
    organisation: state.items.get('organisations'),
  };
}

export default connect(mapStateToProps, {
  toggleReportsTableSelection,
  loadOrganisation,
  openCreateJobMatchReportsModal,
  openCreateSuccessProfileReportsModal,
})(ProfileReportsTable);

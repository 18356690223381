import React from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';

import { closeTopModal } from '^/actions/modals';
import { LanguageCode } from '^/constants/routes';
import Alert from '^/components/Alert';
import FormError from '^/components/FormError';
import { isPending } from '^/responseStates';
import { ReportToGenerate } from './types';
import {
  OrgSessions,
  Uuid,
  TeamSessions,
  ProfileRespondentSessions,
  ProductOrganisation,
  ComparisonGroup,
} from '^/reducers/api/types';
import SelectReportsTable from './SelectReportsTable';
import TotalCostSection, { getTotalCost } from './TotalCostSection';
import MultiLanguageSection, { isMultiLanguage } from './MultiLanguageSection';
import { enoughReportsSelected } from './utils';
import { DeepReadonly } from '^/types';

interface State {
  teamName: string;
}

interface OwnProps {
  productId: Uuid | null;
  reportsToGenerate: ReportToGenerate[];
  orgSessions: OrgSessions | null;
  selectedSessionIds: Set<Uuid>;
  languageCode: LanguageCode;
  selectedTeamSessions?: TeamSessions;
  selectedProfileSessions?: ProfileRespondentSessions;
  updateReportsToGenerate: (reports: ReportToGenerate[]) => void;
  updateLanguageCode: (languageCode: LanguageCode) => void;
  response: Immutable.Map<string, any> | undefined;
  generate: (teamName?: string) => void;
  isTeamReports: boolean;
  isJobMatch: boolean;
  isProfile?: boolean;
  roleName?: string;
  productOrg?: ProductOrganisation;
  selectedComparisonGroupId?: Uuid | null;
  updateComparisonGroup?: (comparisonGroups: Uuid | null) => void;
}

interface DispatchProps {
  closeTopModal: typeof closeTopModal;
}

const renderCompGroupSection = (
  selectedSessions: ProfileRespondentSessions,
  sessionsWithCompGroups: ProfileRespondentSessions,
  comparisonGroups: DeepReadonly<Array<ComparisonGroup>>,
  updateComparisonGroup: (comparisonGroups: Uuid | null) => void,
  selectedComparisonGroupId: Uuid | null | undefined
): React.ReactNode => {
  const onlyOneCompGroup = comparisonGroups.length === 1;
  const allUsersHaveCompGroup =
    sessionsWithCompGroups.length === selectedSessions.length;

  if (allUsersHaveCompGroup) {
    return (
      <Alert>
        {i18next.t<string>('PARAGRAPH already have comparison groups')}
      </Alert>
    );
  }

  return (
    <>
      {sessionsWithCompGroups.length > 0 && (
        <Alert>
          {i18next.t<string>('PARAGRAPH some have comparison groups')}
        </Alert>
      )}
      <select
        value={selectedComparisonGroupId || ''}
        disabled={onlyOneCompGroup}
        className="full-width margin-none"
        onChange={e => updateComparisonGroup(e.target.value)}
      >
        {!onlyOneCompGroup && (
          <option value="" disabled>
            {i18next.t<string>('Please select comparison group')}
          </option>
        )}
        {comparisonGroups.map(compGroup => (
          <option key={compGroup.id} value={compGroup.id}>
            {compGroup.name || i18next.t<string>('Default')}
          </option>
        ))}
      </select>
    </>
  );
};

export type Props = OwnProps & DispatchProps;

export class ConfirmPurchasePage extends React.Component<Props> {
  public readonly state = {
    teamName: '',
  } as State;

  public render() {
    const {
      orgSessions,
      productId,
      response,
      selectedSessionIds,
      reportsToGenerate,
      updateReportsToGenerate,
      languageCode,
      updateLanguageCode,
      isTeamReports,
      isJobMatch,
      generate,
      roleName,
      productOrg,
      selectedProfileSessions,
      selectedTeamSessions,
      updateComparisonGroup,
      selectedComparisonGroupId,
      isProfile,
    } = this.props;
    const { teamName } = this.state;
    const totalCost = getTotalCost(reportsToGenerate);
    const notEnoughCredits =
      orgSessions?.is_using_credit && totalCost > orgSessions.credit;

    const selectedSessions = selectedProfileSessions || selectedTeamSessions;

    const comparisonGroups = productOrg?.comparison_groups;
    const sessionsWithCompGroups = selectedProfileSessions?.filter(session =>
      session.reports.some(
        report => report.report_generation_batch.comparison_group
      )
    );
    const mustSelectCompGroup =
      selectedProfileSessions &&
      sessionsWithCompGroups &&
      !(sessionsWithCompGroups.length === selectedProfileSessions.length);

    return (
      <div>
        {isTeamReports && (
          <div>
            <h5>{i18next.t<string>('Team report name')}</h5>
            <input
              type="text"
              value={teamName}
              onChange={evt => this.setState({ teamName: evt.target.value })}
            />
            <Alert>
              {i18next.t<string>(
                'Enter a name for your team report. This cannot be changed once the report has been generated.'
              )}
            </Alert>
            <FormError response={response} formKey="name" />
          </div>
        )}

        <div>
          <h5>{i18next.t<string>('Selected respondents')}</h5>
          <div className="boxed">
            <div className="box">
              {selectedSessions &&
                selectedSessions
                  .map(session => session.user.full_name)
                  .join(', ')}
            </div>
          </div>
        </div>

        {selectedProfileSessions &&
          sessionsWithCompGroups &&
          comparisonGroups &&
          updateComparisonGroup &&
          renderCompGroupSection(
            selectedProfileSessions,
            sessionsWithCompGroups,
            comparisonGroups,
            updateComparisonGroup,
            selectedComparisonGroupId
          )}

        {isMultiLanguage(orgSessions, productId) && (
          <MultiLanguageSection
            languageCode={languageCode}
            updateLanguageCode={updateLanguageCode}
          />
        )}

        <SelectReportsTable
          reportsToGenerate={reportsToGenerate}
          updateReportsToGenerate={updateReportsToGenerate}
          isTeamReports={isTeamReports}
          isJobMatch={isJobMatch}
          roleName={roleName}
          isProfile={isProfile}
        />

        <TotalCostSection
          notEnoughCredits={notEnoughCredits}
          totalCost={totalCost}
          orgSessions={orgSessions}
        />

        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-default"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t<string>('Cancel')}
            </button>

            <button
              className="btn btn-primary"
              onClick={
                isTeamReports ? () => generate(teamName) : () => generate()
              }
              disabled={
                isPending(response) ||
                !selectedSessionIds.size ||
                (isTeamReports && !teamName) ||
                notEnoughCredits ||
                (!isJobMatch &&
                  !isProfile &&
                  !enoughReportsSelected(reportsToGenerate)) ||
                (isProfile &&
                  !reportsToGenerate.some(
                    report => report.selected && report.quantity > 0
                  )) ||
                (mustSelectCompGroup && !selectedComparisonGroupId)
              }
            >
              {i18next.t<string>('Purchase')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  closeTopModal,
})(ConfirmPurchasePage);

import { List, Map } from 'immutable';

import { DragDetails } from '^/components/drag-and-drop/DragAndDropHandler';
import { generateQuestionOrder } from '^/questionGroups';

export type Item = List<Map<string, any>>;

export type QuestionGroup = Map<string, any>;

export interface State {
  from: ReadonlyArray<Item>;
  to: ReadonlyArray<Item>;
}

export const createItemKeyGetter = <T extends any>(key: string) => (
  item: Item
) => item.getIn([0, key]) as T;

export const getItemId = createItemKeyGetter<string>('id');

export const getItemText = createItemKeyGetter<string>('text');

export const getItemTooltip = createItemKeyGetter<string>('tooltip');

export const isEmpty = createItemKeyGetter<boolean | undefined>('empty');

export const isEmptyBoolean = (item: Item) => Boolean(isEmpty(item));

export const shouldSwap = (
  _item: Item,
  targetItem: Item,
  details: DragDetails<keyof State>
) => isEmpty(targetItem) || details.draggedGroup === 'to';

const orderQuestions = (questions: List<Item>, currentOrder: List<Item>) => {
  return currentOrder.map(id => {
    return questions.find(question => question.getIn([0, 'id']) === id);
  });
};

export const prepareGroups = (questionGroup: QuestionGroup): State => {
  const questions = questionGroup.get('questions');
  const currentOrder = generateQuestionOrder(questions);
  const orderedQuestions = orderQuestions(
    questions,
    currentOrder as List<Item>
  ).toArray();

  const from = orderedQuestions.map(item => {
    const answer = item.get(1);
    if (answer) {
      return List.of(
        Map({
          id: `${getItemId(item)}-empty`,
          text: '',
          empty: true,
        })
      );
    }
    return item;
  });

  const to = orderedQuestions.map(item => {
    const answer = item.get(1);
    if (answer) {
      return item;
    }
    return List.of(
      Map({
        id: `${getItemId(item)}-empty`,
        text: '',
        empty: true,
      })
    );
  });

  return {
    from,
    to,
  };
};

import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import moment from 'moment';
import _ from 'underscore';
import ImmutablePropTypes from 'react-immutable-proptypes';

import PureComponent from '^/components/PureComponent';
import Loading from '^/components/Loading';
import LikertProductVersion from './LikertProductVersion';
import ISPProductVersion from './ISPProductVersion';
import PerspectivesMotivatorsProductVersion from './PerspectivesMotivatorsProductVersion';
import PerspectivesCompetenciesRankedProductVersion from './PerspectivesCompetenciesRankedProductVersion';
import SuccessProfileRankedQuestionProductVersion from './SuccessProfileRankedQuestionProductVersion';
import SJTProductVersion from './SJTProductVersion';
import ResilienceProductVersion from './ResilienceProductVersion';
import ThreeSixtyProductVersion from './ThreeSixtyProductVersion';
import MAPProductVersion from './MAPProductVersion';
import MultiLikertProductVersion from './MultiLikertProductVersion';
import PerspectivesLikertProductVersion from './PerspectivesLikertProductVersion';
import PageContent from '^/components/PageContent';
import { resetProductVersionAnswers } from '^/actions/actions';

export class AnswerProductVersion extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.resetProductVersionAnswers();
  }

  _collectQuestionsWithAnswers(questions, answers, answerMatcher) {
    return questions.map(question => {
      const answersForQuestion = answers.filter(answerMatcher(question));

      // There may be multiple answers, so keep the newest
      const newestAnswer = answersForQuestion.max((valueA, valueB) => {
        return moment(valueA.get('created')).isAfter(valueB.get('created'));
      });
      return List.of(question, newestAnswer);
    });
  }

  collectQuestionsWithAnswers(questions, answers, linkField = 'question') {
    return this._collectQuestionsWithAnswers(
      questions,
      answers,
      question => answer => answer.get(linkField) === question.get('id')
    );
  }

  collectQuestionGroupsWithAnswers(questionGroups, answers) {
    return questionGroups.map(group => {
      const questions = group.get('questions');
      return group.set(
        'questions',
        this.collectQuestionsWithAnswers(questions, answers, 'grouped_question')
      );
    }, this);
  }

  collectCompetenciesQuestionGroupsWithAnswers(questionGroups, answers) {
    const questionGroup = questionGroups
      ? questionGroups.map(group => {
          const questions = group.get('questions');

          return group.set(
            'questions',
            this.collectQuestionsWithAnswers(
              questions,
              answers,
              'competencies_grouped_question'
            )
          );
        }, this)
      : null;

    const updateQuestionGroup = questionGroup
      ? questionGroup
          .toJS()
          .filter(item => item['questions'].length > 0)
          .map(item => {
            return Map({
              id: item.id,
              text: item.text,
              questions: List(
                item.questions.map(items => List(items.map(i => Map(i))))
              ),
              created: item.created,
              modified: item.modified,
              position: item.position,
              session: item.session,
            });
          })
      : null;
    return updateQuestionGroup ? List(updateQuestionGroup) : null;
  }

  collectOrderedQuestionsWithAnswers(orderedQuestions, answers) {
    return orderedQuestions.map(question => {
      const options = question.get('options');
      return question.set(
        'options',
        this.collectQuestionsWithAnswers(
          options,
          answers,
          'ordered_question_option'
        )
      );
    }, this);
  }

  collectMultiQuestionsWithAnswers(questions, answers) {
    return this._collectQuestionsWithAnswers(questions, answers, question => {
      const questionOptions = question
        .get('options')
        .map(option => option.get('id'));
      return answer => questionOptions.contains(answer.get('question_option'));
    });
  }

  collectMultiLikertQuestionsWithAnswers(questions, answers) {
    return this.collectQuestionsWithAnswers(questions, answers, 'question');
  }

  collectImageQuestionsWithAnswers(questions, answers) {
    return questions.map(question => {
      const questionOptions = question
        .get('options')
        .map(option => option.get('id'));

      const answerMap = Map(
        this._collectQuestionsWithAnswers(
          questionOptions,
          answers,
          questionOption => answer =>
            answer.get('image_match_question_option') === questionOption
        )
      );

      return List.of(question, answerMap);
    });
  }

  render() {
    const {
      answers,
      productVersion,
      questionCollectionIdx,
      ranking,
    } = this.props;

    const questionCollection = productVersion.getIn([
      'questioncollection_set',
      questionCollectionIdx || 0,
    ]);

    const questionsWithAnswers = this.collectQuestionsWithAnswers(
      questionCollection.get('questions', List()),
      answers
    );
    const questionGroupsWithAnswers = this.collectQuestionGroupsWithAnswers(
      questionCollection.get('question_groups', List()),
      answers
    );

    const questionCompetenciesGroupsWithAnswers = this.collectCompetenciesQuestionGroupsWithAnswers(
      this.props.rankQuestionOrder,
      answers
    );

    const orderedQuestionsWithAnswers = this.collectOrderedQuestionsWithAnswers(
      questionCollection.get('ordered_questions', List()),
      answers
    );
    const multipleChoiceQuestionsWithAnswers = this.collectMultiQuestionsWithAnswers(
      questionCollection.get('multiple_choice_questions', List()),
      answers
    );
    const imageMatchQuestionsWithAnswers = this.collectImageQuestionsWithAnswers(
      questionCollection.get('image_match_questions', List()),
      answers
    );
    const multiLikertQuestionsWithAnswers = this.collectMultiLikertQuestionsWithAnswers(
      questionCollection.get('multilikert_questions', List()),
      answers
    );
    const productVersionType = questionCollection.get('question_type');

    if (!productVersionType) {
      return (
        <PageContent>
          <Loading />
        </PageContent>
      );
    }

    const renderers = {
      EAP: LikertProductVersion,
      ISP: ISPProductVersion,
      PSYCAP: LikertProductVersion,
      THREE_SIXTY: ThreeSixtyProductVersion,
      POSITIVE_RESILIENCE_PROFILER: ResilienceProductVersion,
      MAP: MAPProductVersion,
      DISC: MultiLikertProductVersion,
      SJT: SJTProductVersion,
      PERSPECTIVES_COMPETENCIES_LIKERT: PerspectivesLikertProductVersion,
      PERSPECTIVES_RANKING: PerspectivesCompetenciesRankedProductVersion,
      PERSPECTIVES_MOTIVATORS: PerspectivesMotivatorsProductVersion,
      PERSPECTIVES_DERAILERS: PerspectivesLikertProductVersion,
      SUCCESS_PROFILE_POTENTIAL_RANK: SuccessProfileRankedQuestionProductVersion,
      SUCCESS_PROFILE_COMPETENCIES_RANK: SuccessProfileRankedQuestionProductVersion,
      SUCCESS_PROFILE_MOTIVATORS_RANK: PerspectivesMotivatorsProductVersion,
    };

    if (!_.has(renderers, productVersionType)) {
      throw new Error(
        'Attempting to render invalid survey type ' + productVersionType
      );
    }
    const Renderer = !ranking
      ? renderers[productVersionType]
      : renderers['PERSPECTIVES_RANKING'];
    return (
      <Renderer
        questionsWithAnswers={questionsWithAnswers}
        questionGroupsWithAnswers={questionGroupsWithAnswers}
        questionCompetenciesGroupsWithAnswers={
          questionCompetenciesGroupsWithAnswers
        }
        orderedQuestionsWithAnswers={orderedQuestionsWithAnswers}
        multipleChoiceQuestionsWithAnswers={multipleChoiceQuestionsWithAnswers}
        imageMatchQuestionsWithAnswers={imageMatchQuestionsWithAnswers}
        multiLikertQuestionsWithAnswers={multiLikertQuestionsWithAnswers}
        questionCollectionIdx={questionCollectionIdx}
        productVersion={this.props.productVersion}
        activity={this.props.activity}
        raterFor={this.props.raterFor}
      />
    );
  }
}

AnswerProductVersion.propTypes = {
  activity: ImmutablePropTypes.map.isRequired,
  productVersion: ImmutablePropTypes.map.isRequired,
  answers: ImmutablePropTypes.list.isRequired,
  raterFor: ImmutablePropTypes.map,
  questionCollectionIdx: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    answers: state.myAnswers,
    rankQuestionOrder: state.rankQuestionOrder,
  };
}

export default connect(mapStateToProps, {
  resetProductVersionAnswers,
})(AnswerProductVersion);

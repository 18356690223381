import { DeepReadonly } from '^/types';
import { logError } from '^/utils';
import {
  ActivityProductVersionSession,
  OrgSessions,
  Uuid,
  Profile,
  Profiles,
} from './types';

interface ActivityProductVersion {
  id: Uuid;
  activity_product_version_sessions: ActivityProductVersionSession[];
}

export type RecentSessions = ReadonlyArray<{
  activity_product_version_items: ReadonlyArray<ActivityProductVersion>;
}>;

export function integrateRecentProfiles(
  state: Profiles | null,
  results: Profiles
) {
  if (!state) {
    return null;
  }

  const newSessionsById: Record<Uuid, Profile> = {};
  results.forEach(jobProfile => {
    newSessionsById[jobProfile.id] = jobProfile;
  });
  const newSessionIds = Object.keys(newSessionsById);

  const newState: Profile[] = [];
  state.forEach(jobProfile => {
    if (newSessionIds.includes(jobProfile.id)) {
      newState.push(newSessionsById[jobProfile.id]);
    } else {
      newState.push(jobProfile);
    }
  });

  return newState as Profiles;
}

export function integrateRecentSessions(
  state: OrgSessions | null,
  results: RecentSessions
) {
  if (!state) {
    return null;
  }

  const newSessionsById: Record<
    Uuid,
    [ActivityProductVersionSession, Uuid]
  > = {};
  results.forEach(activity =>
    activity.activity_product_version_items.forEach(apv =>
      apv.activity_product_version_sessions.forEach(
        session => (newSessionsById[session.id] = [session, apv.id])
      )
    )
  );

  function getNewOrExistingSession(
    session: DeepReadonly<ActivityProductVersionSession>
  ) {
    const newSessionAndApvId = newSessionsById[session.id];
    if (newSessionAndApvId) {
      delete newSessionsById[session.id];
      return newSessionAndApvId[0];
    }
    return session;
  }

  const newState = {
    ...state,
    activities: state.activities.map(activity => ({
      ...activity,
      activity_product_version_items: activity.activity_product_version_items.map(
        activityProductVersion => ({
          ...activityProductVersion,
          activity_product_version_sessions: activityProductVersion.activity_product_version_sessions.map(
            getNewOrExistingSession
          ),
        })
      ),
    })),
  };

  const newSessions = Object.values(newSessionsById);
  if (newSessions.length) {
    const apvsById: Record<Uuid, ActivityProductVersion> = {};
    newState.activities.forEach(activity =>
      activity.activity_product_version_items.forEach(
        (apv: any) => (apvsById[apv.id] = apv)
      )
    );

    newSessions.forEach(([session, apvId]) => {
      if (apvsById[apvId]) {
        apvsById[apvId].activity_product_version_sessions.push(session);
      } else {
        // It should not be possible to receive a new session on an apv we don't have
        // This happened spontaneously during testing but we can't replicate
        logError('Unable to stitch in session because apv not found', {
          apvId,
          apvsById,
          newState,
        });
      }
    });
  }
  return newState;
}

export function sortedBy<T>(
  transform: (t: T) => string,
  values: ReadonlyArray<string>,
  items: ReadonlyArray<T>
): ReadonlyArray<T> {
  return values.flatMap(value =>
    items.filter(each => transform(each) === value)
  );
}

import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Map, Set } from 'immutable';

import CreateUserInlineForm from '^/components/users/CreateUserInlineForm';
import Alert from '^/components/Alert';
import { StoreState } from '^/store';
import { addMultipleRaterUsersThen } from '^/actions/actionSequences';
import { closeTopModal } from '^/actions/modals';

export interface User {
  id: string;
  full_name: string;
  email: string;
}

interface StateProps {
  response: Map<string, any>;
  selectedUsers: Set<string>;
}

interface OwnProps {
  currentIds: Set<string>;
  maxNumUsers: number | undefined;
  updateUsers: (selectedUsers: User[]) => void;
  setProfileCreatorEmail: (email: string) => void;
  organisationId: string;
  isDISC: boolean;
  addMultipleRaterUsersThen: typeof addMultipleRaterUsersThen;
  closeTopModal: typeof closeTopModal;
}

interface UserList {
  full_name: string;
  email: string;
}

type Props = StateProps & OwnProps;

const AddProfileRatersModal: React.FC<Props> = props => {
  const [raters, setRaters] = useState<UserList[] | null>(null);

  const addUpdateUsers = (users: User[]) => {
    props.updateUsers(users);
  };

  const cancel = () => {
    props.closeTopModal();
  };

  const addExternalUser = (values: { full_name: string; email: string }) => {
    if (raters && raters[0].email !== '' && !props.isDISC) {
      setRaters(
        raters.concat([
          {
            email: values.email,
            full_name: values.full_name,
          },
        ])
      );
    } else {
      setRaters([
        {
          email: values.email,
          full_name: values.full_name,
        },
      ]);
    }
  };

  const addMultipleExternalUsers = () => {
    props.addMultipleRaterUsersThen(
      addUpdateUsers,
      raters?.map(item => ({
        email: item.email,
        full_name: item.full_name,
        external: true,
        external_profile: false,
        organisation: props.organisationId,
      }))
    );
    raters && props.isDISC && props.setProfileCreatorEmail(raters[0].email);
    props.closeTopModal();
  };

  return (
    <div>
      <CreateUserInlineForm
        response={props.response}
        onSubmit={addExternalUser}
      />
      <Alert className="mt-sm">
        {props.isDISC &&
          i18next.t<String>('Please enter the full name and email address.')}
        {!props.isDISC &&
          i18next.t<String>(
            'Nominate the individual rater(s) who will be invited to input into the success profile.'
          )}
      </Alert>
      <table>
        {raters &&
          raters.map(items => (
            <tr className="user-row" key={items.full_name}>
              <td className="user-profile">{items.full_name}</td>
              <td className="user-email">{items.email}</td>
            </tr>
          ))}
      </table>

      <div className="modal-footer clearfix">
        <div className="pull-right">
          <button className="btn btn-default" onClick={() => cancel()}>
            {i18next.t<String>('Cancel')}
          </button>
          <button
            className="btn btn-primary"
            disabled={!raters}
            onClick={() => addMultipleExternalUsers()}
          >
            {i18next.t<String>('Add profile raters')}
          </button>
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (state: StoreState): StateProps => ({
  selectedUsers: state.ui.get('selectedUiComponents').get('users', Set()),
  response: state.responses.get('addToCollection'),
});

export default connect(mapStateToProps, {
  addMultipleRaterUsersThen,
  closeTopModal,
})(AddProfileRatersModal);

/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { RouterParamProps, withRouter } from '^/withRouter';
import { loadSuccessProfile } from '^/actions/items';
import { Uuid, SuccessProfile } from '^/reducers/api/types';
import { StoreState } from '^/store';
import ControlBar from '^/components/ControlBar';
import { TabbedContainer } from '../TabbedContainer';
import ProfileCharts from './profile-charts/ProfileCharts';
import ProfileSummarySection from './ProfileSummarySection';

interface DispatchProps {
  loadProfile: typeof loadSuccessProfile;
}

interface StateProps {
  successProfile: SuccessProfile | undefined;
  successProfileId: Uuid;
  activityId: Uuid;
}

type Props = DispatchProps &
  StateProps &
  RouterParamProps<{ id: Uuid; activityId: Uuid }, {}>;

export const SuccessProfilePage: React.FC<Props> = ({
  loadProfile,
  successProfile,
  successProfileId,
  activityId,
}) => {
  useEffect(() => {
    loadProfile(successProfileId);
  }, [successProfileId, loadProfile]);

  const canVerify = Boolean(successProfile && successProfile.can_verify);
  const breadcrumb = (
    <span className="breadcrumbs">
      <Link to={`/page/activities/${activityId}/`}>
        {i18next.t<string>('Activity')}
      </Link>
      <span>{i18next.t<string>('Profile detail')}</span>
    </span>
  );

  return (
    <div>
      <ControlBar
        title={i18next.t<string>('Profile')}
        hideSearch
        hideAdd
        breadcrumb={breadcrumb}
      />
      <h2>
        {i18next.t<string>('Profile name')}:{' '}
        {successProfile && successProfile.profile_name}
      </h2>
      <h3>
        {i18next.t<string>('No. raters')} ={' '}
        {successProfile && successProfile.number_of_raters}
      </h3>
      <div className="success-profile-summary">
        <TabbedContainer
          defaultActive="summary"
          transparent
          config={[
            {
              id: 'summary',
              label: i18next.t<string>('Profile summary'),
              noPadding: false,
              renderer: successProfile ? (
                <div>
                  <ProfileSummarySection
                    activityId={activityId}
                    canVerify={canVerify}
                    title={successProfile.profile_name}
                    potentials={successProfile.potential}
                    motivators={successProfile.motivators}
                    competencies={successProfile.competencies}
                    profileId={successProfileId}
                    defaultRanges={successProfile.default_ranges}
                  />
                </div>
              ) : (
                <></>
              ),
            },
            {
              id: 'charts',
              label: i18next.t<string>('Profile charts'),
              noPadding: true,
              renderer: (
                <div>
                  {successProfile && (
                    <ProfileCharts successProfile={successProfile} />
                  )}
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export function mapStateToProps(state: StoreState, props: Props) {
  return {
    successProfile: state.items.get('successProfiles')?.toJS() as
      | SuccessProfile
      | undefined,
    successProfileId: props.router.params.id,
    activityId: props.router.params.activityId,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    loadProfile: loadSuccessProfile,
  })(SuccessProfilePage)
);
